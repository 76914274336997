import React,{Component} from 'react'
import { Table, Container, Col, Row, Form, Input,Label,FormGroup,ButtonGroup, FormText, Button } from 'reactstrap';
import {FormWidget,ProyectForm,ActivityIndicator} from '../components';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
//import swal from '@sweetalert/with-react'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import 'moment/locale/es' 

moment.locale("es");

const AddMoreBtn = (props) => {
    return(
        <Button style={props.style} onClick={(e) => props.onClick(e)}>
            {props.text}
        </Button>
    )
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

class Proyects extends Component{

    constructor(){
        super();

        this.state = {
            show : false,
            services : [],
            custom : [],
            pricePerHour: 0,
            proyectTitle : "",
            currentProyect : {},
            selectedClient : 0,
            popUpMessage : "",
            popup : false,
            exit : false
        }
    }

    componentDidMount(){
        const {fetchProyects,fetchClients,fetchSettings} = this.props
        fetchProyects();
        fetchClients();
        fetchSettings();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.proyects.proyectServices !== this.props.proyects.proyectServices && nextProps.proyects.proyectServices){
            let proyectServices = nextProps.proyects.proyectServices;
            let services = [],custom = [];

            for(let i = 0; i < proyectServices.length; i++){
                if(proyectServices[i].type == "custom"){
                    custom.push(proyectServices[i])
                }else{
                    services.push(proyectServices[i])
                }
            }

            this.setState({
                custom,
                services
            })
        }

        if(nextProps.proyects.error != this.props.proyects.error && nextProps.proyects.error){
            this.setState({ popup: true, popUpMessage : nextProps.proyects.error });
        }

        if(nextProps.proyects.success != this.props.proyects.success && nextProps.proyects.success){
            this.setState({ popup: true, popUpMessage : "Guardado correctamente" })
        }
    }

    toogleForm = (proyect) => { 
        const {fetchProyectQuotation} = this.props
        if(proyect && proyect.proyect_id && proyect.proyect_id > 0){
            fetchProyectQuotation(proyect.proyect_id);
        }
        this.setState({
            show:!this.state.show,
            currentProyect:proyect,
            pricePerHour : proyect && proyect.hour_price ? proyect.hour_price : 0,
            proyectTitle : proyect && proyect.proyect_name ? proyect.proyect_name : 0,
            quotation_id : proyect && proyect.quotation_id ? proyect.quotation_id : 0,
            services : [],
            custom : [],
            selectedClient : proyect && proyect.client_id ? proyect.client_id : 0
        });
    }

    handleRemoveItem = ({id,type}) => {
        const typeString = (type == "service" ? "services" : "custom");

        this.setState({
          [typeString]: this.state[typeString].filter((item, index) => item.quotation_description_id != id)
        });
    };

    confirm(e){
        e.preventDefault();
        this.setState({exit:true})
    }

    addMore(type){
        const {services,custom} = this.state;
        const id = new Date().getTime();
        switch(type){
            case "service":
                this.setState({
                    services : services.concat([
                        { quotation_description_id : id, service: "", desc : "", qty : 0, price : 0,hour : 0 }
                    ])
                })
            break;

            case "custom":
                this.setState({
                    custom : custom.concat([
                        { quotation_description_id : id, service: "", desc : "", qty : 0, price : 0, hour : 0 }
                    ])
                });
            break;
        }
    }

    handleInputChange = ({index,type,field}) => evt => {
        const {pricePerHour} = this.state;
        const inputTypeString = (type == "service" ? "services" : "custom");
        
        let newItems = []

        if(pricePerHour && parseFloat(pricePerHour) > 0){
            if((type == "custom" && field == "hour")){
                newItems = this.state[inputTypeString].map((item, sidx) => {
                    if (index !== sidx) return item;
                    return { ...item, price : ((evt.target.value * pricePerHour) * item.qty), hour : evt.target.value };
                });
            }else if((type == "custom" && field == "qty")){
                newItems = this.state[inputTypeString].map((item, sidx) => {
                    if (index !== sidx) return item;
                    return { ...item, price : ((item.hour * pricePerHour) * evt.target.value), qty : evt.target.value };
                });
            }else if((type == "service" && field == "qty")){
                newItems = this.state[inputTypeString].map((item, sidx) => {
                    if (index !== sidx) return item;
                    return { ...item, price : ((item.hour * pricePerHour) * evt.target.value), qty : evt.target.value };
                });
            }else{
                newItems = this.state[inputTypeString].map((item, sidx) => {
                    if (index !== sidx) return item;
                    return { ...item, [field.toString()] : evt.target.value };
                });
            }

            

            this.setState({ [inputTypeString]: newItems });
        }
    }

    handleSubmit = evt => {
        const {createOrUpdateProyect} = this.props;
        const { custom, services, proyectTitle, selectedClient, pricePerHour, currentProyect } = this.state;
        
        const data = {
            ...currentProyect,
            //proyect_id : currentProyect.proyect_id,
            client_id : selectedClient,
            proyect_name : proyectTitle,
            hour_price : pricePerHour,
            services : [
                ...services
            ],
            custom : [
                ...custom
            ]
            
        }
        
        createOrUpdateProyect(data);
    }

    downloadPdf = e => {
        const {clients} = this.props;
        const {selectedClient,custom,services,proyectTitle} = this.state;

        const {logo,contact_phone,pdf_mail,address} = this.props.settings;

        const client = clients.collection.find((item,index) => { return item.client_id == parseInt(selectedClient) });

        let doc = new jsPDF('', '', 'a4');
        
        doc.addImage(logo, 'JPEG', 10, 10, 30, 30);
        doc.setFontSize(11);
        doc.text("Presupuesto",10,50);
        doc.setTextColor("#215dff");
        doc.textWithLink(`${pdf_mail} | ${contact_phone}`,10,55);

        let date = moment().format("LLLL");
        doc.setTextColor("#000000");
        doc.text("Fecha : " + date,10,65);
        doc.setFontSize(11);
        doc.text(`${address}`,10,70);

        //datos cliente
        doc.setFontSize(14);
        doc.text(`${client.client_name} de ${client.client_company}`,10,80);
        

        //proyecto
        doc.text(proyectTitle,10,95);
        //doc.line(10,55,10,55);

        let servicesDetails = [],
        subTotal = 0,
        iva = 0,
        total = 0,
        hours = 0

        for(let i = 0; i < services.length; i++){
            servicesDetails.push(
                [
                    services[i].service,
                    services[i].desc,
                    services[i].qty,
                    services[i].hour,
                    formatter.format(services[i].price)
                ]
            );

            subTotal = (subTotal + parseFloat(services[i].price))
            hours = (hours + parseFloat(services[i].hour))
        }

        for(let i = 0; i < custom.length; i++){
            servicesDetails.push(
                [
                    custom[i].service,
                    custom[i].desc,
                    custom[i].qty,
                    custom[i].hour,
                    formatter.format(custom[i].price)
                ]
            );
            subTotal = (subTotal + parseFloat(custom[i].price))
            hours = (hours + parseFloat(custom[i].hour))
        }

        iva = subTotal * 0.16;
        total = subTotal + iva;

        let height = doc.internal.pageSize.height;

        doc.autoTable({
            head: [['Servicio', 'Descripción', 'Cantidad','Horas','Precio Unitario']],
            body: servicesDetails,
            afterPageContent: function(data) {
                //doc.setFontSize(20)
                //doc.text("After page", 0,height - data.settings.margin.bottom - 50);
            },
            showHead : "firstPage",
            showFoot : "lastPage",
            margin: {top: 100,right:10,left:10},
            styles : {cellWidth : 33.3333},
            pageBreak : "auto",
            //startY : doc.autoTableEndPosY() + 65,
        });

        doc.autoTable({
            foot : [
                ['','','','Sub-total',formatter.format(subTotal)],
                ['','','','IVA',formatter.format(iva)],
                ['','','','TOTAL',formatter.format(total)],
                ['','','','Tiempo total estimado',`${hours} horas`]
            ],
            
            margin: {right:10,left:10},
            styles : {cellWidth : 33.3333},
            pageBreak : "avoid"
        });

        doc.save('quotation.pdf')
    }

    render(){
        const {show,custom,services,currentProyect,selectedClient,popUpMessage,popup,exit} = this.state;
        const {proyects:{collection,success,error,loading},clients} = this.props;
        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget 
                        title="Proyectos"
                        headerColor="#215dff"
                        icon="plus"
                        onClick={(e) => {
                            e.preventDefault();
                            this.toogleForm();
                        }}
                        render={
                            <div>
                            {error && !loading && (<span>{error.toString()}</span>)}
                            {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                            {!loading && !error && collection && collection.length > 0 && (<Table borderless>
                                <thead>
                                <tr>
                                    <th>Proyecto</th>
                                    <th>Status</th>
                                    <th>Precio por Hora</th>
                                    <th>Creado</th>
                                    <th>Acción</th>
                                </tr>
                                </thead>
                                <tbody>
                                
                                {!loading && collection && collection.length > 0 && collection.map((proyect,index) => (
                                    <tr key={proyect.proyect_id}>
                                        <td>{proyect.proyect_name}</td>
                                        <td>{proyect.status}</td>
                                        <td>{formatter.format(proyect.hour_price)}</td>
                                        <td>{moment(proyect.created_at).format("LL")}</td>
                                        <td>
                                            <ButtonGroup>
                                                <Button onClick={(e) => {
                                                    e.preventDefault();
                                                    this.toogleForm(proyect);
                                                }}>
                                                    <i className="fa fa-pencil-alt"></i>
                                                </Button>
                                                <Button color="danger" onClick={(e) => {
                                                    e.preventDefault();
                                                    //this.toogleForm(proyect);
                                                }}>
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>)}
                            </div>
                        }
                        maxHeight={430}
                    />
                </Container>

                
                <ProyectForm show={show} onLeave={() => this.toogleForm()}>
                    <div style={{backgroundColor:"#fff",flex:1,display:"flex",height:"100%"}}>
                        <div className="form-widget-header"
                            style={{
                                backgroundColor:"rgb(33, 93, 255)",
                                top:0,
                                left:0,
                                right:0,
                                width:"100%",
                                padding:12,
                                borderRadius : 0,
                                boxShadow:"0 0 0 0"
                            }}
                        >
                            <Row>
                                <Col>
                                    <Button color="link" onClick={(e) => this.confirm(e)} style={styles.goBack}>
                                        <Row>
                                            <i className={`fa fa-arrow-left`}></i>
                                        </Row>
                                    </Button>
                                </Col>
                                <Col className="text-align-right">
                                    <ButtonGroup>
                                        <Button disabled={loading ? true : false} color="link" style={styles.save} onClick={this.handleSubmit}>
                                            <i className="fa fa-check"></i>
                                            <span style={{marginLeft:12}} className="form-widget-header-title">Guardar</span>
                                        </Button>
                                        <Button color="link" style={styles.save} onClick={this.downloadPdf}>
                                            <i className="fa fa-download"></i>
                                            <span style={{marginLeft:12}} className="form-widget-header-title">PDF</span>
                                        </Button>
                                        <Button disabled={loading ? true : false} color="link" style={styles.save} onClick={() => {}}>
                                            <i className="fa fa-trash"></i>
                                            <span style={{marginLeft:12}} className="form-widget-header-title">Borrar</span>
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </div>
                        
                        <Container style={{paddingTop:10,marginTop:60,overflow:"scroll"}}>
                            <Form>
                                <FormGroup>
                                    <Label>Nombre</Label>
                                    <Input defaultValue={currentProyect && currentProyect.proyect_name ? currentProyect.proyect_name : ""} type="text" placeholder="Escriba el nombre del proyecto" onChange={e => this.setState({proyectTitle : e.target.value})} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Cliente</Label>
                                    <Input defaultValue={selectedClient} type="select" onChange={e => this.setState({selectedClient : e.target.value})}>
                                        <option value="">Elija una opción</option>
                                        {clients && clients.collection && clients.collection.length > 0 && clients.collection.map((client,index) => 
                                            <option value={client.client_id}>{client.client_name}</option> 
                                        )}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Precio por hora</Label>
                                    <Input defaultValue={currentProyect && currentProyect.hour_price ? currentProyect.hour_price : ""} type="number" min="1" max="99999999" onChange={e => this.setState({pricePerHour : e.target.value})} />
                                    <FormText>
                                        Se tomará en cuenta el precio por hora para todos los productos y/ó servicios personalizados asignados al proyecto 
                                    </FormText>
                                </FormGroup>
                                <Label style={styles.title}>Productos y Servicios</Label>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <td>Producto / Servicio</td>
                                            <td>Descripción</td>
                                            <td>Cantidad</td>
                                            <td>Precio</td>
                                            <td>Horas</td>
                                            <td>Eliminar</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {services && services.length > 0 && services.map((item,idx) => (
                                            <tr key={item.quotation_description_id}>
                                                <td>
                                                    <Input defaultValue={item.service} type="select" onChange={this.handleInputChange({type:"service",index:idx,field:"service"})}>
                                                        <option>Hostin Básico</option>
                                                        <option>Dominio 1 año</option>
                                                        <option>Dominio 2 años</option>
                                                        <option>Hosting Premium</option>
                                                    </Input>
                                                    {item.type && item.type == "custom" && (
                                                        <Input type="text" defaultValue={item.service} placeholder="Producto / Servicio" onChange={this.handleInputChange({type:"custom",index:idx,field:"service"})} />
                                                    )}
                                                </td>
                                                <td>
                                                    <Input defaultValue={item.desc} type="text" placeholder="Descripción" onChange={this.handleInputChange({type:"service",index:idx,field:"desc"})} />
                                                </td>
                                                <td>
                                                    <Input defaultValue={item.qty} type="number" min="1" max="99999" placeholder="Cantidad" onChange={this.handleInputChange({type:"service",index:idx,field:"qty"})} />
                                                </td>
                                                <td>
                                                    <Input value={item.price} type="number" min="1" max="99999" placeholder="Precio" onChange={this.handleInputChange({type:"service",index:idx,field:"price"})} />
                                                </td>
                                                <td>
                                                    <Input defaultValue={item.hours} type="number" min="1" max="99999" placeholder="Horas" onChange={this.handleInputChange({type:"service",index:idx,field:"hour"})} />
                                                </td>
                                                <td>
                                                    <Button color="link" style={styles.remove} onClick={() => this.handleRemoveItem({id:item.quotation_description_id,type:"service"})}>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <AddMoreBtn 
                                    text={"Agregar Producto/Servicio"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.addMore("service");
                                    }}
                                    style={{display:"block",marginBottom:20}}
                                />
                                <Label style={styles.title}>Productos y Servicios Únicos</Label>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <td>Producto / Servicio</td>
                                            <td>Descripción</td>
                                            <td>Cantidad</td>
                                            <td>Precio</td>
                                            <td>Horas</td>
                                            <td>Eliminar</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {custom && custom.length > 0 && custom.map((item,idx) => (
                                            <tr key={item.quotation_description_id}>
                                                <td>
                                                    <Input defaultValue={item.service} type="text" placeholder="Producto / Servicio" onChange={this.handleInputChange({type:"custom",index:idx,field:"service"})} />
                                                </td>
                                                <td>
                                                    <Input defaultValue={item.desc} type="text" placeholder="Descripción" onChange={this.handleInputChange({type:"custom",index:idx,field:"desc"})} />
                                                </td>
                                                <td>
                                                    <Input defaultValue={item.qty} type="number" min="1" max="99999" placeholder="Cantidad" onChange={this.handleInputChange({type:"custom",index:idx,field:"qty"})} />
                                                </td>
                                                <td>
                                                    <Input value={item.price} disabled type="number" min="1" max="99999" placeholder="Precio" onChange={this.handleInputChange({type:"custom",index:idx,field:"price"})} />
                                                </td>
                                                <td>
                                                    <Input defaultValue={item.hour} type="number" min="1" max="99999" placeholder="Horas" onChange={this.handleInputChange({type:"custom",index:idx,field:"hour"})} />
                                                </td>
                                                <td>
                                                    <Button color="link" style={styles.remove} onClick={() => this.handleRemoveItem({id:item.quotation_description_id,type:"custom"})}>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <AddMoreBtn 
                                    text={"Agregar Producto/Servicio Per."}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.addMore("custom");
                                    }}
                                    style={{display:"block",marginBottom:20}}
                                />
                            </Form>
                        </Container>
                    </div>
                </ProyectForm>

                <SweetAlert 
                    show={popup}
                    title="Aviso"
                    type={success ? 'success' : 'error'}
                    text={popUpMessage}
                    onConfirm={() => this.setState({ popup: false, popUpMessage : "" })}
                />

                <SweetAlert
                    show={exit}
                    title="Aviso"
                    type="warning"
                    text="Los cambios que no hayan sido guardados se perderán, deseas continuar?"
                    showCancelButton
                    onConfirm={() => {
                        //console.log('confirm'); // eslint-disable-line no-console
                        this.setState({ exit: false });
                        this.toogleForm();
                    }}
                    onCancel={() => {
                        //console.log('cancel'); // eslint-disable-line no-console
                        this.setState({ exit: false });
                    }}
                    onEscapeKey={() => this.setState({ exit: false })}
                    onOutsideClick={() => this.setState({ exit: false })}
                />

            </div>
        )
    }
}

const styles = {
    addMore : {
        padding : '8px 12px',
        backgroundColor : "#215dff",
        color: "white",
        borderRadius : 4
    },
    
    save : {
        color: "white",
        textDecoration:"none"
    },

    goBack : {
        marginLeft:20,
        color: "white",
        textDecoration:"none"
    },

    title : {
        fontWeight : "bold"
    },

    remove : {
        color: "red",
        textDecoration:"none"
    }
}

export default Proyects