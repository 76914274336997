import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,proyects,clients,settings} from '../actions';
import Proyects from '../pages/Proyects';

export default connect(
    (state) => ({
        account: state.account,
        proyects : state.proyects,
        clients : state.clients,
        settings : state.settings
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...proyects,
        ...clients,
        ...settings
    }, dispatch)
)(Proyects);
