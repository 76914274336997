import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account} from '../actions';
import Main from '../components/Main';

export default connect(
    (state) => ({
        account: state.account
    }),
    (dispatch) => bindActionCreators({
        ...account
    }, dispatch)
)(Main);
