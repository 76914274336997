import {SchedulesApi} from '../api'

export const FETCH_SCHEDULES_SUCCESS = "FETCH_SCHEDULES_SUCCESS"
export const FETCH_SCHEDULES_REQUEST = "FETCH_SCHEDULES_REQUEST"
export const FETCH_SCHEDULES_ERROR = "FETCH_SCHEDULES_ERROR"

export function deleteSchedule(schedule_id){
    return function(dispatch){
        dispatch(fetchSchedulesRequest())
        return SchedulesApi.post({},`delete/${schedule_id}`)
        .then(response => {
            if(response.response){
                dispatch(fetchSchedules())
            }else{
                dispatch(fetchSchedulesError("NO_RESULT"))
            }
        })
        .catch(error => dispatch(fetchSchedulesError(error)))
    }
}

export function newSchedule(schedule){
    return function(dispatch){
        dispatch(fetchSchedulesRequest())
        return SchedulesApi.post(schedule,'save')
        .then(response => {
            if(response.response){
                dispatch(fetchSchedules())
            }else{
                dispatch(fetchSchedulesError("NO_RESULT"))
            }
        })
        .catch(error => dispatch(fetchSchedulesError(error)))
    }
}

export function fetchSchedules(){
    return function(dispatch){
        dispatch(fetchSchedulesRequest())
        return SchedulesApi.get('getAll')
        .then(response => {
            if(response.response){
                dispatch(fetchSchedulesSuccess(response.result))
            }else{
                dispatch(fetchSchedulesError("NO_RESULT"))
            }
        })
        .catch(error => dispatch(fetchSchedulesError(error)))
    }
}

function fetchSchedulesRequest(){
    return {
        type : FETCH_SCHEDULES_REQUEST
    }
}

function fetchSchedulesSuccess(schedules){
    return {
        type : FETCH_SCHEDULES_SUCCESS,
        schedules
    }
}

function fetchSchedulesError(error){
    return {
        type : FETCH_SCHEDULES_ERROR,
        error
    }
}