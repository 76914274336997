import {notes} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case notes.FETCH_NOTES_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case notes.FETCH_NOTES_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case notes.FETCH_NOTES_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.notes
            }
        }

        default:
            return state;
    }
}