import React,{Component} from 'react'
import { Row, Col, Container, FormGroup, Label, Input,Table,Button,CustomInput,InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import {FormWidget,Form, ActivityIndicator} from '../components'
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import generator from 'generate-password';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Users extends Component{

    state = {
        profile : {},
        form : false,
        permissions:[]
    }

    componentDidMount(){
        const {fetchProfiles,fetchModules} = this.props
        fetchModules();
        fetchProfiles(1);
    }

    toogleForm = (profile) => { 
        this.setState({
            form : !this.state.form, 
            profile,
            permissions : (profile && profile.permissions) ? profile.permissions.map((m,i) => {return m.module_id}) : []
        }) 
    }

    toggle(collection, item) {
        var idx = collection.indexOf(item);
        if(idx !== -1) {
          collection.splice(idx, 1);
        } else {
          collection.push(item);
        }
        return collection;
    }

    handleInputChange = ({field}) => evt => {        
        this.setState({ profile: {
            ...this.state.profile,
            [field] : evt.target.value
        }});
    }

    onSubmit = (e) => {
        const {createOrUpdateProfile,account:{data}} = this.props
        const {profile,permissions} = this.state
        e.preventDefault();
        
        let p = []
        if(permissions.length > 0){
            for(let i = 0; i < permissions.length; i++){
                p.push({
                    module_id : permissions[i]
                })
            }
            profile.permissions = p
        }

        if(profile && profile.profile_name && profile.profile_name != ""
        && profile.permissions && profile.permissions.length > 0){
            createOrUpdateProfile(profile,data.user_name);
        }else{
            toast.warn('Verifique que todos los campos estén llenados y que el perfil cuente con al menos un acceso a algún módulo del sistema', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }

       
    }

    onRemove(profile_id){
        const {deleteProfile,account:{data}} = this.props
        let conf = window.confirm("¿Deseas eliminar este usuario?")
        if(conf){
            deleteProfile(profile_id,data.user_name);
        }
    }

    render(){
        const {form,profile,permissions} = this.state
        const {profiles:{collection,loading,error},modules} = this.props
       
        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget
                        title="Perfiles de Usuario"
                        headerColor="#f5b13f"
                        icon="plus"
                        titleButton=" Añadir nuevo"
                        onClick={(e) => {
                            e.preventDefault();
                            this.toogleForm();
                        }}
                        maxHeight={430}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                    <Table borderless>
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {collection && collection.length > 0 && collection.map((profile,index) => (
                                                <tr index={profile.profile_id}>
                                                    <td>{profile && profile.profile_name != "" ? profile.profile_name : ""}</td>
                                                    <td>
                                                        <ButtonGroup>
                                                            <Button onClick={(e) => {
                                                                e.preventDefault();
                                                                this.toogleForm(profile);
                                                            }}>
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Button>
                                                            <Button color="danger" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.onRemove(profile.profile_id)
                                                            }}>
                                                                <i className="fa fa-trash"></i>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        }
                    />

                    <Form 
                        show={form} 
                        onLeave={() => this.toogleForm()} >
                        <div className="form-content">
                            <div className="form-header">
                                <Row>
                                    <Col><span>Perfil de Usuario</span></Col>
                                    <Col className="text-align-right">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.toogleForm();
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <Container className="form-children-content">
                                <FormGroup>
                                    <Label>Nombre</Label>
                                    <Input defaultValue={profile && profile.profile_name && profile.profile_name != "" ? profile.profile_name : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"profile_name"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Permisos</Label>
                                    <div>
                                        {modules && modules.collection && modules.collection.length > 0 && modules.collection.map((item,index) =>(
                                            <div>
                                            <CustomInput id={`customInput-${item.module_id}`} name={`customInput-${item.module_id}`} checked={permissions.indexOf(item.module_id) > -1 ? true : false} key={`${index}-${item.module_id}`} type="switch" label={item.module_name} onChange={() => {
                                                let checked = this.toggle(permissions,item.module_id)
                                                
                                                this.setState({
                                                    permissions : checked
                                                })
                                            }} />
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                                
                                <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                        {!loading && ('Guardar Perfil')}
                                        {loading && ('Espere por favor ...')}
                                </Button>
                            </Container>
                        </div>
                    </Form>
                </Container>
                <ToastContainer />
            </div>
        )
    }
}

export default Users