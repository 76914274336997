import {proyects} from '../actions'
export default function(state = {}, action) {

    switch(action.type){
        case proyects.FETCH_PROYECTS_REQUEST:
            return {
                ...state,
                loading : true,
                success : false
            }

        case proyects.FETCH_PROYECTS_ERROR:
            return {
                ...state,
                loading : false,
                success : false,
                error : action.error
            }

        case proyects.EMPTY_PROYECTS_COLLECTION:
            return {
                ...state,
                collection : []
            }
        
        case proyects.FETCH_PROYECTS_SUCCESS:
            return {
                ...state,
                loading : false,
                error : null,
                collection : action.proyect//state.collection.concat(action.proyect)
            }

        case proyects.SAVE_SUCCESS_PROYECT:
            return {
                ...state,
                loading : false,
                error : null,
                success : true
            }

        case proyects.FETCH_PROYECT_SERVICES:
            return {
                ...state,
                loading : false,
                error : null,
                success : false,
                proyectServices : action.services
            }
        
        default:
            return state;
    }
}
