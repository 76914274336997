import {ClientApi,BookingApi} from '../api'

export const FETCH_CLIENT_REQUEST = "FETCH_CLIENT_REQUEST"
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS"
export const FETCH_CLIENT_ERROR = "FETCH_CLIENT_ERROR"

export function searchClients(value,page){
    return function(dispatch){
        dispatch(fetchClientsRequest())
        return ClientApi.post({value},`search/${page}`)
        .then(response => {
            if(response.response){
                dispatch(fetchClientsSuccess(
                    response.result,
                    response.total_rows,
                    response.total_pages
                ));
            }else{
                dispatch(fetchClientsError("Ocurrió un error al eliminar el cliente"))
            }
        })
        .catch(error => dispatch(fetchClientsError(error)))
    }
}

export function deleteClient(client_id,request_by){
    return function(dispatch){
        dispatch(fetchClientsRequest())
        return ClientApi.post({
            client_id,
            request_by
        },`delete`)
        .then(response => {
            if(response.response){
                dispatch(fetchClients())
            }else{
                dispatch(fetchClientsError("Ocurrió un error al eliminar el cliente"))
            }
        })
        .catch(error => dispatch(fetchClientsError(error)))
    }
}

export function createOrUpdateClient(client,request_by){
    return function(dispatch){
        dispatch(fetchClientsRequest())
        return ClientApi.post({
            ...client,
            request_by
        },'save')
        .then(response => {
           if(response.response){
               dispatch(fetchClients())
           }else{
                dispatch(fetchClientsError("Ocurrió un error al actualizar"))
           }
        })
        .catch(error => {
            console.log(error)
        })
    }
}

export function fetchClients(page){
    return function(dispatch){
        dispatch(fetchClientsRequest())
        return ClientApi.get(`getAll/${page}`)
        .then(response => {
            if(response.result){
                dispatch(fetchClientsSuccess(
                    response.result,
                    response.total_rows,
                    response.total_pages
                ));
            }else{
                dispatch(fetchClientsError("No se encontraron resultados"))
            }
        })
        .catch(error => {
            dispatch(fetchClientsError(error))
        })
    }
}

export function fetchBookingClients(page){
    return function(dispatch){
        dispatch(fetchClientsRequest())
        return BookingApi.get(`clients/${page || 0}`)
        .then(response => {
            if(response.result){
                dispatch(fetchClientsSuccess(
                    response.result,
                    response.total_rows,
                    response.total_pages
                ));
            }else{
                dispatch(fetchClientsError("No se encontraron resultados"))
            }
        })
        .catch(error => {
            dispatch(fetchClientsError(error))
        })
    }
}

function fetchClientsRequest(){
    return {
        type : FETCH_CLIENT_REQUEST
    }
}

function fetchClientsSuccess(clients,rows,pages){
    return {
        type : FETCH_CLIENT_SUCCESS,
        clients,
        rows,
        pages
    }
}

function fetchClientsError(error){
    return {
        type : FETCH_CLIENT_ERROR,
        error
    }
}