import {settings} from '../actions'
export default function(state = {}, action) {

    switch(action.type){
        case settings.FETCH_SETTINGS_REQUEST:
            return {
                ...state,
                loading : true,
                error : false,
                success : false
            }

        case settings.FETCH_SETTINGS_ERROR:
            return {
                ...state,
                loading : false,
                error : action.error,
                success : false
            }

        case settings.TOGGLE_SUCCESS_SETTINGS:
            return {
                ...state,
                loading : false,
                success : action.success,
                error : false
            }
        
        case settings.FETCH_SETTINGS_SUCCESS:
            return {
                ...state,
                loading : false,
                error : null,
                pdf_mail : action.pdf_mail,
                contact_phone : action.contact_phone,
                logo : action.logo,
                address : action.address
            }

        case "ACCOUNT_LOGOUT":{
            return {
                loading : false,
                error : null
            }
        }
        
        default:
            return state;
    }
}
