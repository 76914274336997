import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,agenda} from '../actions';
import Agenda from '../pages/Agenda';

export default connect(
    (state) => ({
        account: state.account,
        agenda : state.agenda
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...agenda
    }, dispatch)
)(Agenda);
