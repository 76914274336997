import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { account } from '../actions'
import DashboardLayout from '../pages/DashboardLayout'

export default connect(
  state => ({
   account : state.account
  }),
  dispatch => bindActionCreators({
    ...account
  }, dispatch)
)(DashboardLayout)