import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,invoices,proyects} from '../actions';
import Invoices from '../pages/Invoices';

export default connect(
    (state) => ({
        account: state.account,
        invoices : state.invoices,
        proyects : state.proyects
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...invoices,
        ...proyects
    }, dispatch)
)(Invoices);
