import {RoomsApi} from '../api'

export const FETCH_ROOM_REQUEST = "FETCH_ROOM_REQUEST"
export const FETCH_ROOM_SUCCESS = "FETCH_ROOM_SUCCESS"
export const FETCH_ROOM_ERROR = "FETCH_ROOM_ERROR"
export const CURRENT_BOOKING_DATA = "CURRENT_BOOKING_DATA"
export const FETCH_ROOM_TURNS_SUCCESS = "FETCH_ROOM_TURNS_SUCCESS"

export function fetchRoomTurns(room_id){
    return function(dispatch){
        dispatch(fetchRoomsRequest())
        return RoomsApi.get(`room_turns/${room_id}`)
        .then(response => {
            if(response.response){
                dispatch(fetchRoomTurnsSuccess(response.result))
            }else{
                dispatch(fetchRoomsError("CANT_GET_HOUR_BOOKING"))
            }
        })
    }
}

function fetchRoomTurnsSuccess(turns){
    return {
        type : FETCH_ROOM_TURNS_SUCCESS,
        turns
    }
}

export function getHourBooking(hourData){
    return function(dispatch){
        dispatch(fetchRoomsRequest())
        return RoomsApi.post({
            ...hourData
        },'hour')
        .then(response => {
            if(response.response){
                dispatch(fetchHourBookingSuccess(response.result))
            }else{
                dispatch(fetchRoomsError("CANT_GET_HOUR_BOOKING"))
            }
        })
    }
}

function fetchHourBookingSuccess(booking){
    return {
        type : CURRENT_BOOKING_DATA,
        booking
    }
}

export function createOrUpdateRoom(room,request_by){
    return function(dispatch){
        dispatch(fetchRoomsRequest())
        return RoomsApi.post({
            ...room,
            request_by
        },'save')
        .then(response => {
            if(response.response){
                dispatch(fetchRooms(1))
            }else{
                dispatch(fetchRoomsError("CANT_CRUD_ROOM"))
            }
        })
    }
}

export function deleteRoom(room_id,request_by){
    return function(dispatch){
        dispatch(fetchRoomsRequest())
        return RoomsApi.post({
            room_id,
            request_by
        },`delete`)
        .then(response => {
            if(response.response){
                dispatch(fetchRooms(1))
            }else{
                dispatch(fetchRoomsError("CANT_REMOVE_ROOM"))
            }
        })
    }
}

export function fetchRooms(page,date){
    return function(dispatch){
        dispatch(fetchRoomsRequest())
        return RoomsApi.get(`getAll/${page}/${date}`)
        .then(response => {
            if(response.response){
                dispatch(fetchRoomsSuccess(
                    response.result,
                    response.pages,
                    response.count
                ))
            }else{
                dispatch(fetchRoomsError("CANT_GET_ROOMS"))
            }
        })
    }
}

function fetchRoomsRequest(){
    return {
        type : FETCH_ROOM_REQUEST
    }
}

function fetchRoomsSuccess(rooms,pages,count){
    return {
        type : FETCH_ROOM_SUCCESS,
        rooms,
        pages,
        count
    }
}

function fetchRoomsError(error){
    return {
        type : FETCH_ROOM_ERROR,
        error
    }
}