import {NotesApi} from '../api'

export const FETCH_NOTES_REQUEST = "FETCH_NOTES_REQUEST"
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS"
export const FETCH_NOTES_ERROR = "FETCH_NOTES_ERROR"

export function fetchNotes(){
    return function(dispatch){
        dispatch(fetchNotesRequest())

        return NotesApi.get('getAll')
        .then(response => {
            if(response.response){
                dispatch(fetchNotesSuccess(response.result))
            }else{
                dispatch(fetchNotesError("NO_RESULTS"))
            }
        })
        .catch(error => dispatch(fetchNotesError(error)))
    }
}

export function createOrUpdateNote(note){
    return function(dispatch){
        dispatch(fetchNotesRequest())

        return NotesApi.post(note,'save')
        .then(response => {
            if(response.response){
                dispatch(fetchNotes())
            }else{
                dispatch(fetchNotesError("NO_UPDATE"))
            }
        })
        .catch(error => dispatch(fetchNotesError(error)))
    }
}

function fetchNotesRequest(){
    return {
        type : FETCH_NOTES_REQUEST
    }
}

function fetchNotesError(error){
    return {
        type : FETCH_NOTES_ERROR,
        error
    }
}

function fetchNotesSuccess(notes){
    return {
        type : FETCH_NOTES_SUCCESS,
        notes
    }
}