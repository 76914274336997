import React,{Component} from 'react'
import { Row, Col, Container, FormGroup, Label, Input,Table,Button,ButtonGroup} from 'reactstrap';
import {FormWidget,Form, ActivityIndicator} from '../components'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const editorToolbar = {
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
    colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: ['rgb(255,235,43)','rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
          'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
          'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
          'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
          'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
          'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)','rgb(0,0,0)','rgb(255,255,255)'],
    }
}

class Faqs extends Component{
    
    state = {
        faq : {},
        editorState : EditorState.createEmpty(),
        editorEnglishState : EditorState.createEmpty(),
        form : false
    }

    componentDidMount(){
        const {fetchFaqs} = this.props
        fetchFaqs(1);
    }

    toogleForm = (faq) => { 

        if(faq && faq.description && faq.description != ""){
            const contentBlock = htmlToDraft(faq.description);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

            const contentBlocken = htmlToDraft(faq.description_en);
            const contentStateen = ContentState.createFromBlockArray(contentBlocken.contentBlocks);
            this.setState({
                editorState : EditorState.createWithContent(contentState),
                editorEnglishState : EditorState.createWithContent(contentStateen)
            })
        }

        this.setState({
            form : !this.state.form, 
            faq,
        })
    }

    handleInputChange = ({field}) => evt => {        
        this.setState({ faq: {
            ...this.state.faq,
            [field] : evt.target.value
        }});
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
    }

    onEditorEnglishStateChange = (editorEnglishState) => {
        this.setState({
            editorEnglishState,
        });
    }

    onSubmit = (e) => {
        const {createOrUpdateFaq,account:{data}} = this.props
        const {faq, editorState, editorEnglishState} = this.state
        e.preventDefault();

        let description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        let eng_description = draftToHtml(convertToRaw(editorEnglishState.getCurrentContent()))

        if(faq && faq.title && faq.title != "" &&
        description && description != "" &&
        faq.title_en && faq.title_en != "" &&
        eng_description && eng_description != ""
        ){
            faq.description = description;
            faq.description_en = eng_description;

            createOrUpdateFaq(faq,data.user_name);
        }else{
            toast.warn('Verifique que todos los campos estén llenados', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    removeFaq(faq_id){
        const {deleteFaq} = this.props
        let conf = window.confirm("Realmente deseas eliminar esta pregunta?")
        if(conf){
            deleteFaq(faq_id)
        }
    }

    
    render(){
        const {form,faq,editorState,editorEnglishState} = this.state
        const {faqs:{collection,loading,error}} = this.props
        return(<div className="dashboard-content">
            <Container>
                    <FormWidget
                        title="Preguntas Frecuentes"
                        headerColor="#f5b13f"
                        icon="plus"
                        titleButton=" Añadir nueva"
                        onClick={(e) => {
                            e.preventDefault();
                            this.toogleForm();
                        }}
                        maxHeight={430}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                    <Table borderless>
                                        <thead>
                                        <tr>
                                            <th>Título</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {collection && collection.length > 0 && collection.map((faq) => (
                                                <tr index={faq.faq_id}>
                                                    <td>{faq && faq.title != "" ? faq.title : ""}</td>
                                                    <td>
                                                        <ButtonGroup>
                                                            <Button onClick={(e) => {
                                                                e.preventDefault();
                                                                this.toogleForm(faq);
                                                            }}>
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Button>
                                                            <Button color="danger" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.removeFaq(faq.faq_id)
                                                            }}>
                                                                <i className="fa fa-trash"></i>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        }
                    />

                    <Form 
                        show={form} 
                        onLeave={() => this.toogleForm()} >
                        <div className="form-content">
                            <div className="form-header">
                                <Row>
                                    <Col><span>Datos de la pregunta</span></Col>
                                    <Col className="text-align-right">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.toogleForm();
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <Container className="form-children-content">
                                <FormGroup>
                                    <Label>Título (Español)</Label>
                                    <Input defaultValue={faq && faq.title && faq.title != "" ? faq.title : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"title"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Título (Inglés)</Label>
                                    <Input defaultValue={faq && faq.title_en && faq.title_en != "" ? faq.title_en : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"title_en"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Descripción (Español)</Label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        editorState={editorState}
                                        toolbar={editorToolbar}
                                        localization={{
                                            locale: 'es',
                                        }}
                                        hashtag={{
                                            separator: ' ',
                                            trigger: '#',
                                        }}
                                        onEditorStateChange={this.onEditorStateChange}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Descripción (Inglés)</Label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        editorState={editorEnglishState}
                                        toolbar={editorToolbar}
                                        localization={{
                                            locale: 'en',
                                        }}
                                        hashtag={{
                                            separator: ' ',
                                            trigger: '#',
                                        }}
                                        onEditorStateChange={this.onEditorEnglishStateChange}
                                    />
                                </FormGroup>
                                
                                <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                        {!loading && ('Guardar Pregunta')}
                                        {loading && ('Espere por favor ...')}
                                </Button>
                            </Container>
                        </div>
                    </Form>
                </Container>
                <ToastContainer />
        </div>)
    }
}

export default Faqs