import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,products} from '../actions';
import Products from '../pages/Products';

export default connect(
    (state) => ({
        account: state.account,
        products : state.products
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...products
    }, dispatch)
)(Products);
