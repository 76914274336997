import React,{Component} from 'react'
import {Button,ButtonGroup} from 'reactstrap'
import propTypes from 'prop-types'

class FileInput extends Component {
    constructor() {
      super();
  
      this.deleteImage = this.deleteImage.bind(this);

      this.state = {
          edited : false
      }
    }
  
    deleteImage() {
      // Just setting input ref value to null did not work well with redux form
      // At the same time just calling on change with nothing didn't do the trick
      // just using onChange does the change in redux form but if you try selecting
      // the same image again it doesn't show in the preview cause the onChange of the
      // input is not called since for the input the value is not changing
      // but for redux form would be.
  
      this.fileInput.value = null;
      this.props.input.onClear();
    }
  
    render() {
      const { input: { onChange, value }, accept, disabled, error, style } = this.props;
      const { edited } = this.state;
  
      return (
        <div className="file-input-expanded" style={style}>
          {/* ref and on change are key properties here */}
          <input
            style={{display:"none"}}
            type="file"
            onChange={e => onChange(e.target.files[0])}
            multiple={false}
            accept={accept}
            capture
            ref={(input) => { this.fileInput = input; }}
            disabled={disabled}
          />
          {!value ?
            <Button
              type="button"
              onClick={() => this.fileInput.click()}
              disabled={disabled}
            >
                Elegir Imagen
            </Button>
            :
            <div className="file-input-container">
              <div className="flex-row">
                {/* Image preview */}
                <img src={value} alt="Logo" style={{backgroundColor:"#000",width:"100%"}} />
                <ButtonGroup>
                  {/* This button does de replacing */}
                  <Button
                    type="button"
                    onClick={() => this.fileInput.click()}
                    disabled={disabled}
                  ><i className="fa fa-pencil-alt"></i></Button>
                  {/* This button is the one that does de deleting */}
                  <Button
                    color="danger"
                    onClick={this.deleteImage}
                    disabled={disabled}
                  ><i className="fa fa-trash"></i></Button>
                </ButtonGroup>
              </div>
              {error &&
                <div className="error-message"> {error}</div>
              }
            </div>
          }
        </div>
      );
    }
  }
  
  FileInput.propTypes = {
    input: propTypes.object.isRequired,
    accept: propTypes.string,
    disabled: propTypes.bool,
    error: propTypes.string
  };
  
  FileInput.defaultProps = {
    accept: '*',
  };
  
  export default FileInput;