import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,settings} from '../actions';
import Settings from '../pages/Settings';

export default connect(
    (state) => ({
        settings: state.settings,
        account : state.settings
    }),
    (dispatch) => bindActionCreators({
        ...settings,
        ...account
    }, dispatch)
)(Settings);
