import {GiftcardsApi} from '../api'

export const FETCH_GIFTCARDS_REQUEST = "FETCH_GIFTCARDS_REQUEST"
export const FETCH_GIFTCARDS_ERROR = "FETCH_GIFTCARDS_ERROR"
export const FETCH_GIFTCARDS_SUCCESS = "FETCH_GIFTCARDS_SUCCESS"
export const FETCH_GIFTCARD_SINGLE_SUCCESS = "FETCH_GIFTCARD_SINGLE_SUCCESS"

export function getGiftcard(code){
    return function(dispatch){
        dispatch(fetchGiftcardsRequest())
        return GiftcardsApi.get(`unique/${code}`)
        .then(response => {
            if(response.response){
                console.log(response.result)
                dispatch(fetchGiftcardSuccess(response.result))
            }else{
                dispatch(fetchGiftcardsError("CANT_GET_GIFTCARD"))
            }
        })
        .catch(error => dispatch(fetchGiftcardsError(error)))
    }
}

export function autoGenerateGiftcards(data,request_by){
    return function(dispatch){
        dispatch(fetchGiftcardsRequest())
        return GiftcardsApi.post({
            ...data,
            request_by
        },'autogen')
        .then(response => {
            if(response.response){
                dispatch(fetchGiftcards(1))
            }else{
                dispatch(fetchGiftcardsError("CANT_AUTOGEN_GIFTCARDS"))
            }
        })
        .catch(error => dispatch(fetchGiftcardsError(error)))
    }
}

export function createOrUpdateGiftcard(giftcard,request_by){
    return function(dispatch){
        dispatch(fetchGiftcardsRequest())
        return GiftcardsApi.post({
            ...giftcard,
            request_by
        },'save')
        .then(response => {
            if(response.response){
                dispatch(fetchGiftcards(1))
            }else{
                dispatch(fetchGiftcardsError("CANT_CREATE_GIFTCARDS"))
            }
        })
        .catch(error => dispatch(fetchGiftcardsError(error)))
    }
}

export function fetchGiftcards(page){
    return function(dispatch){
        dispatch(fetchGiftcardsRequest())
        return GiftcardsApi.get(`getAll/${page}`)
        .then(response => {
            if(response.response){
                dispatch(fetchGiftcardsSuccess(
                    response.result,
                    response.total_rows,
                    response.total_pages
                ))
            }else{
                dispatch(fetchGiftcardsError("CANT_FETCH_GIFTCARDS"))
            }
        })
        .catch(error => dispatch(fetchGiftcardsError(error)))
    }
}

export function deleteGiftcard(giftcard_id,request_by){
    return function(dispatch){
        dispatch(fetchGiftcardsRequest())
        return GiftcardsApi.post({
            giftcard_id,
            request_by
        },`delete`)
        .then(response => {
            if(response.response){
                dispatch(fetchGiftcards(1))
            }else{
                dispatch(fetchGiftcardsError("Ocurrió un error al eliminar la giftcard"))
            }
        })
        .catch(error => dispatch(fetchGiftcardsError(error)))
    }
}

function fetchGiftcardSuccess(available){
    return {
        type : FETCH_GIFTCARD_SINGLE_SUCCESS,
        available
    }
}

function fetchGiftcardsRequest(){
    return {
        type : FETCH_GIFTCARDS_REQUEST
    }
}

function fetchGiftcardsSuccess(giftcards,rows,pages){
    return {
        type : FETCH_GIFTCARDS_SUCCESS,
        giftcards, rows, pages
    }
}

function fetchGiftcardsError(error){
    return {
        type : FETCH_GIFTCARDS_ERROR,
        error
    }
}