import {createStore, applyMiddleware} from "redux";
import {persistStore} from "redux-persist";
import createLogger from "redux-logger";
import thunkMiddleware from "redux-thunk";
import reducers from "./reducers";

const loggerMiddleware = createLogger();

const defaultStore = {
    
    //euforia
    sections : {
        collection: [],
        loading : false,
        error :null
    },
    sales : {
        collection : [],
        loading : false,
        error : null,
        pages : 0,
        count : 0
    },

    backup : {
        collection : [],
        loading : false,
        error : null,
        pages : 0,
        count : 0
    },

    bookings : {
        collection : [],
        loading : false,
        error : 0,
        weeklyCollection : [],
        monthlyCollection : []
    },

    account : {
        data : {},
        loading : false,
        error : null,
        modules : []
    },

    profiles : {
        collection : [],
        loading : false,
        error : null,
        pages : 0,
        count : 0
    },

    modules : {
        collection : [],
        loading : false,
        error : null
    },

    rooms : {
        collection : [],
        loading : false,
        error : null,
        pages : 0,
        count : 0,
        booking : {},
        turns : []
    },

    giftcards : {
        loading : false,
        error : null,
        collection : [],
        pages : 0,
        count : 0,
        isValid : false,
        single : {}
    },

    clients : {
        collection : [],
        loading : false,
        error : null,
        pages : 0,
        count : 0
    },

    users : {
        collection: [],
        loading : false,
        error : null
    },
    //euforia

    todos : {
        loading : false,
        error : null,
        collection : []
    },

    proyects  : {
        collection : [],
        loading : false,
        error : null,
        proyectServices : [],
        success : false
    },

    invoices : {
        loading : false,
        error : null,
        collection : []
    },

    products : {
        collection : [],
        loading : false,
        error : null
    },

    agenda : {
        collection : [],
        loading : false,
        error : null
    },

    notes : {
        loading: false,
        error : null,
        collection : []
    },

    settings : {
        pdf_mail : "",
        contact_phone : "",
        logo : "",
        success : false,
        loading : false,
        error : null
    },

    faqs : {
        error : null,
        loading : false,
        collection : []
    }
};

const store = createStore(reducers, defaultStore, applyMiddleware(thunkMiddleware));
persistStore(store, {
    storage: localStorage,
    whitelist: []
});
export default store;
