import React,{Component} from 'react'
import {Sidebar,PrivateRoute} from '../components';
import {Clients, Dashboard, Rooms, Users,Profiles, Giftcards, Backup, Sales, Sections, Faqs} from '../pages';

class DashboardLayout extends Component{
    render(){
        const {account} = this.props
        return(
            <div className="dashboard-wrap">
                <Sidebar 
                    user={account.data}
                    modules={account.modules}
                    logout={this.props.logout}
                />
                <PrivateRoute exact path="/dashboard/home" component={Dashboard} />
                <PrivateRoute exact path="/dashboard/clients" component={Clients} />
                <PrivateRoute exact path="/dashboard/users" component={Users} />
                <PrivateRoute exact path="/dashboard/rooms" component={Rooms} />
                <PrivateRoute exact path="/dashboard/profiles" component={Profiles} />
                <PrivateRoute exact path="/dashboard/giftcards" component={Giftcards} />
                <PrivateRoute exact path="/dashboard/backup" component={Backup} />
                <PrivateRoute exact path="/dashboard/sales" component={Sales} />
                <PrivateRoute exact path="/dashboard/sections" component={Sections} />
                <PrivateRoute exact path="/dashboard/faqs" component={Faqs} />
            </div>
        )
    }
}

export default DashboardLayout;