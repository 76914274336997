import {UsersApi} from '../api'

export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const ACCOUNT_ERROR = "ACCOUNT_ERROR";
export const ACCOUNT_SUCCESS = "ACCOUNT_SUCCESS";
export const ACCOUNT_LOGOUT = "ACCOUNT_LOGOUT"

export function loginUser(data){
    return function(dispatch){
        dispatch(accountRequest())

        return UsersApi.post(data,'login')
        .then(response => {
            if(response.response){
                dispatch(accountSuccess(response.result,response.modules))
            }else{
                dispatch(accountError("Usuario / contraseña incorrectos"))
            }
        })
        .catch(error => dispatch(accountError(error)))
    }
}

function accountRequest(){
    return {
        type : ACCOUNT_LOADING
    }
}

function accountError(error){
    return {
        type : ACCOUNT_ERROR,
        error
    }
}

function accountSuccess(account,modules){
    return {
        type : ACCOUNT_SUCCESS,
        account,
        modules
    }
}

export function logout(){
    return {
        type : ACCOUNT_LOGOUT
    }
}