import React,{Component} from 'react';
import {FormWidget,Form as MainForm,ActivityIndicator} from '../components';
import {Container, Form, FormGroup,Table,Row,Col,Label,Input,Button,ButtonGroup} from 'reactstrap';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment'
import striptags from 'striptags'
import './editor.css';

class Notes extends Component{

    constructor(){
        super()
        const html = '<p>Escriba aquí 😀</p>';
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

        this.state = {
            form : false,
            note : {},
            editorState : EditorState.createWithContent(contentState)
        }
    }

    componentDidMount(){
        const {fetchNotes} = this.props
        fetchNotes()
    }

    toogleForm = (note) => { 
        
        if(note && note.content && note.content != ""){
            const contentBlock = htmlToDraft(note.content);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            
            this.setState({
                form : !this.state.form, 
                note,
                editorState : EditorState.createWithContent(contentState)
            });
        }else{
            this.setState({
                form : !this.state.form, 
                note 
            });
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState
        });
    };

    handleInputChange = ({field}) => evt => {        
        this.setState({ note: {
            ...this.state.note,
            [field] : evt.target.value
        }});
    }

    onSubmit = (e) => {
        const {createOrUpdateNote} = this.props
        const {note,editorState} = this.state

        note.content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        console.log(note)
        e.preventDefault();
        createOrUpdateNote(note);
        //alert(JSON.stringify(client))
    }

    render(){
        const {note,form,editorState} = this.state;
        const {notes:{collection,loading,error}} = this.props
        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget 
                        title="Clientes"
                        headerColor="#215dff"
                        icon="plus"
                        onClick={(e) => {
                            e.preventDefault();
                            this.toogleForm();
                        }}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                    <Table borderless>
                                        <thead>
                                            <tr>
                                                <th>Título</th>
                                                <th>Descripción</th>
                                                <th>Fecha</th>
                                                <th>Acción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {collection && collection.length > 0 && collection.map((note,index) => (
                                                <tr key={index}>
                                                    <td>{note.title}</td>
                                                    <td>{striptags(note.content,[],'\n').substring(0,50)} ...</td>
                                                    <td>{moment(note.created_at).format("L")}</td>
                                                    <td>
                                                        <ButtonGroup>
                                                            <Button onClick={e => {
                                                                e.preventDefault();
                                                                this.toogleForm(note)
                                                            }}>
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Button>
                                                            <Button color="danger" onClick={e => {
                                                                e.preventDefault();
                                                                //this.toogleForm(note)
                                                            }}>
                                                                <i className="fa fa-trash"></i>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))}    
                                        </tbody>   
                                    </Table>
                                )}
                            </div>
                        }>
                    </FormWidget>

                    <MainForm 
                    show={form} 
                    onLeave={() => this.toogleForm()}>

                        <div className="form-content">
                            <div className="form-header">
                                <Row>
                                    <Col><span>Crear Nota</span></Col>
                                    <Col className="text-align-right">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.toogleForm();
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <Container className="form-children-content">

                                <FormGroup>
                                    <Label>Título de la nota</Label>
                                    <Input type="text" defaultValue={note && note.title != "" ? note.title : ""} onChange={this.handleInputChange({field:"title"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Contenido</Label>
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onEditorStateChange}
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                        }}
                                        localization={{
                                            locale: 'es',
                                        }}
                                        hashtag={{
                                            separator: ' ',
                                            trigger: '#',
                                        }}
                                    />                                        
                                </FormGroup>
                                <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                        {!loading && ('Guardar Nota')}
                                        {loading && ('Espere por favor ...')}
                                </Button>
                            </Container>
                       
                            
                            {/*<textarea
                                disabled

                                value={editorState ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : ''}
                            />*/}
                        </div>
                    </MainForm>
                </Container>
            </div>
        )
    }
}

export default Notes