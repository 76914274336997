import * as users from './users'
import * as account from './account'
import * as clients from './clients'
import * as proyects from './proyects'
import * as settings from './settings'
import * as todos from './todos'
import * as products from './products'
import * as agenda from './agenda'
import * as invoices from './invoices'
import * as notes from './notes'
//euforia
import * as profiles from './profiles'
import * as modules from './modules'
import * as rooms from './rooms'
import * as bookings from './bookings'
import * as giftcards from './giftcards'
import * as backup from './backup'
import * as sales from './sales'
import * as sections from './sections'
import * as faqs from './faqs'

export{
    faqs,
    sections,
    sales,
    backup,
    giftcards,
    bookings,
    notes,
    invoices,
    agenda,
    todos,
    settings,
    products,
    proyects,
    clients,
    account,
    users,
    //euforia
    rooms,
    profiles,
    modules
}