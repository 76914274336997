import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,notes} from '../actions';
import Notes from '../pages/Notes';

export default connect(
    (state) => ({
        notes : state.notes,
        account: state.account
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...notes
    }, dispatch)
)(Notes);
