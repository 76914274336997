import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,users,profiles} from '../actions';
import Users from '../pages/Users';

export default connect(
    (state) => ({
        account: state.account,
        users : state.users,
        profiles: state.profiles
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...users,
        ...profiles
    }, dispatch)
)(Users);
