import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,rooms} from '../actions';
import Rooms from '../pages/Rooms';

export default connect(
    (state) => ({
        account: state.account,
        rooms : state.rooms
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...rooms
    }, dispatch)
)(Rooms);
