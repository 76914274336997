import React, { Component } from 'react'
import { Row, Col, Container, FormGroup, Label, Input,Table,Button,CustomInput,ButtonGroup, InputGroup, InputGroupAddon } from 'reactstrap';
import { FormWidget ,Form, ActivityIndicator,Tabs, FileInput } from '../components'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Formik } from 'formik';
import * as yup from 'yup';

const editorToolbar = {
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
    colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: ['rgb(255,235,43)','rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
          'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
          'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
          'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
          'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
          'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)','rgb(0,0,0)','rgb(255,255,255)'],
    }
}

class Sections extends Component {

    state = {
        form : false,
        section : {},
        activeTab : 1, //spanish
    }

    componentDidMount(){
        const {fetchSections} = this.props
        fetchSections(1);
    }

    toogleForm = (section) => { 

        if(section && section.section_id == 1){//home
            section.langs.map((lang,index) => {
                const contentBlock = htmlToDraft(lang.section_fields.content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-content`] : EditorState.createWithContent(contentState)
                })
            })
        }

        if(section && section.section_id == 3){
            section.langs.map((lang,index) => {
                const contentBlock = htmlToDraft(lang.section_fields.content_important_notes_text);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-content_important_notes_text`] : EditorState.createWithContent(contentState)
                })
            })
        }

        if(section && section.section_id == 4){//empresa
            section.langs.map((lang,index) => {
                const contentBlock = htmlToDraft(lang.section_fields.empresa_content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-empresa_content`] : EditorState.createWithContent(contentState)
                })

                const contentBlock1 = htmlToDraft(lang.section_fields.employ_content);
                const contentState1 = ContentState.createFromBlockArray(contentBlock1.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-employ_content`] : EditorState.createWithContent(contentState1)
                })

                const contentBlock2 = htmlToDraft(lang.section_fields.team_content);
                const contentState2 = ContentState.createFromBlockArray(contentBlock2.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-team_content`] : EditorState.createWithContent(contentState2)
                })

                const contentBlock3 = htmlToDraft(lang.section_fields.company_content);
                const contentState3 = ContentState.createFromBlockArray(contentBlock3.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-company_content`] : EditorState.createWithContent(contentState3)
                })

                const contentBlock4 = htmlToDraft(lang.section_fields.content_packages_first_turns);
                const contentState4 = ContentState.createFromBlockArray(contentBlock4.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-content_packages_first_turns`] : EditorState.createWithContent(contentState4)
                })

                const contentBlock5 = htmlToDraft(lang.section_fields.content_packages_second_turns);
                const contentState5 = ContentState.createFromBlockArray(contentBlock5.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-content_packages_second_turns`] : EditorState.createWithContent(contentState5)
                })

                const contentBlock6 = htmlToDraft(lang.section_fields.content_packages_third_turns);
                const contentState6 = ContentState.createFromBlockArray(contentBlock6.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-content_packages_third_turns`] : EditorState.createWithContent(contentState6)
                })

                const contentBlock7 = htmlToDraft(lang.section_fields.content_packages_four_turns);
                const contentState7 = ContentState.createFromBlockArray(contentBlock7.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-content_packages_four_turns`] : EditorState.createWithContent(contentState7)
                })
            })
        }

        if(section && section.section_id == 7){
            section.langs.map((lang,index) => {
                const contentBlock = htmlToDraft(lang.section_fields.form_uno_content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-form_uno_content`] : EditorState.createWithContent(contentState)
                })

                const contentBlock2 = htmlToDraft(lang.section_fields.form_dos_content);
                const contentState2 = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-form_dos_content`] : EditorState.createWithContent(contentState)
                })

                const contentBlock3 = htmlToDraft(lang.section_fields.form_tres_content);
                const contentState3 = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-form_tres_content`] : EditorState.createWithContent(contentState)
                })

                const contentBlock4 = htmlToDraft(lang.section_fields.form_cuatro_content);
                const contentState4 = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-form_cuatro_content`] : EditorState.createWithContent(contentState)
                })
            })
        }

        if(section && section.section_id == 9){
            section.langs.map((lang,index) => {
                const contentBlock = htmlToDraft(lang.section_fields.privacy_policy_content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-privacy_policy_content`] : EditorState.createWithContent(contentState)
                })
            })
        }

        if(section && section.section_id == 10){
            section.langs.map((lang,index) => {
                const contentBlock = htmlToDraft(lang.section_fields.terms_condition_content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-terms_condition_content`] : EditorState.createWithContent(contentState)
                })
            })
        }

        if(section && section.section_id == 11){
            section.langs.map((lang,index) => {
                const contentBlock = htmlToDraft(lang.section_fields.work_with_us_content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-work_with_us_content`] : EditorState.createWithContent(contentState)
                })
            })
        }

        if(section && section.section_id == 12){
            section.langs.map((lang,index) => {
                const contentBlock = htmlToDraft(lang.section_fields.checkout_content);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({
                    [`${lang.lang_id}-checkout_content`] : EditorState.createWithContent(contentState)
                })
            })
        }

        this.setState({
            form : !this.state.form, 
            section
        })
    }

    handleInputChange = ({field}) => evt => {        
        this.setState({ room: {
            ...this.state.room,
            [field] : evt.target.value
        }});
    }

    initialValues(section){
        let values = {}

        if(section){

            const { langs } = section

            switch(section.section_id){
                case "1":
                case "3":
                case "4":
                case "7":
                case "9":
                case "10":
                case "11":
                case "12":
                    
                    langs.map((lang,i) => {
                        Object.keys(lang.section_fields).map((field,index) => {
                            if(field.indexOf('content') == -1){
                                values[`${lang.lang_id}-${field}`] = lang.section_fields[field]
                            }
                        })
                    
                    })

                break;

                case "2":
                case "5":
                case "6":
                    
                    langs.map((lang,i) => {
                        Object.keys(lang.section_fields).map((field,index) => {
                            values[`${lang.lang_id}-${field}`] = lang.section_fields[field]
                        })
                    })

                break;
            }

        }

        return values
    }

    validationsSchemas(section){
        let schema = {}

        if(section){

            const { langs } = section

            switch(section.section_id){
                case "1":
                case "3":
                case "4":
                case "7":
                    
                    langs.map((lang,i) => {
                        Object.keys(lang.section_fields).map((field,index) => {
                            if(field.indexOf('content') == -1){
                                schema[`${lang.lang_id}-${field}`] = yup.string().required("Este campo es requerido")
                            }
                        })
                    
                    })

                break;

                case "2":
                case "5":
                case "6":
                    langs.map((lang,i) => {
                        Object.keys(lang.section_fields).map((field,index) => {
                            schema[`${lang.lang_id}-${field}`] = yup.string().required("Este campo es requerido")
                        })
                    })
                break;
            }

        }

        return schema
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onSubmit = (values,actions) => {
        const {saveSection} = this.props;
        const { section } = this.state;

        if(section && section.section_id == 1){
            section.langs.map((lang,index) => {
                let description = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-content`].getCurrentContent()))
                values[`${lang.lang_id}-content`] = description
            })
        }

        if(section && section.section_id == 3){
            section.langs.map((lang,index) => {
                let description = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-content_important_notes_text`].getCurrentContent()))
                values[`${lang.lang_id}-content_important_notes_text`] = description
            })
        }

        if(section && section.section_id == 4){
            section.langs.map((lang,index) => {
                let description = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-company_content`].getCurrentContent()))
                values[`${lang.lang_id}-company_content`] = description

                let description1 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-empresa_content`].getCurrentContent()))
                values[`${lang.lang_id}-empresa_content`] = description1

                let description2 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-team_content`].getCurrentContent()))
                values[`${lang.lang_id}-team_content`] = description2

                let description4 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-employ_content`].getCurrentContent()))
                values[`${lang.lang_id}-employ_content`] = description4

                let description5 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-content_packages_first_turns`].getCurrentContent()))
                values[`${lang.lang_id}-content_packages_first_turns`] = description5

                let description6 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-content_packages_second_turns`].getCurrentContent()))
                values[`${lang.lang_id}-content_packages_second_turns`] = description6

                let description7 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-content_packages_third_turns`].getCurrentContent()))
                values[`${lang.lang_id}-content_packages_third_turns`] = description7

                let description8 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-content_packages_four_turns`].getCurrentContent()))
                values[`${lang.lang_id}-content_packages_four_turns`] = description8
            })
        }

        if(section && section.section_id == 7){
            section.langs.map((lang,index) => {
                let description = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-form_uno_content`].getCurrentContent()))
                values[`${lang.lang_id}-form_uno_content`] = description

                let description2 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-form_dos_content`].getCurrentContent()))
                values[`${lang.lang_id}-form_dos_content`] = description2

                let description3 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-form_tres_content`].getCurrentContent()))
                values[`${lang.lang_id}-form_tres_content`] = description3

                let description4 = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-form_cuatro_content`].getCurrentContent()))
                values[`${lang.lang_id}-form_cuatro_content`] = description4
            })
        }

        if(section && section.section_id == 9){
            section.langs.map((lang,index) => {
                let description = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-privacy_policy_content`].getCurrentContent()))
                values[`${lang.lang_id}-privacy_policy_content`] = description
            })
        }
        
        if(section && section.section_id == 10){
            section.langs.map((lang,index) => {
                let description = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-terms_condition_content`].getCurrentContent()))
                values[`${lang.lang_id}-terms_condition_content`] = description
            })
        }
        
        if(section && section.section_id == 11){
            section.langs.map((lang,index) => {
                let description = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-work_with_us_content`].getCurrentContent()))
                values[`${lang.lang_id}-work_with_us_content`] = description
            })
        }

        if(section && section.section_id == 12){
            section.langs.map((lang,index) => {
                let description = draftToHtml(convertToRaw(this.state[`${lang.lang_id}-checkout_content`].getCurrentContent()))
                values[`${lang.lang_id}-checkout_content`] = description
            })
        }
        
        saveSection(section.section_id,values)
    }
    
    render(){
        const { form, section, activeTab, homeEditorState } = this.state
        const { loading, error, collection } = this.props.sections
        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget
                        title="Secciones estáticas"
                        headerColor="#f5b13f"
                        icon=""
                        titleButton={null}
                        onClick={(e) => {
                            e.preventDefault();
                            //this.toogleForm();
                        }}
                        maxHeight={430}
                        render={
                            <div>
                            {error && !loading && (<span>{error.toString()}</span>)}
                            {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                            {!loading && !error && collection && collection.length > 0 && (
                                <Table borderless>
                                    <thead>
                                    <tr>
                                        <th>Título</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {collection && collection.length > 0 && collection.map((section,index) => (
                                            <tr index={section.section_id}>
                                                <td>{section && section.section_title != "" ? section.section_title : ""}</td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button onClick={(e) => {
                                                            e.preventDefault();
                                                            this.toogleForm(section);
                                                        }}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Button>
                                                        <Button color="danger" onClick={(e) => {
                                                            e.preventDefault();
                                                            
                                                        }}>
                                                            <i className="fa fa-trash"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                        }
                    />
                </Container>

                <Form 
                    show={form} 
                    onLeave={() => this.toogleForm()} >
                    <div className="form-content">
                        <div className="form-header">
                            <Row>
                                <Col><span>Datos de la sección</span></Col>
                                <Col className="text-align-right">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.toogleForm();
                                    }}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                        <Container className="form-children-content">
                            <Tabs 
                                active={activeTab}
                                items={section && section.langs ? section.langs : []}
                                returnableValue={"lang_id"}
                                onChangeTab={current => this.setState({activeTab : current})}
                            />

                            <Formik 
                                initialValues={this.initialValues(section)}
                                validateOnChange={true}
                                validationSchema={yup.object().shape(
                                    this.validationsSchemas(section)
                                )}
                                onSubmit={this.onSubmit}
                                render={({
                                  values,
                                  errors,
                                  status,
                                  touched,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                                }) => (
                                    <div>
                                        {section && section.langs && section.langs.map((lang,index) => {

                                            //home spanish
                                            return (<div key={lang.lang_id}>
                                            
                                            {section && section.section_id == 1 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Imagen ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-background_image`,base64File)
                                                                    });
                                                                 },
                                                                value : values[`${lang.lang_id}-background_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-background_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-background_image`] && <span className="error-message">{errors[`${lang.lang_id}-background_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.title ? lang.section_fields.title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-title`)} />
                                                        {errors[`${lang.lang_id}-title`] && <span className="error-message">{errors[`${lang.lang_id}-title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Texto del botón ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.button_text ? lang.section_fields.button_text : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-button_text`)} />
                                                        {errors[`${lang.lang_id}-button_text`] && <span className="error-message">{errors[`${lang.lang_id}-button_text`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Enlace del botón ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.button_link ? lang.section_fields.button_link : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-button_link`)} />
                                                        {errors[`${lang.lang_id}-button_link`] && <span className="error-message">{errors[`${lang.lang_id}-button_link`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}

                                            {section && section.section_id == 2 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Imagen ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-quienes_background_image`,base64File)
                                                                    });
                                                                 },
                                                                value : values[`${lang.lang_id}-quienes_background_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-quienes_background_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-quienes_background_image`] && <span className="error-message">{errors[`${lang.lang_id}-quienes_background_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.title_que_es ? lang.section_fields.title_que_es : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-title_que_es`)} />
                                                        {errors[`${lang.lang_id}-title_que_es`] && <span className="error-message">{errors[`${lang.lang_id}-title_que_es`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título participantes ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.participants ? lang.section_fields.participants : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-participants`)} />
                                                        {errors[`${lang.lang_id}-participants`] && <span className="error-message">{errors[`${lang.lang_id}-participants`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Texto participantes ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.participants_text ? lang.section_fields.participants_text : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-participants_text`)} />
                                                        {errors[`${lang.lang_id}-participants_text`] && <span className="error-message">{errors[`${lang.lang_id}-participants_text`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título diferentes ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.diferents ? lang.section_fields.diferents : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-diferents`)} />
                                                        {errors[`${lang.lang_id}-diferents`] && <span className="error-message">{errors[`${lang.lang_id}-diferents`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Texto diferentes ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.diferents_text ? lang.section_fields.diferents_text : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-diferents_text`)} />
                                                        {errors[`${lang.lang_id}-diferents_text`] && <span className="error-message">{errors[`${lang.lang_id}-diferents_text`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Texto debajo del botón ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.bottom_text_add ? lang.section_fields.bottom_text_add : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-bottom_text_add`)} />
                                                        {errors[`${lang.lang_id}-bottom_text_add`] && <span className="error-message">{errors[`${lang.lang_id}-bottom_text_add`]}</span>}
                                                    </FormGroup>
                                                    
                                                </div>
                                            )}

                                            {section && section.section_id == 3 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    
                                                    
                                                    <div style={{borderColor:"black",borderBottom:1}}>
                                                        <p>Precio 1</p>
                                                        <FormGroup>
                                                            <Label>Jugadores ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.players_uno ? lang.section_fields.players_uno : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-players_uno`)}/>
                                                            {errors[`${lang.lang_id}-players_uno`] && <span className="error-message">{errors[`${lang.lang_id}-players_uno`]}</span>}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Precio ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.precio_uno ? lang.section_fields.precio_uno : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-precio_uno`)}/>
                                                            {errors[`${lang.lang_id}-precio_uno`] && <span className="error-message">{errors[`${lang.lang_id}-precio_uno`]}</span>}
                                                        </FormGroup>
                                                    </div>
                                                    <div style={{borderColor:"black",borderBottom:1}}>
                                                        <p>Precio 2</p>
                                                        <FormGroup>
                                                            <Label>Jugadores ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.players_dos ? lang.section_fields.players_dos : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-players_dos`)}/>
                                                            {errors[`${lang.lang_id}-players_dos`] && <span className="error-message">{errors[`${lang.lang_id}-players_dos`]}</span>}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Precio ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.precio_dos ? lang.section_fields.precio_dos : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-precio_dos`)}/>
                                                            {errors[`${lang.lang_id}-precio_dos`] && <span className="error-message">{errors[`${lang.lang_id}-precio_dos`]}</span>}
                                                        </FormGroup>
                                                    </div>
                                                    <div style={{borderColor:"black",borderBottom:1}}>
                                                        <p>Precio 3</p>
                                                        <FormGroup>
                                                            <Label>Jugadores ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.players_tres ? lang.section_fields.players_tres : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-players_tres`)}/>
                                                            {errors[`${lang.lang_id}-players_tres`] && <span className="error-message">{errors[`${lang.lang_id}-players_tres`]}</span>}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Precio ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.precio_tres ? lang.section_fields.precio_tres : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-precio_tres`)}/>
                                                            {errors[`${lang.lang_id}-precio_tres`] && <span className="error-message">{errors[`${lang.lang_id}-precio_tres`]}</span>}
                                                        </FormGroup>
                                                    </div>
                                                    <div style={{borderColor:"black",borderBottom:1}}>
                                                        <p>Precio 4</p>
                                                        <FormGroup>
                                                            <Label>Jugadores ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.players_cuatro ? lang.section_fields.players_cuatro : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-players_cuatro`)}/>
                                                            {errors[`${lang.lang_id}-players_cuatro`] && <span className="error-message">{errors[`${lang.lang_id}-players_cuatro`]}</span>}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Precio ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.precio_cuatro ? lang.section_fields.precio_cuatro : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-precio_cuatro`)}/>
                                                            {errors[`${lang.lang_id}-precio_cuatro`] && <span className="error-message">{errors[`${lang.lang_id}-precio_cuatro`]}</span>}
                                                        </FormGroup>
                                                    </div>
                                                    <div style={{borderColor:"black",borderBottom:1}}>
                                                        <p>Precio 5</p>
                                                        <FormGroup>
                                                            <Label>Jugadores ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.players_cinco ? lang.section_fields.players_cinco : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-players_cinco`)}/>
                                                            {errors[`${lang.lang_id}-players_cinco`] && <span className="error-message">{errors[`${lang.lang_id}-players_cinco`]}</span>}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Precio ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.precio_cinco ? lang.section_fields.precio_cinco : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-precio_cinco`)}/>
                                                            {errors[`${lang.lang_id}-precio_cinco`] && <span className="error-message">{errors[`${lang.lang_id}-precio_cinco`]}</span>}
                                                        </FormGroup>
                                                    </div>
                                                    <div style={{borderColor:"black",borderBottom:1}}>
                                                        <p>Precio 6</p>
                                                        <FormGroup>
                                                            <Label>Jugadores ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.players_seis ? lang.section_fields.players_seis : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-players_seis`)}/>
                                                            {errors[`${lang.lang_id}-players_seis`] && <span className="error-message">{errors[`${lang.lang_id}-players_seis`]}</span>}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Precio ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.precio_seis ? lang.section_fields.precio_seis : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-precio_seis`)}/>
                                                            {errors[`${lang.lang_id}-precio_seis`] && <span className="error-message">{errors[`${lang.lang_id}-precio_seis`]}</span>}
                                                        </FormGroup>
                                                    </div>
                                                    <div style={{borderColor:"black",borderBottom:1}}>
                                                        <p>Precio 7</p>
                                                        <FormGroup>
                                                            <Label>Jugadores ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.players_siete ? lang.section_fields.players_siete : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-players_siete`)}/>
                                                            {errors[`${lang.lang_id}-players_siete`] && <span className="error-message">{errors[`${lang.lang_id}-players_siete`]}</span>}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Precio ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.precio_siete ? lang.section_fields.precio_siete : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-precio_siete`)}/>
                                                            {errors[`${lang.lang_id}-precio_siete`] && <span className="error-message">{errors[`${lang.lang_id}-precio_siete`]}</span>}
                                                        </FormGroup>
                                                    </div>

                                                    <FormGroup>
                                                            <Label>Texto de <strong>Notas importantes</strong> ({lang.name})</Label>
                                                            <Input defaultValue={lang.section_fields && lang.section_fields.important_notes_text ? lang.section_fields.important_notes_text : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-important_notes_text`)}/>
                                                            {errors[`${lang.lang_id}-important_notes_text`] && <span className="error-message">{errors[`${lang.lang_id}-important_notes_text`]}</span>}
                                                        </FormGroup>

                                                    <FormGroup>
                                                        <Label>Contenido Notas importantes ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-content_important_notes_text`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-content_important_notes_text`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                    
                                                </div>
                                            )}

                                            {section && section.section_id == 4 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Imagen fondo ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-company_background_image`,base64File)
                                                                    });
                                                                 },
                                                                value : values[`${lang.lang_id}-company_background_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-company_background_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-company_background_image`] && <span className="error-message">{errors[`${lang.lang_id}-company_background_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.empresa_title ? lang.section_fields.empresa_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-empresa_title`)}/>
                                                        {errors[`${lang.lang_id}-empresa_title`] && <span className="error-message">{errors[`${lang.lang_id}-empresa_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-empresa_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-empresa_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Botón paquetes ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.packages_button_text ? lang.section_fields.packages_button_text : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-packages_button_text`)}/>
                                                        {errors[`${lang.lang_id}-packages_button_text`] && <span className="error-message">{errors[`${lang.lang_id}-packages_button_text`]}</span>}
                                                    </FormGroup>
                                                    
                                                    <FormGroup>
                                                        <Label>Paquete 1 turnos ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.packages_1_turns ? lang.section_fields.packages_1_turns : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-packages_1_turns`)}/>
                                                        {errors[`${lang.lang_id}-packages_1_turns`] && <span className="error-message">{errors[`${lang.lang_id}-packages_1_turns`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Paquete 1 Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-content_packages_first_turns`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-content_packages_first_turns`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Paquete 2 turnos ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.packages_2_turns ? lang.section_fields.packages_2_turns : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-packages_2_turns`)}/>
                                                        {errors[`${lang.lang_id}-packages_2_turns`] && <span className="error-message">{errors[`${lang.lang_id}-packages_2_turns`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Paquete 2 Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-content_packages_second_turns`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-content_packages_second_turns`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Paquete 3 turnos ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.packages_2_turns ? lang.section_fields.packages_2_turns : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-packages_2_turns`)}/>
                                                        {errors[`${lang.lang_id}-packages_2_turns`] && <span className="error-message">{errors[`${lang.lang_id}-packages_2_turns`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Paquete 3 Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-content_packages_third_turns`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-content_packages_third_turns`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Paquete 4 turnos ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.packages_4_turns ? lang.section_fields.packages_4_turns : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-packages_4_turns`)}/>
                                                        {errors[`${lang.lang_id}-packages_4_turns`] && <span className="error-message">{errors[`${lang.lang_id}-packages_4_turns`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Paquete 4 Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-content_packages_four_turns`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-content_packages_four_turns`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Text debajo del botón ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.bottom_text ? lang.section_fields.bottom_text : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-bottom_text`)}/>
                                                        {errors[`${lang.lang_id}-bottom_text`] && <span className="error-message">{errors[`${lang.lang_id}-bottom_text`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título derecho ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.right_title ? lang.section_fields.right_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-right_title`)}/>
                                                        {errors[`${lang.lang_id}-right_title`] && <span className="error-message">{errors[`${lang.lang_id}-right_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Imagen Empleado ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-employ_image`,base64File)
                                                                    });
                                                                 },
                                                                value : values[`${lang.lang_id}-employ_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-employ_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-employ_image`] && <span className="error-message">{errors[`${lang.lang_id}-employ_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción Empleado ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-employ_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-employ_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Imagen Equipo ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-team_image`,base64File)
                                                                    });
                                                                 },
                                                                value : values[`${lang.lang_id}-team_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-team_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-team_image`] && <span className="error-message">{errors[`${lang.lang_id}-team_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción Equipo ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-team_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-team_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Imagen Empresa ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-company_image`,base64File)
                                                                    });
                                                                 },
                                                                value : values[`${lang.lang_id}-company_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-company_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-company_image`] && <span className="error-message">{errors[`${lang.lang_id}-company_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción Empresa ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-company_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-company_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}

                                            {section && section.section_id == 5 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Imagen fondo ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-contact_background_image`,base64File)
                                                                    });
                                                                },
                                                                value : values[`${lang.lang_id}-contact_background_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-contact_background_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-contact_background_image`] && <span className="error-message">{errors[`${lang.lang_id}-contact_background_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título izquierdo ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.contact_left_title ? lang.section_fields.contact_left_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-contact_left_title`)}/>
                                                        {errors[`${lang.lang_id}-contact_left_title`] && <span className="error-message">{errors[`${lang.lang_id}-contact_left_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del campo <strong>Nombre</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.contact_name_field ? lang.section_fields.contact_name_field : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-contact_name_field`)}/>
                                                        {errors[`${lang.lang_id}-contact_name_field`] && <span className="error-message">{errors[`${lang.lang_id}-contact_name_field`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del campo <strong>Email</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.contact_email_field ? lang.section_fields.contact_email_field : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-contact_email_field`)}/>
                                                        {errors[`${lang.lang_id}-contact_email_field`] && <span className="error-message">{errors[`${lang.lang_id}-contact_email_field`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del campo <strong>Celular</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.contact_phone_field ? lang.section_fields.contact_phone_field : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-contact_phone_field`)}/>
                                                        {errors[`${lang.lang_id}-contact_phone_field`] && <span className="error-message">{errors[`${lang.lang_id}-contact_phone_field`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del campo <strong>Asunto</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.contact_subject_field ? lang.section_fields.contact_subject_field : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-contact_subject_field`)}/>
                                                        {errors[`${lang.lang_id}-contact_subject_field`] && <span className="error-message">{errors[`${lang.lang_id}-contact_subject_field`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del campo <strong>Mensaje</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.contact_message_field ? lang.section_fields.contact_message_field : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-contact_message_field`)}/>
                                                        {errors[`${lang.lang_id}-contact_message_field`] && <span className="error-message">{errors[`${lang.lang_id}-contact_message_field`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título drecho ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.contact_right_title ? lang.section_fields.contact_right_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-contact_right_title`)}/>
                                                        {errors[`${lang.lang_id}-contact_right_title`] && <span className="error-message">{errors[`${lang.lang_id}-contact_right_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Dirección ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.contact_address ? lang.section_fields.contact_address : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-contact_address`)}/>
                                                        {errors[`${lang.lang_id}-contact_address`] && <span className="error-message">{errors[`${lang.lang_id}-contact_address`]}</span>}
                                                    </FormGroup>
                                                </div>
                                            )}

                                            {section && section.section_id == 6 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Imagen fondo ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-reserva_background_image`,base64File)
                                                                    });
                                                                },
                                                                value : values[`${lang.lang_id}-reserva_background_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-reserva_background_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-reserva_background_image`] && <span className="error-message">{errors[`${lang.lang_id}-reserva_background_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Nombre del campo <strong>Número de personas</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.reserva_players_count ? lang.section_fields.reserva_players_count : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-reserva_players_count`)}/>
                                                        {errors[`${lang.lang_id}-reserva_players_count`] && <span className="error-message">{errors[`${lang.lang_id}-reserva_players_count`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Nombre del campo <strong>Nombre</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.reserva_user_name ? lang.section_fields.reserva_user_name : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-reserva_user_name`)}/>
                                                        {errors[`${lang.lang_id}-reserva_user_name`] && <span className="error-message">{errors[`${lang.lang_id}-reserva_user_name`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Nombre del campo <strong>Email</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.reserva_user_email ? lang.section_fields.reserva_user_email : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-reserva_user_email`)}/>
                                                        {errors[`${lang.lang_id}-reserva_user_email`] && <span className="error-message">{errors[`${lang.lang_id}-reserva_user_email`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Nombre del campo <strong>Celular</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.reserva_user_phone ? lang.section_fields.reserva_user_phone : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-reserva_user_phone`)}/>
                                                        {errors[`${lang.lang_id}-reserva_user_phone`] && <span className="error-message">{errors[`${lang.lang_id}-reserva_user_phone`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Nombre del campo <strong>Cortesía</strong> ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.reserva_cortesia ? lang.section_fields.reserva_cortesia : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-reserva_cortesia`)}/>
                                                        {errors[`${lang.lang_id}-reserva_cortesia`] && <span className="error-message">{errors[`${lang.lang_id}-reserva_cortesia`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Texto debjo del precio ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.reserva_message ? lang.section_fields.reserva_message : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-reserva_message`)}/>
                                                        {errors[`${lang.lang_id}-reserva_message`] && <span className="error-message">{errors[`${lang.lang_id}-reserva_message`]}</span>}
                                                    </FormGroup>
                                                </div>
                                            )}

                                            {section && section.section_id == 7 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Imagen primer cuadro ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-form_uno_image`,base64File)
                                                                    });
                                                                },
                                                                value : values[`${lang.lang_id}-form_uno_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-form_uno_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-form_uno_image`] && <span className="error-message">{errors[`${lang.lang_id}-form_uno_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del primer cuadro ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.form_uno_title ? lang.section_fields.form_uno_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-form_uno_title`)}/>
                                                        {errors[`${lang.lang_id}-form_uno_title`] && <span className="error-message">{errors[`${lang.lang_id}-form_uno_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción del primer cuadro ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-form_uno_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-form_uno_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Imagen segundo cuadro ({lang.name})</Label>
                                                        <FileInput 
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-form_dos_image`,base64File)
                                                                    });
                                                                },
                                                                value : values[`${lang.lang_id}-form_dos_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-form_dos_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-form_dos_image`] && <span className="error-message">{errors[`${lang.lang_id}-form_dos_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del segundo cuadro ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.form_dos_title ? lang.section_fields.form_dos_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-form_dos_title`)}/>
                                                        {errors[`${lang.lang_id}-form_dos_title`] && <span className="error-message">{errors[`${lang.lang_id}-form_dos_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción del segundo cuadro ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-form_dos_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-form_dos_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Imagen tercer cuadro ({lang.name})</Label>
                                                        <FileInput
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-form_tres_image`,base64File)
                                                                    });
                                                                },
                                                                value : values[`${lang.lang_id}-form_tres_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-form_tres_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-form_tres_image`] && <span className="error-message">{errors[`${lang.lang_id}-form_tres_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del tercer cuadro ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.form_tres_title ? lang.section_fields.form_tres_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-form_tres_title`)}/>
                                                        {errors[`${lang.lang_id}-form_tres_title`] && <span className="error-message">{errors[`${lang.lang_id}-form_tres_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción del tercer cuadro ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-form_tres_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-form_tres_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label>Imagen cuatro cuadro ({lang.name})</Label>
                                                        <FileInput
                                                            accept={".jpg,.jpeg,.png"}
                                                            input={{
                                                                onChange : (file) => { 
                                                                    let base64File = '';
                                                                    this.getBase64(file, (result) => {
                                                                        base64File = result;
                                                                        setFieldValue(`${lang.lang_id}-form_cuatro_image`,base64File)
                                                                    });
                                                                },
                                                                value : values[`${lang.lang_id}-form_cuatro_image`],
                                                                onClear : () => setFieldValue(`${lang.lang_id}-form_cuatro_image`,"")
                                                            }}
                                                        />
                                                        {errors[`${lang.lang_id}-form_cuatro_image`] && <span className="error-message">{errors[`${lang.lang_id}-form_cuatro_image`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Título del tercer cuadro ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.form_cuatro_title ? lang.section_fields.form_cuatro_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-form_cuatro_title`)}/>
                                                        {errors[`${lang.lang_id}-form_cuatro_title`] && <span className="error-message">{errors[`${lang.lang_id}-form_cuatro_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción del cuarto cuadro ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-form_cuatro_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-form_cuatro_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}

                                            {section && section.section_id == 9 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Título ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.privacy_policy_title ? lang.section_fields.privacy_policy_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-privacy_policy_title`)}/>
                                                        {errors[`${lang.lang_id}-privacy_policy_title`] && <span className="error-message">{errors[`${lang.lang_id}-privacy_policy_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-privacy_policy_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-privacy_policy_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}
                                            {section && section.section_id == 10 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Título ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.terms_condition_title ? lang.section_fields.terms_condition_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-terms_condition_title`)}/>
                                                        {errors[`${lang.lang_id}-terms_condition_title`] && <span className="error-message">{errors[`${lang.lang_id}-terms_condition_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-terms_condition_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-terms_condition_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}
                                            {section && section.section_id == 11 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Título ({lang.name})</Label>
                                                        <Input defaultValue={lang.section_fields && lang.section_fields.work_with_us_title ? lang.section_fields.work_with_us_title : ""} type="text"  placeholder="" onChange={handleChange(`${lang.lang_id}-work_with_us_title`)}/>
                                                        {errors[`${lang.lang_id}-work_with_us_title`] && <span className="error-message">{errors[`${lang.lang_id}-work_with_us_title`]}</span>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-work_with_us_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-work_with_us_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}
                                            {section && section.section_id == 12 && (
                                                <div style={{ display: activeTab == lang.lang_id ? 'block' : 'none' }}>
                                                    <FormGroup>
                                                        <Label>Descripción ({lang.name})</Label>
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            editorState={this.state[`${lang.lang_id}-checkout_content`]}
                                                            toolbar={editorToolbar}
                                                            localization={{
                                                                locale: 'es',
                                                            }}
                                                            hashtag={{
                                                                separator: ' ',
                                                                trigger: '#',
                                                            }}
                                                            onEditorStateChange={(editorState) => this.setState({
                                                                [`${lang.lang_id}-checkout_content`] : editorState,
                                                            })}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            )}
            
                                        
                                            </div>)
                                        
                                        })}
            
                                        <Button disabled={loading ? true : false} onClick={handleSubmit}>
                                                {!loading && ('Guardar Sección')}
                                                {loading && ('Espere por favor ...')}
                                        </Button>
                                    </div>
                                )}
                            />

                        </Container>
                    </div>
                </Form>
            </div>
        )
    }
}

export default Sections