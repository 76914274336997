import React,{Component} from 'react';
import { Route, Switch } from 'react-router-dom'
import {Login, DashboardLayout} from '../pages'

const LoginComponent = () => {
    return(
        <div className="login-wrap">
            <div className="login-overlay"></div>
            <Login />
        </div>
    )
}

class Main extends Component{

    componentWillReceiveProps(nextProps){
        if(nextProps.account.data !== this.props.account.data && nextProps.account.data){
            window.history.pushState('', '', './#/dashboard/home');
            //this.context.router.push("dashboard")
        }
    }

    render(){
        const {account} = this.props;
        console.log(account,'data logged')
        return(
            <div className={account && account.data && account.data.user_id ? 'dashboard-main' : 'login-main' }>
                <Route exact path="/" component={LoginComponent} />
                <Route path="/dashboard" component={DashboardLayout} />
            </div>
        )
    }
}

export default Main;