import {combineReducers} from "redux";

import users from "./users";
import account from './account';
import clients from './clients';
import products from './products';
import proyects from './proyects';
import settings from './settings';
import todos from './todos';
import agenda from './agenda';
import invoices from './invoices';
import notes from './notes';
//euforia
import profiles from './profiles'
import modules from './modules'
import rooms from './rooms'
import bookings from './bookings'
import giftcards from './giftcards'
import backup from './backup'
import sales from './sales'
import sections from './sections'
import faqs from './faqs'

const reducers = combineReducers({
	sections,
	faqs,
	sales,
	users,
	giftcards,
	notes,
	invoices,
	agenda,
	account,
	clients,
	products,
	proyects,
	settings,
	todos,
	//euforia
	bookings,
	rooms,
	profiles,
	modules,
	backup
});

export default reducers;
