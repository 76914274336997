import {rooms} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case rooms.FETCH_ROOM_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case rooms.CURRENT_BOOKING_DATA:
            return {
                ...state,
                loading : false,
                error : null,
                booking : action.booking
            }

        case rooms.FETCH_ROOM_TURNS_SUCCESS:
            return {
                ...state,
                loading : false,
                error : null,
                turns : action.turns
            }

        case rooms.FETCH_ROOM_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case rooms.FETCH_ROOM_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.rooms,
                count : action.rows,
                pages : action.pages
            }
        }

        case "ACCOUNT_LOGOUT":{
            return {
                loading : false,
                collection : [],
                count :0,
                pages:0,
                error:null
            }
        }

        default:
            return state;
    }
}