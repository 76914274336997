import {users} from '../actions'
export default function(state = {}, action) {

    switch(action.type){
        case users.FETCH_USERS_LOADING:
            return {
                ...state,
                loading : true
            }

        case users.FETCH_USERS_ERROR:
            return {
                ...state,
                loading : false,
                error : action.error
            }

        case users.FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading : false,
                error : null,
                collection : action.users
            }

        case "ACCOUNT_LOGOUT":{
            return {
                loading : false,
                error : null,
                collection : []
            }
        }
        
        default:
            return state;
    }
}
