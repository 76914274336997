import {profiles} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case profiles.FETCH_PROFILE_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case profiles.FETCH_PROFILE_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case profiles.FETCH_PROFILE_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.profiles,
                count : action.rows,
                pages : action.pages
            }
        }

        case "ACCOUNT_LOGOUT":{
            return {
                loading : false,
                collection : [],
                count : 0,
                pages : 0,
                error : null
            }
        }

        default:
            return state;
    }
}