import React,{Component} from 'react';
import { Transition, animated } from 'react-spring';

class ProyectForm extends Component{
    render(){
        const {onLeave,show,children} = this.props
        return(
            <div>
                <Transition
                    native
                    items={show}
                    from={{ cursor:'pointer',position: 'fixed', zIndex:3, right:0,top:0,bottom:0,left:0,backgroundColor:"rgba(0,0,0,0)" }}
                    enter={{ cursor:'pointer',backgroundColor:"rgba(0,0,0,0.8)",zIndex:3 }}
                    leave={{ cursor:'pointer',backgroundColor:"rgba(0,0,0,0)",zIndex:0 }}
                >
                    {show => show && (props => <animated.div style={props} onClick={() => onLeave()}>
                    </animated.div>)}
                </Transition>
                <Transition
                    native
                    items={show}
                    from={{ position: 'fixed', zIndex:3, right:0,top:0,bottom:0,left:'19%',transform:'translateY(100%)' }}
                    enter={{ backgroundColor:"rgba(0,0,0,0.8)",zIndex:3,left:'19%',bottom:0,transform:'translateY(0%)' }}
                    leave={{ backgroundColor:"rgba(0,0,0,0)",zIndex:0,left:'19%',bottom:0,transform:'translateY(100%)' }}
                >
                    {show => show && (props => <animated.div style={props}>
                        {children}
                    </animated.div>)}
                </Transition>
            </div>
        )
    }
}

export default ProyectForm;