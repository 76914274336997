import {invoices} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case invoices.FETCH_INVOICES_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case invoices.FETCH_INVOICES_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case invoices.FETCH_INVOICES_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.invoices
            }
        }

        default:
            return state;
    }
}