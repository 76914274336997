import {agenda} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case agenda.FETCH_SCHEDULES_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case agenda.FETCH_SCHEDULES_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case agenda.FETCH_SCHEDULES_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.schedules
            }
        }

        default:
            return state;
    }
}