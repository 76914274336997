import {modules} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case modules.FETCH_MODULE_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case modules.FETCH_MODULE_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case modules.FETCH_MODULE_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.modules
            }
        }

        case "ACCOUNT_LOGOUT":{
            return {
                loading : false,
                error : null,
                collection : []
            }
        }

        default:
            return state;
    }
}