import {giftcards} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case giftcards.FETCH_GIFTCARDS_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case giftcards.FETCH_GIFTCARDS_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case giftcards.FETCH_GIFTCARDS_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.giftcards,
                error : null,
                count : action.rows,
                pages : action.pages
            }
        }

        case giftcards.FETCH_GIFTCARD_SINGLE_SUCCESS:{
            console.log(action.available,'single')
            return {
                ...state,
                loading : false,
                error : null,
                isValid : action.available,
                single : action.available
            }
        }

        default:
            return state;
    }
}