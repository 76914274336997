import React,{Component} from 'react'
import './sidebar.css';
import { Row, Container } from 'reactstrap';
import {Link, Redirect} from 'react-router-dom';

const SidebarItem = (props) => {
    return(
        <Row>
            <Link to={props.path} onClick={e => props.onClick()} className={`sidebar-item ${props.active ? 'active' : ''}`}>
                <div className="blur"></div>
                <i className={`${props.iconType} fa-${props.icon}`}></i>
                <p>{props.title}</p>
            </Link>
        </Row>
    )
}

class Sidebar extends Component{

    constructor(){
        super()

        this.state = {
            active : "/dashboard/home",
            isLogout : false
        }
    }

    toggleActiveSection = active => {
        this.setState({active})
    }

    isAvailableForUser(module_slug){
        const {modules} = this.props
        let isAvailable = false
        modules.find((module,index) => {
            if(module.module_slug == module_slug){
                isAvailable = true
            }
        })

        return isAvailable;
    }

    render(){
        const {user,logout} = this.props
        const {active,isLogout} = this.state
        return(
            <div className="sidebar-wrap">
                
                <div className="avatar">
                    {/*<img src={`${user.avatar}`} />*/}
                    <p>Bienvenido, {user.user_name}</p>
                    <small>Administrador</small>
                </div>

                <Container>

                    {this.isAvailableForUser("booking") && (
                        <SidebarItem 
                            path="/dashboard/home"
                            iconType="fa"
                            icon="home"
                            title="Dashboard"
                            active={active == "/dashboard/home"}
                            onClick={() => this.toggleActiveSection("/dashboard/home")}
                        />
                    )}

                    {this.isAvailableForUser("rooms") && (
                    <SidebarItem 
                        path="/dashboard/rooms"
                        iconType="fa"
                        icon="dungeon"
                        title="Cuartos"
                        active={active == "/dashboard/rooms"}
                        onClick={() => this.toggleActiveSection("/dashboard/rooms")}
                    />)}

                    {this.isAvailableForUser("sections") && (
                    <SidebarItem 
                        path="/dashboard/sections"
                        iconType="fa"
                        icon="dungeon"
                        title="Secciones"
                        active={active == "/dashboard/sections"}
                        onClick={() => this.toggleActiveSection("/dashboard/sections")}
                    />)}

                    {this.isAvailableForUser("faqs") && (<SidebarItem 
                        path="/dashboard/faqs"
                        iconType="fa"
                        icon="box"
                        title="Faqs"
                        active={active == "/dashboard/faqs"}
                        onClick={() => this.toggleActiveSection("/dashboard/faqs")}
                    />)}

                    {this.isAvailableForUser("giftcards") && (
                    <SidebarItem 
                        path="/dashboard/giftcards"
                        iconType="fa"
                        icon="dollar-sign"
                        title="Giftcards"
                        active={active == "/dashboard/giftcards"}
                        onClick={() => this.toggleActiveSection("/dashboard/giftcards")}
                    />)}

                    {this.isAvailableForUser("sales") && (<SidebarItem 
                        path="/dashboard/sales"
                        iconType="fa"
                        icon="capsules"
                        title="Ventas"
                        active={active == "/dashboard/sales"}
                        onClick={() => this.toggleActiveSection("/dashboard/sales")}
                    />)}

                    {this.isAvailableForUser("clients") && (<SidebarItem 
                        path="/dashboard/clients"
                        iconType="fa"
                        icon="users"
                        title="Clientes"
                        active={active == "/dashboard/clients"}
                        onClick={() => this.toggleActiveSection("/dashboard/clients")}
                    />)}

                    {this.isAvailableForUser("users") && (<SidebarItem 
                        path="/dashboard/users"
                        iconType="fa"
                        icon="user"
                        title="Usuarios"
                        active={active == "/dashboard/users"}
                        onClick={() => this.toggleActiveSection("/dashboard/users")}
                    />)}

                    {this.isAvailableForUser("profiles") && (<SidebarItem 
                        path="/dashboard/profiles"
                        iconType="fa"
                        icon="poll-h"
                        title="Perfiles"
                        active={active == "/dashboard/profiles"}
                        onClick={() => this.toggleActiveSection("/dashboard/profiles")}
                    />)}

                    {this.isAvailableForUser("settings") && (<SidebarItem 
                        path="/dashboard/settings"
                        iconType="fa"
                        icon="cog"
                        title="Ajustes"
                        active={active == "/dashboard/settings"}
                        onClick={() => this.toggleActiveSection("/dashboard/settings")}
                    />)}

                    {this.isAvailableForUser("backup") && (<SidebarItem 
                        path="/dashboard/backup"
                        iconType="fa"
                        icon="box"
                        title="Backup"
                        active={active == "/dashboard/backup"}
                        onClick={() => this.toggleActiveSection("/dashboard/backup")}
                    />)}

                    <Row>
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            let conf = window.confirm("¿Deseas cerrar sesión?")
                            if(conf){
                                logout();
                                this.setState({isLogout:true})
                            }
                        }} className={`sidebar-item`}>
                            <div className="blur"></div>
                            <i className={`fa fa-times`}></i>
                            <p>Cerrar sesión</p>
                        </a>
                    </Row>

                    {isLogout && (<Redirect to={{pathname: "/", state: { from: this.props.location }}} />)}
                </Container>
            </div>
        )
    }
}

export default Sidebar