import React,{Component} from 'react'
import { CustomInput, Row, Col, Container, FormGroup, Label, Input,Table,Button,ButtonGroup } from 'reactstrap';
import {FormWidget,Form,ActivityIndicator} from '../components';
import moment from 'moment'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

class Invoices extends Component{
    constructor(){
        super();
        this.state = {
            form : false,
            product : {}
        }
    }

    componentDidMount(){
        const {getFactured,fetchProyects} = this.props
        getFactured();
        fetchProyects();
    }

    toogleForm = (product) => { this.setState({form : !this.state.form, product}) }

    handleInputChange = ({field}) => evt => {        
        this.setState({ product: {
            ...this.state.product,
            [field] : evt.target.value
        }});
    }

    onSubmit = (e) => {
        const {uploadProyectInvoice} = this.props
        const {product} = this.state
        e.preventDefault();
        console.log(product,'data')
        uploadProyectInvoice(product);
    }

    render(){
        const {form,product} = this.state;
        const {invoices:{collection,loading,error},proyects} = this.props
        return(
            <div className="dashboard-content">
                <Container>
                <FormWidget 
                        title="Facturas"
                        headerColor="#215dff"
                        icon="plus"
                        onClick={(e) => {
                            e.preventDefault();
                            this.toogleForm({});
                        }}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                    <Table borderless>
                                        <thead>
                                        <tr>
                                            <th>Proyecto</th>
                                            <th>Total Facturado</th>
                                            <th>Creado</th>
                                            <th>Facturado</th>
                                            <th>Archivo</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!loading && collection && collection.length > 0 && collection.map((product,index) => (
                                            
                                            <tr key={product.proyect_id}>
                                                <td>{product.title}</td>
                                                <td>{formatter.format(product.invoice_amount)}</td>
                                                <td>{moment(product.created_at).format('L')}</td>
                                                <td>{product.factured_at && product.factured_at != "0000-00-00" ? moment(product.factured_at).format('L') : 'Sin facturar'}</td>
                                                <td><Button onClick={(e) => {
                                                    e.preventDefault()
                                                    window.open(`${product.file}`,"_blank")
                                                }} disabled={(!product.factured_at || product.factured_at == "0000-00-00" || product.file == "") ? true : false}>
                                                    <i className="fa fa-download"></i>
                                                </Button></td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button onClick={(e) => {
                                                            e.preventDefault();
                                                            this.toogleForm(product);
                                                        }}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Button>
                                                        <Button color="danger" onClick={(e) => {
                                                            e.preventDefault();
                                                        }}>
                                                            <i className="fa fa-trash"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        }
                        maxHeight={430}
                    />
                </Container>

                <Form 
                    show={form} 
                    onLeave={() => this.toogleForm()} >
                    <div className="form-content">
                        <div className="form-header">
                            <Row>
                                <Col><span>Factura</span></Col>
                                <Col className="text-align-right">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.toogleForm();
                                    }}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                        <Container className="form-children-content">
                            <FormGroup>
                                <Label>Proyecto</Label>
                                <Input defaultValue={product && product.proyect_id ? product.proyect_id : ""} type="select" name="product_name" onChange={this.handleInputChange({field:"proyect_id"})} >
                                    <option value="">- Elija un opción -</option>
                                    {proyects && proyects.collection && proyects.collection.length > 0 && proyects.collection.map((item,index) => (
                                        <option key={index} value={item.proyect_id}>{item.proyect_name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>Fecha de facturación</Label>
                                <Input type="date" defaultValue={product && product.factured_at ? product.factured_at : ""} onChange={this.handleInputChange({field:"factured_at"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Monto de la facturación</Label>
                                <Input type="number" defaultValue={product && product.invoice_amount ? product.invoice_amount : 0} onChange={this.handleInputChange({field:"invoice_amount"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Factura</Label>
                                <CustomInput type="file" label="Elija un archivo" 
                                    accept={'.pdf,.xls,.doc,.pdf,.zip,.rar,.xlsx,.docx,.png,.jpg'}
                                    onChange={e => {
                                        this.setState({
                                            product : {
                                                ...product,
                                                file : e.target.files[0]
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                            <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                        {!loading && ('Guardar Factura')}
                                        {loading && ('Espere por favor ...')}
                            </Button>
                        </Container>
                    </div>
                </Form>
            </div>
        )
    }
}

export default Invoices