import React,{Component} from 'react'
import { Row, Col, Container, FormGroup, Label, Input,Table,Button,CustomInput,ButtonGroup, InputGroup, InputGroupAddon } from 'reactstrap';
import {FormWidget,Form, ActivityIndicator,FileInput} from '../components'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const editorToolbar = {
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
    colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: ['rgb(255,235,43)','rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
          'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
          'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
          'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
          'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
          'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)','rgb(0,0,0)','rgb(255,255,255)'],
    }
}

class Rooms extends Component{

    state = {
        room : {
            roomDateBlocks : [{}]
        },
        images : [],
        editorState : EditorState.createEmpty(),
        editorEnglishState : EditorState.createEmpty(),
        form : false,
        base64File : null
    }

    componentDidMount(){
        const {fetchRooms} = this.props
        fetchRooms(1);
    }

    toogleForm = (room) => { 

        if(room && room.room_description && room.room_description != ""){
            const contentBlock = htmlToDraft(room.room_description);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            this.setState({
                editorState : EditorState.createWithContent(contentState)
            })
        }

        this.setState({
            form : !this.state.form, 
            room,
            images : room && room.room_images ? room.room_images : [],
            base64File:(room && room.room_image != "" ? room.room_image : "")
        })
    }

    toggle(collection, item) {
        var idx = collection.indexOf(item);
        if(idx !== -1) {
          collection.splice(idx, 1);
        } else {
          collection.push(item);
        }
        return collection;
    }

    handleInputChange = ({field}) => evt => {        
        this.setState({ room: {
            ...this.state.room,
            [field] : evt.target.value
        }});
    }

    removeRoom(room_id){
        const {deleteRoom,account:{data}} = this.props
        let conf = window.confirm("Realmente deseas eliminar este cuarto?")
        if(conf){
            deleteRoom(room_id,data.user_name)
        }
    }

    onChangeFile = (file)  => {
        let base64File = '';
        this.getBase64(file, (result) => {
            base64File = result;
            this.setState({
                room : {
                    ...this.state.room,
                    room_image : file
                },
                base64File
            })
        });
    }

    onAddMoreImages(){
        const {images} = this.state

        this.setState({
            images : [
                ...images,
                {
                    id : `image-${new Date().getTime()}`,
                    uri: "",
                    file : null
                }
            ]
        })
    }

    onChangeGalery = (file,id) => {
        const {images} = this.state;
        let base64File = '';
        this.getBase64(file, (result) => {
            base64File = result;

            let newArr = images.map((item,index) => {
                if(item.id == id){
                    item.id = id
                    item.uri = base64File
                    item.file = file
                }
                return item
            })

            this.setState({
                images : newArr
            })
            //console.log(images,file,base64File,'here')
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onSubmit = (e) => {
        const {createOrUpdateRoom,account:{data}} = this.props
        const {room, editorState, editorEnglishState, images} = this.state
        e.preventDefault();

        let description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        let eng_description = draftToHtml(convertToRaw(editorEnglishState.getCurrentContent()))

        if(room && room.room_name && room.room_name != "" &&
        description && description != "" &&
        room.room_image && room.room_image != "" &&
        room.room_subtitle && room.room_subtitle != "" &&
        room.room_difficult && room.room_difficult != "" &&
        room.room_stars && room.room_stars != "" &&
        room.room_bottom_title && room.room_bottom_title != "" &&
        room.room_players && room.room_players != "" &&
        room.room_status && room.room_status != ""){
            room.room_description = description;
            room.room_english_description = eng_description;

            if(images && images.length > 0){
                images.map((item,index) => {
                    //files.push(item.file)
                    room[`room_image_${index}`] = item.file
                })

                //room.room_images = files
            }

            createOrUpdateRoom(room,data.user_name);
        }else{
            toast.warn('Verifique que todos los campos estén llenados', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
    }

    onEditorEnglishStateChange = (editorEnglishState) => {
        this.setState({
            editorEnglishState,
        });
    }

    render(){
        const {form,room,base64File,editorState,editorEnglishState,images} = this.state
        const {rooms:{collection,loading,error}} = this.props
       
        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget
                        title="Cuartos"
                        headerColor="#f5b13f"
                        icon="plus"
                        titleButton=" Añadir nuevo"
                        onClick={(e) => {
                            e.preventDefault();
                            this.toogleForm();
                        }}
                        maxHeight={430}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                    <Table borderless>
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {collection && collection.length > 0 && collection.map((room,index) => (
                                                <tr index={room.room_id}>
                                                    <td>{room && room.room_name != "" ? room.room_name : ""}</td>
                                                    <td>
                                                        <ButtonGroup>
                                                            <Button onClick={(e) => {
                                                                e.preventDefault();
                                                                this.toogleForm(room);
                                                            }}>
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Button>
                                                            <Button color="danger" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.removeRoom(room.room_id)
                                                            }}>
                                                                <i className="fa fa-trash"></i>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        }
                    />

                    <Form 
                        show={form} 
                        onLeave={() => this.toogleForm()} >
                        <div className="form-content">
                            <div className="form-header">
                                <Row>
                                    <Col><span>Datos del Cuarto</span></Col>
                                    <Col className="text-align-right">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.toogleForm();
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <Container className="form-children-content">
                                <FormGroup>
                                    <Label>Imagen</Label>
                                    <FileInput 
                                        accept={".jpg,.jpeg,.png"}
                                        input={{
                                            onChange : (file) => { this.onChangeFile(file) },
                                            value : base64File,
                                            onClear : () => {this.setState({
                                                base64File : ""
                                            })}
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Título (Español)</Label>
                                    <Input defaultValue={room && room.room_name && room.room_name != "" ? room.room_name : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"room_name"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Título (Inglés)</Label>
                                    <Input defaultValue={room && room.room_english_name && room.room_english_name != "" ? room.room_english_name : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"room_english_name"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Sub-título (Español)</Label>
                                    <Input defaultValue={room && room.room_subtitle && room.room_subtitle != "" ? room.room_subtitle : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"room_subtitle"})} />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label>Sub-título (Inglés)</Label>
                                    <Input defaultValue={room && room.room_english_subtitle && room.room_english_subtitle != "" ? room.room_english_subtitle : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"room_english_subtitle"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Texto inferior (Español)</Label>
                                    <Input defaultValue={room && room.room_bottom_title && room.room_bottom_title != "" ? room.room_bottom_title : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"room_bottom_title"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Texto inferior (Inglés)</Label>
                                    <Input defaultValue={room && room.room_english_bottom_title && room.room_english_bottom_title != "" ? room.room_english_bottom_title : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"room_english_bottom_title"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Descripción (Español)</Label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        editorState={editorState}
                                        toolbar={editorToolbar}
                                        localization={{
                                            locale: 'es',
                                        }}
                                        hashtag={{
                                            separator: ' ',
                                            trigger: '#',
                                        }}
                                        onEditorStateChange={this.onEditorStateChange}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Descripción (Inglés)</Label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        editorState={editorEnglishState}
                                        toolbar={editorToolbar}
                                        localization={{
                                            locale: 'en',
                                        }}
                                        hashtag={{
                                            separator: ' ',
                                            trigger: '#',
                                        }}
                                        onEditorStateChange={this.onEditorEnglishStateChange}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Status</Label>
                                    <Input defaultValue={room && room.room_status && room.room_status != "" ? room.room_status : ""} type="select"  placeholder="" onChange={this.handleInputChange({field:"room_status"})}>
                                        <option value="">- Elija una opción -</option>
                                        <option value="available">Habilitado</option>
                                        <option value="blocked">Bloqueado</option>
                                    </Input>
                                </FormGroup>

                                {/*<FormGroup>
                                    <Label>Bloqueo por rango de fechas</Label>
                                    
                                    <div>
                                        {room && room.roomDateBlocks && room.roomDateBlocks.length > 0 && room.roomDateBlocks.map((item,index) => (
                                            <Row key={item.id}>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Desde</Label>
                                                        <Input type="date" defaultValue={item.blocked_from} placeholder="desde" onChange={this.handleDynamicInputChange("blocked_from",index)} />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Hasta</Label>
                                                        <Input type="date" defaultValue={item.blocked_to} placeholder="hasta" onChange={this.handleDynamicInputChange("blocked_to",index)} />
                                                    </FormGroup>
                                                </Col>
                                                <Button onClick={() => this.removeDynamicBlock(item.id)}><i className="fa fa-times"></i></Button>
                                                <br />
                                            </Row>
                                        ))}
                                    </div>

                                    <Button onClick={() => this.addDateBlock()}>Añadir otro bloqueo</Button>
                                </FormGroup>*/}

                                <FormGroup>
                                    <Label>Estrellas</Label>
                                    <Input defaultValue={room && room.room_stars && room.room_stars != "" ? room.room_stars : ""} type="select"  placeholder="" onChange={this.handleInputChange({field:"room_stars"})}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Jugadores</Label>
                                    <Input defaultValue={room && room.room_players && room.room_players != "" ? room.room_players : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"room_players"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Edades</Label>
                                    <Input defaultValue={room && room.room_difficult && room.room_difficult != "" ? room.room_difficult : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"room_difficult"})} />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Fotos</Label>
                                    <Button style={{marginLeft:10}} onClick={() => this.onAddMoreImages()}><i className="fa fa-plus"></i></Button>
                                    <br />
                                    {images && images.length > 0 && images.map((item,index) => (
                                        <FileInput 
                                            accept={".jpg,.jpeg,.png"}
                                            key={item.id}
                                            input={{
                                                onChange : (file) => { this.onChangeGalery(file,item.id) },
                                                value : item.uri,
                                                onClear : () => {

                                                    let newArr = images.filter((_item) => _item.id != item.id );

                                                    this.setState({
                                                        images : newArr
                                                    })

                                                }
                                            }}
                                            style={{marginTop:10}}
                                        />
                                    ))}
                                    
                                </FormGroup>
                                
                                <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                        {!loading && ('Guardar Cuarto')}
                                        {loading && ('Espere por favor ...')}
                                </Button>
                            </Container>
                        </div>
                    </Form>
                </Container>
                <ToastContainer />
            </div>
        )
    }
}

export default Rooms