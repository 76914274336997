import React,{Component} from 'react'
import {Container,Table,Row,Col,FormGroup,Input,Button,ButtonGroup,Label,InputGroup,InputGroupAddon} from 'reactstrap'
import {FormWidget,Form, ActivityIndicator, PaginationView} from '../components'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const GTYPES = [
    {
        label : 'Temporal',
        type : 'temp'
    },
    {
        label : 'Impresa',
        type : 'print'
    },
    {
        label : '1 Persona',
        type : '1-person'
    },
    {
        label : '2 Personas',
        type : '2-person'
    },
    {
        label : 'Mitad de precio',
        type : 'half-price'
    },
    {
        label : 'Todo Gratis',
        type : 'free'
    }
]

const DownloadExcel = (props) => {
    return(
        <ExcelFile element={<Button>Exportar a Excel</Button>} filename={"giftcards"}>
            <ExcelSheet data={props.data} name="Giftcards">
                <ExcelColumn label="Código" value="giftcard_code"/>
                <ExcelColumn label="Tipo" value="giftcard_type"/>
                <ExcelColumn label="Disponible" value="giftcard_available"/>
                <ExcelColumn label="Usos disponibles" value="giftcard_uses" />
                <ExcelColumn label="Usos restantes" value="giftcard_remaining_uses"/>
                <ExcelColumn label="Fecha de disponibilidad" value="giftcard_available_date"/>
                <ExcelColumn label="Fecha de creación" value="created_at"/>
            </ExcelSheet>
        </ExcelFile>
    )
}

class Giftcards extends Component{

    state = {
        giftcard : {
            giftcard_code : "",
            giftcard_available : false,
            giftcard_uses : 0,
            giftcard_available_date : "0000-00-00"
        },
        form : false,
        currentPage:1
    }

    componentDidMount(){
        const {fetchGiftcards} = this.props
        fetchGiftcards(1);
    }

    toogleForm = (giftcard) => { 
        this.setState({
            form : !this.state.form, 
            giftcard
        }) 
    }

    handleInputChange = ({field}) => evt => {        
        this.setState({ giftcard: {
            ...this.state.giftcard,
            [field] : evt.target.value
        }});
    }

    onSubmit = (e) => {
        const {createOrUpdateGiftcard,account:{data}} = this.props
        const {giftcard} = this.state
        e.preventDefault();

        // && 
        if(giftcard && giftcard.giftcard_code && giftcard.giftcard_code != ""){

            /*if(giftcard.giftcard_available && giftcard.giftcard_available != ""){

            }*/

            createOrUpdateGiftcard(giftcard,data.user_name);
        }else{
            toast.warn('Verifique que todos los campos estén llenados', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    onRemove(giftcard_id){
        const {deleteGiftcard,account:{data}} = this.props
        let conf = window.confirm("¿Deseas eliminar esta giftcard?")
        if(conf){
            deleteGiftcard(giftcard_id,data.user_name);
        }
    }

    getGiftCardType(type){
        return GTYPES.find((item) => item.type == type).label
    }

    render(){
        const {giftcards:{collection,loading,error,pages,count},fetchGiftcards} = this.props
        const {giftcard,form,currentPage} = this.state
        return(
            <div className="dashboard-content">
            <Container>
                <FormWidget 
                    title="Tarjetas de regalo"
                    headerColor="#f5b13f"
                    icon="plus"
                    titleButton=" Añadir nueva"
                    onClick={(e) => {
                        e.preventDefault();
                        this.toogleForm();
                    }}
                    maxHeight={430}
                    render={<div>
                        {error && !loading && (<span>{error.toString()}</span>)}
                        {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                        {!loading && !error && collection && collection.length > 0 && (
                            <div>
                                <Row>
                                    <Col sm="6">
                                        <p style={{color:"white"}}>Viendo {collection.length} de {count}</p>
                                    </Col>
                                    <Col sm="6" style={{textAlign:"right"}}>
                                        <DownloadExcel data={collection} />
                                    </Col>
                                </Row>
                                <Table borderless>
                                    <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Tipo</th>
                                        <th>Disponible</th>
                                        <th>Usos disponibles</th>
                                        <th>Veces usadas</th>
                                        <th>Usos restantes</th>
                                        <th>Fecha de disponibilidad</th>
                                        <th>Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {collection && collection.length > 0 && collection.map((giftcard,index) => (
                                            <tr index={giftcard.giftcard_id}>
                                                <td>{giftcard && giftcard.giftcard_code != "" ? giftcard.giftcard_code : ""}</td>
                                                <td>{giftcard && giftcard.giftcard_type != "" ? this.getGiftCardType(giftcard.giftcard_type) : 'Temporal'}</td>
                                                <td>{giftcard && giftcard.giftcard_available == 0 ? "No" : "Si"}</td>
                                                <td>{giftcard && giftcard.giftcard_uses != "" && giftcard.giftcard_uses > 0 && giftcard.giftcard_available == 1 ? giftcard.giftcard_uses : "Ilimitado"}</td>
                                                <td>{giftcard ? giftcard.giftcard_times_uses : 0}</td>
                                                <td>{giftcard && giftcard.giftcard_remaining_uses && giftcard.giftcard_uses > 0 && giftcard.giftcard_available == 1 ? giftcard.giftcard_remaining_uses : "Ilimitado"}</td>
                                                <td>{giftcard && giftcard.giftcard_available_date && giftcard.giftcard_available_date != "0000-00-00" && giftcard.giftcard_available == 1 ? giftcard.giftcard_available_date : "Ilimitado"}</td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button onClick={(e) => {
                                                            e.preventDefault();
                                                            this.toogleForm(giftcard);
                                                        }}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Button>
                                                        <Button color="danger" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.onRemove(giftcard.giftcard_id)
                                                        }}>
                                                            <i className="fa fa-trash"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        )}
                    </div>}
                />

                <PaginationView 
                    pages={pages}
                    count={count}
                    active={currentPage}
                    onPageChange={page => {
                        this.setState({currentPage : page})
                        fetchGiftcards(page)
                    }}
                    style={{flexWrap: "wrap"}}
                />

                <Form 
                    show={form} 
                    onLeave={() => this.toogleForm()} >
                    <div className="form-content">
                        <div className="form-header">
                            <Row>
                                <Col><span>Tarjeta de regalo</span></Col>
                                <Col className="text-align-right">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.toogleForm();
                                    }}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                        <Container className="form-children-content">
                            <p>La giftcard generada, será solo de tipo temporal</p>
                            <FormGroup>
                                <Label>Código</Label>
                                <Input value={giftcard && giftcard.giftcard_code && giftcard.giftcard_code != "" ? giftcard.giftcard_code : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"giftcard_code"})} />
                            </FormGroup>

                            
                            <FormGroup>
                                <Label>Disponible</Label>
                                <Input value={giftcard && giftcard.giftcard_available && giftcard.giftcard_available != "" ? giftcard.giftcard_available : ""} type="select"  placeholder="" onChange={this.handleInputChange({field:"giftcard_available"})}>
                                    <option value="">Elija una opción</option>
                                    <option value="1">Si</option>
                                    <option value="0">No</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label>Tipo</Label>
                                <Input value={giftcard && giftcard.giftcard_type && giftcard.giftcard_type != "" ? giftcard.giftcard_type : ""} type="select"  placeholder="" onChange={this.handleInputChange({field:"giftcard_type"})}>
                                    <option value="">Elija una opción</option>
                                    <option value="1-person">1 Persona Gratis</option>
                                    <option value="2-person">2 Personas Gratis</option>
                                    <option value="half-price">Mitad de Precio</option>
                                    <option value="free">Todo Gratis</option>
                                </Input>
                            </FormGroup>

                            <p>Los usos disponibles y la fecha son opcionales</p>

                            <FormGroup>
                                <Label>Usos disponibles</Label>
                                <Input value={giftcard && giftcard.giftcard_uses && giftcard.giftcard_uses != "" ? giftcard.giftcard_uses : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"giftcard_uses"})} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Disponible hasta</Label>
                                <Input value={giftcard && giftcard.giftcard_available_date && giftcard.giftcard_available_date != "" ? giftcard.giftcard_available_date : ""} type="date"  placeholder="" onChange={this.handleInputChange({field:"giftcard_available_date"})} />
                            </FormGroup>
                            
                            <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                    {!loading && ('Guardar Gifcard')}
                                    {loading && ('Espere por favor ...')}
                            </Button>
                        </Container>
                    </div>
                </Form>
            </Container>

            <ToastContainer />
            </div>
        )
    }
}

export default Giftcards