import {account} from '../actions'
export default function(state = {}, action) {

    switch(action.type){
        case account.ACCOUNT_LOADING:
            return {
                ...state,
                loading : true,
                error : null
            }

        case account.ACCOUNT_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case account.ACCOUNT_SUCCESS : {
            return {
                ...state,
                loading : false,
                data : action.account,
                modules : action.modules
            }
        }

        case account.ACCOUNT_LOGOUT:{
            return {
                loading : false,
                data : {},
                modules : []
            }
        }

        default:
            return state;
    }
}