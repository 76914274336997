import {backup} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case backup.FETCH_BACKUP_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case backup.FETCH_BACKUP_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case backup.FETCH_BACKUP_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.collection,
                count : action.rows,
                pages : action.pages
            }
        }

        default:
            return state;
    }
}