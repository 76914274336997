import {bookings} from '../actions'
import booking from '../api/booking';
export default function(state = {}, action) {

    switch(action.type){
        case bookings.FETCH_BOOKING_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case bookings.FETCH_BOOKING_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case bookings.FETCH_BOOKING_SUCCESS : 
            return {
                ...state,
                loading : false,
                collection : action.bookings
            }
        

        case bookings.FETCH_WEEKLY_BOOKINGS_SUCCESS: 
            return {
                ...state,
                loading : false,
                weeklyCollection : action.weekly
            }

        case bookings.FETCH_MONTHLY_BOOKINGS_SUCCESS:
            return {
                ...state,
                loading : false,
                monthlyCollection : action.monthly
            }

        case "ACCOUNT_LOGOUT":{
            return {
                loading : false,
                monthlyCollection : [],
                weeklyCollection : [],
                collection : [],
                error : null
            }
        }

        default:
            return state;
    }
}