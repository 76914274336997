import React,{Component} from 'react'
import { Row, Col, Container, FormGroup, Label, Input,Table,Button,CustomInput,InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import {FormWidget,Form, ActivityIndicator} from '../components'
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import generator from 'generate-password';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Users extends Component{

    constructor(){
        super()

        this.state = {
            user : {},
            form : false,
            passwordField : "password",
            base64File : ""
        }
    }

    componentDidMount(){
        const {fetchUsers,fetchProfiles} = this.props
        fetchUsers()
        fetchProfiles()
    }

    toogleForm = (user) => { this.setState({form : !this.state.form, user,base64File:(user && user.avatar != "" ? user.avatar : "")}) }

    handleInputChange = ({field}) => evt => {        
        this.setState({ user: {
            ...this.state.user,
            [field] : evt.target.value
        }});
    }

    onSubmit = (e) => {
        const {insertOrUpdateUser,account:{data}} = this.props
        const {user} = this.state
        e.preventDefault();

        if(user && user.user_name && user.user_name != "" &&
        user.user_email && user.user_email != "" &&
        user.user_profile_id && user.user_profile_id != "" &&
        user.user_active && user.user_active != ""){
            insertOrUpdateUser(user,data.user_name);
        }else{
            toast.warn('Verifique que todos los campos estén llenados', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    onRemove = (user_id) => {
        let conf = window.confirm("¿Deseas eliminar este usuario?")
        const {deleteUser,account:{data}} = this.props
        if(conf){
            deleteUser(user_id,data.user_name);
        }
    }

    render(){
        const {form,user,passwordField} = this.state
        const {users:{collection,loading,error},profiles} = this.props

        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget
                        title="Usuarios"
                        headerColor="#f5b13f"
                        icon="user"
                        titleButton=" Añadir nuevo"
                        onClick={(e) => {
                            e.preventDefault();
                            this.toogleForm();
                        }}
                        maxHeight={430}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                    <Table borderless>
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Enviar Email</th>
                                            <th>Acceso al sistema</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {collection && collection.map((user,index) => (
                                                <tr>
                                                    <td>{user && user.user_name != "" ? user.user_name : ""}</td>
                                                    <td><Button color="warning" onClick={e => {
                                                        e.preventDefault();
                                                        window.location.href = `mailto:${user.user_email}`
                                                    }}><i className="fa fa-envelope"></i></Button></td>
                                                    <td>{user && user.user_active == 1 ? 'Si' : 'No'}</td>
                                                    <td>
                                                        <ButtonGroup>
                                                            <Button onClick={(e) => {
                                                                e.preventDefault();
                                                                this.toogleForm(user);
                                                            }}>
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Button>
                                                            <Button color="danger" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.onRemove(user.user_id)
                                                            }}>
                                                                <i className="fa fa-trash"></i>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        }
                    />

                    <Form 
                        show={form} 
                        onLeave={() => this.toogleForm()} >
                        <div className="form-content">
                            <div className="form-header">
                                <Row>
                                    <Col><span>Usuario del sistema</span></Col>
                                    <Col className="text-align-right">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.toogleForm();
                                        }}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <Container className="form-children-content">
                                <FormGroup>
                                    <Label>Nombre</Label>
                                    <Input defaultValue={user && user.user_name && user.user_name != "" ? user.user_name : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"user_name"})} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Email de Contacto</Label>
                                    <Input defaultValue={user && user.user_email && user.user_email != "" ? user.user_email : ""} type="email" onChange={this.handleInputChange({field:"user_email"})} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Contraseña</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <Button color="secondary" onClick={(e) => {
                                                e.preventDefault();
                                                const newPass = generator.generate({
                                                    length: 10,
                                                    numbers: true,
                                                    symbols : true,
                                                    excludeSimilarCharacters : true,
                                                    strict : true
                                                });

                                                this.setState({
                                                    user : {
                                                        ...user,
                                                        user_password : newPass
                                                    }
                                                })
                                            }}>Autogenerar</Button>
                                        </InputGroupAddon>
                                        <Input value={user && user.user_password != "" ? user.user_password : ""} type={passwordField} onChange={this.handleInputChange({field:"user_password"})} />
                                        <InputGroupAddon addonType="append"><Button color="secondary" onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({
                                                passwordField : this.state.passwordField == "text" ? "password" : "text"
                                            })
                                        }}><i className="fa fa-eye"></i></Button></InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Perfil</Label>
                                    <Input type="select" defaultValue={user && user.user_profile_id ? user.user_profile_id : ''} onChange={this.handleInputChange({field:"user_profile_id"})}>
                                        {profiles && profiles.collection && profiles.collection.length > 0 && profiles.collection.map((profile,index) => (
                                            <option value={profile.profile_id}>{profile.profile_name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="customInput">Acceso al sistema</Label>
                                    <div>
                                        <CustomInput value={user && user.user_active} id="customInput" name="customInput" checked={user && user.user_active == 1 ? true : false} type="switch" onChange={e => {
                                            this.setState({ 
                                                user : {
                                                    ...user,
                                                    user_active : !this.state.user.user_active
                                                }
                                            })
                                        }} />
                                    </div>
                                </FormGroup>
                                <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                        {!loading && ('Guardar Usuario')}
                                        {loading && ('Espere por favor ...')}
                                </Button>
                            </Container>
                        </div>
                    </Form>
                </Container>
                <ToastContainer />
            </div>
        )
    }
}

export default Users