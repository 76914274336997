import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,sales} from '../actions';
import Sales from '../pages/Sales';

export default connect(
    (state) => ({
        sales: state.sales,
        account : state.settings
    }),
    (dispatch) => bindActionCreators({
        ...sales,
        ...account
    }, dispatch)
)(Sales);
