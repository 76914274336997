import {ProyectApi} from '../api'

export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST"
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS"
export const FETCH_INVOICES_ERROR = "FETCH_INVOICES_ERROR"

export function uploadProyectInvoice(proyect){
    return function(dispatch){
        dispatch(fetchInvoicesRequest())
        return ProyectApi.post(proyect,'invoice')
        .then(response => {
            if(response.response){
                dispatch(getFactured())
            }else{
                dispatch(fetchInvoicesError("NO_RESULTS"))
            }
        })
        .catch(error => {
            dispatch(fetchInvoicesError(error))
        })
    }
}

export function getFactured(){
    return function(dispatch){
        dispatch(fetchInvoicesRequest())
        return ProyectApi.get('getFactured')
        .then(response => {
            if(response.response){
                dispatch(fetchInvoicesSuccess(response.result))
            }else{
                dispatch(fetchInvoicesError("NO_RESULTS"))
            }
        })
        .catch(error => {
            dispatch(fetchInvoicesError(error))
        })
    }
}

function fetchInvoicesError(error){
    return {
        type : FETCH_INVOICES_ERROR,
        error
    }
}

function fetchInvoicesSuccess(invoices){
    return {
        type : FETCH_INVOICES_SUCCESS,
        invoices
    }
}

function fetchInvoicesRequest(){
    return {
        type  : FETCH_INVOICES_REQUEST
    }
}