import {SettingsApi} from '../api';

export const FETCH_SETTINGS_REQUEST = "FETCH_SETTINGS_REQUEST"
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS"
export const FETCH_SETTINGS_ERROR = "FETCH_SETTINGS_ERROR"
export const TOGGLE_SUCCESS_SETTINGS = "TOGGLE_SUCCESS_SETTINGS"

function toggleSuccess(value){
    return {
        type : TOGGLE_SUCCESS_SETTINGS,
        success : value
    }
}

export function fetchSettings(){
    return function(dispatch){
        dispatch(fetchSettingsRequest())
        return SettingsApi.get('getAll')
        .then(response =>{
            if(response.result){
                dispatch(fetchSettingsSuccess(response.result[0]))
            }
        })
        .catch(error => {
            dispatch(fetchSettingsError(error))
        })
    }
}

function fetchSettingsRequest(){
    return {
        type : FETCH_SETTINGS_REQUEST
    }
}

function fetchSettingsError(error){
    return {
        type : FETCH_SETTINGS_ERROR,
        error
    }
}

function fetchSettingsSuccess(data){
    return{
        type : FETCH_SETTINGS_SUCCESS,
        pdf_mail : data.pdf_mail,
        contact_phone : data.contact_phone,
        logo : data.logo,
        address: data.address
    }
}

export function updateSettings(settings){
    return function(dispatch){
        dispatch(fetchSettingsRequest())
        return SettingsApi.post(settings,'save')
        .then(response =>{
            if(response.response){
                dispatch(toggleSuccess(true))
                dispatch(fetchSettingsSuccess(response.result[0]))
            }else{
                dispatch(toggleSuccess(false))
            }
        })
        .catch(error => {
            dispatch(fetchSettingsError(error))
        })
    }
}