import React,{Component} from 'react'
import { Row, Col, Container, FormGroup, Label, Input,Table,Button,ButtonGroup } from 'reactstrap';
import {FormWidget,Form,ActivityIndicator} from '../components';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})

class Products extends Component{
    constructor(){
        super();
        this.state = {
            form : false,
            product : {}
        }
    }

    componentDidMount(){
        const {fetchProducts} = this.props
        fetchProducts();
    }

    toogleForm = (product) => { this.setState({form : !this.state.form, product}) }

    handleInputChange = ({field}) => evt => {        
        this.setState({ product: {
            ...this.state.product,
            [field] : evt.target.value
        }});
    }

    onSubmit = (e) => {
        const {createOrUpdateProduct} = this.props
        const {product} = this.state
        e.preventDefault();
        createOrUpdateProduct(product);
    }

    render(){
        const {form,product} = this.state;
        const {products:{collection,error,loading}} = this.props

        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget 
                        title="Productos"
                        headerColor="#215dff"
                        icon="plus"
                        onClick={(e) => {
                            e.preventDefault();
                            this.toogleForm();
                        }}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                    <Table borderless>
                                        <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Descripción</th>
                                            <th>Precio</th>
                                            <th>Acción</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {collection && collection.length > 0 && collection.map((product,index) => (
                                            <tr key={product.service_id}>
                                                <td>{product.name}</td>
                                                <td>{product.description}</td>
                                                <td>{formatter.format(product.price)}</td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Button onClick={(e) => {
                                                            e.preventDefault();
                                                            this.toogleForm(product);
                                                        }}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Button>
                                                        <Button color="danger" onClick={(e) => {
                                                            e.preventDefault();
                                                        }}>
                                                            <i className="fa fa-trash"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        }
                        maxHeight={430}
                    />
                </Container>

                <Form 
                    show={form} 
                    onLeave={() => this.toogleForm()} >
                    <div className="form-content">
                        <div className="form-header">
                            <Row>
                                <Col><span>Crear Producto</span></Col>
                                <Col className="text-align-right">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.toogleForm();
                                    }}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                        <Container className="form-children-content">
                            <FormGroup>
                                <Label>Nombre</Label>
                                <Input defaultValue={product && product.name ? product.name : ""} type="text" name="product_name" placeholder="Escriba el nombre del producto" onChange={this.handleInputChange({field:"name"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Precio</Label>
                                <Input defaultValue={product && product.price ? product.price : ""} type="number" min="1" max="99999" name="product_price" placeholder=" Escriba el precio del producto" onChange={this.handleInputChange({field:"price"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Descripción</Label>
                                <Input defaultValue={product && product.description ? product.description : ""} type="textarea" name="product_desc" placeholder="" onChange={this.handleInputChange({field:"description"})} />
                            </FormGroup>
                            <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                        {!loading && ('Guardar Producto')}
                                        {loading && ('Espere por favor ...')}
                            </Button>
                        </Container>
                    </div>
                </Form>
            </div>
        )
    }
}

export default Products