import React,{Component} from 'react'
import { Pagination, PaginationItem, PaginationLink,Container } from 'reactstrap';

class PaginationView extends Component{
    render(){
        const {pages,count,onPageChange,active,style} = this.props

        let _pages = []
        for(let i = 0; i < pages; i++){
            _pages.push(
                <PaginationItem active={i == (active-1) ? true : false}>
                    <PaginationLink onClick={e => {
                        e.preventDefault()
                        onPageChange((i+1))
                    }}>
                        {(i+1)}
                    </PaginationLink>
                </PaginationItem>
            )
        }

        return(
            <Container style={{marginTop:15}}>
                <Pagination style={style} aria-label="Page navigation example">
                    {_pages}
                </Pagination>
            </Container>
        )
    }
}

export default PaginationView;