import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,clients} from '../actions';
import Clients from '../pages/Clients';

export default connect(
    (state) => ({
        account: state.account,
        clients : state.clients
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...clients
    }, dispatch)
)(Clients);
