import React,{Component} from 'react';
import {ActivityIndicator,Modal} from '../components';
import { Row, Col, Card, CardBody,ListGroup,ListGroupItem,Form,FormGroup,Label,Input,Table, ButtonGroup,Button,CustomInput } from 'reactstrap';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './formWidget.css';
import moment from 'moment'
moment.locale('es')

const statusArray = {
    'pending' : 'Pendiente',
    'completed' : 'Completado',
    'in_process' : 'En proceso',
    'deleted' : 'Cancelada' 
}

class FormWidget extends Component{
    constructor(){
        super();
        this.state = {
            showModal : false,
            currentTodo : {},
            checkedAllTodos : false,
            checkedTodos : [],
            showScheduleModal : false,
            currentSchedule : {}
        }
    }

    toggle(collection, item) {
        var idx = collection.indexOf(item);
        if(idx !== -1) {
          collection.splice(idx, 1);
        } else {
          collection.push(item);
        }
        return collection;
    }

    render(){
        const {showModal,currentTodo,checkedTodos,checkedAllTodos,showScheduleModal,currentSchedule} = this.state
        const {title,headerColor,icon, onClick, maxHeight, render,titleButton} = this.props;
        const {loading,error,collection,type,create,onDelete,clients} = this.props;
        return(
            <Col className="form-widget">
                <div className="form-widget-header"
                    style={{backgroundColor:headerColor}}
                >
                    <Row>
                        <Col>
                            <span className="form-widget-header-title">{title}</span>
                        </Col>
                        <Col className="text-align-right">
                            {checkedTodos && checkedTodos.length > 0 && (
                                <a href="#" style={{marginRight:15}} className="form-widget-header-btn" onClick={(e) => {
                                    e.preventDefault();
                                    if(type == "todos"){
                                        
                                    }
                                }}>
                                    <i className="fa fa-trash"></i>
                                </a>
                            )}
                            <a href="#" className="form-widget-header-btn" onClick={(e) => {
                                e.preventDefault();
                                if(type == "todos"){
                                    this.setState({showModal : true, currentTodo : {}})
                                }else if(type == "schedule"){
                                    this.setState({showScheduleModal : true, currentSchedule : {}})
                                }else{
                                    onClick(e)
                                }
                            }}>
                                <i className={`fa fa-${icon}`}></i>
                                {titleButton}
                            </a>
                        </Col>
                    </Row>
                    
                </div>

                <Card className="form-widget-container">
                    <CardBody style={{maxHeight: maxHeight && maxHeight > 0 ? maxHeight : 450,overflow:"scroll"}}>
                        {error && !loading && (<p>{error.toString()}</p>)}
                        {loading && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                        {!render && !loading && collection && collection.length > 0 && (
                        <Table striped style={{margin:0}}>
                            <thead>
                                {type == "todos" && (
                                    <tr>
                                        <th><Input style={{position:"relative",margin:0}} checked={checkedAllTodos} type="checkbox" onChange={e => {
                                            let data = []
                                            if(checkedTodos.length == collection.length){
                                                this.setState({
                                                    checkedTodos : data,
                                                    checkedAllTodos : false
                                                })
                                            }else{
                                                collection.map((item) => {
                                                    data.push(item.todo_id)
                                                });
                                                this.setState({
                                                    checkedTodos : data,
                                                    checkedAllTodos:true
                                                })
                                            }
                                        }} /></th>
                                        <th>Título</th>
                                        <th>Status</th>
                                        <th>Creado</th>
                                        <th>Acciones</th>
                                    </tr>
                                )}
                                {type == "schedule" && (
                                    <tr>
                                        <th>Título</th>
                                        <th>Cliente</th>
                                        <th>Fecha / Hora</th>
                                        <th>Acciones</th>
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                            {collection && collection.map((item,index) => {
                                if(type == `todos`){
                                   return(
                                        <tr key={index}>
                                            <td><Input style={{position:"relative",margin:0}} checked={checkedTodos.indexOf(item.todo_id) > -1 ? true : false} type="checkbox" onChange={e => {
                                                let checked = this.toggle(checkedTodos,item.todo_id)
                                                if(checkedTodos.length < collection.length){
                                                    this.setState({
                                                        checkedAllTodos : false,
                                                        checkedTodos : checked
                                                    })
                                                }else{
                                                    this.setState({
                                                        checkedAllTodos : true,
                                                        checkedTodos : checked
                                                    })
                                                }
                                            }} /></td>
                                            <td>{item.title.substring(0,10)} ...</td>
                                            <td><span
                                                style={{
                                                    color : item.status == "pending" ? "orange" :
                                                    item.status == "completed" ? "green" :
                                                    item.status == "in_process" ? "blue" :
                                                    item.status == "deleted" ? "red" : "black"
                                                }}
                                            >{statusArray[item.status]}</span></td>
                                            <td>{moment(item.created_at).format('L')}</td>
                                            <td>
                                                <ButtonGroup>
                                                    <Button onClick={(e) => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            checkedAllTodos:false,
                                                            checkedTodos : [],
                                                            currentTodo : item,
                                                            showModal : true
                                                        })
                                                    }}>
                                                        <i className="fa fa-pencil-alt"></i>
                                                    </Button>
                                                    <Button color="danger" onClick={(e) => {
                                                        e.preventDefault();
                                                        let conf = window.confirm("Estás seguro que deseas eliminar esta tarea?")
                                                        if(conf){
                                                            onDelete(item.todo_id)
                                                        }
                                                    }}>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                   )
                                }

                                if(type == `schedule`){
                                    let client = clients.find((el) => el.client_id == item.client_id)
                                    return(
                                         <tr key={index}>
                                             <td>{item.title.substring(0,10)} ...</td>
                                             <td><span>{client && client.client_name ? client.client_name : '...'}</span></td>
                                             <td>{moment(item.schedule_at).format('L')} / {item.schedule_at_time}</td>
                                             <td>
                                                 <ButtonGroup>
                                                     <Button onClick={(e) => {
                                                         e.preventDefault()
                                                         this.setState({
                                                            showScheduleModal: true,
                                                            currentSchedule : item
                                                         })
                                                     }}>
                                                         <i className="fa fa-pencil-alt"></i>
                                                     </Button>
                                                     <Button color="danger" onClick={(e) => {
                                                         e.preventDefault();
                                                         let conf = window.confirm("Estás seguro que deseas eliminar esta cita?")
                                                         if(conf){
                                                             onDelete(item.agenda_id)
                                                         }
                                                     }}>
                                                         <i className="fa fa-trash"></i>
                                                     </Button>
                                                 </ButtonGroup>
                                             </td>
                                         </tr>
                                    )
                                 }
                            })}
                            </tbody>
                        </Table>)}
                        {render && render && (render)}
                    </CardBody>
                </Card>

                <Modal
                    show={showModal}
                    title={'Tarea'}
                    loading={loading}
                    error={error}
                    onContinue={() => {
                        if(currentTodo && currentTodo.title && currentTodo.description && currentTodo.status){
                            currentTodo.user_id = 1
                            currentTodo.created_at = moment().format('YYYY-M-D')
                            create(currentTodo);
                            this.setState({showModal : false})
                        }else{
                            toast.warn('Debe llenar todos los campos!', {
                                position: "top-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        }
                    }}
                    onCancel={() => {
                        this.setState({showModal : false})
                    }}
                >
                    <Form>
                        <FormGroup>
                            <Label>Título</Label>
                            <Input required defaultValue={currentTodo.title} onChange={e => {
                                this.setState({ 
                                    currentTodo : {
                                        ...currentTodo,
                                        title : e.target.value 
                                    }
                                })
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Descripción</Label>
                            <Input required type="textarea" defaultValue={currentTodo.description}  onChange={e => {
                                this.setState({ 
                                    currentTodo : {
                                        ...currentTodo,
                                        description : e.target.value 
                                    }
                                })
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Status</Label>
                            <Input required type="select" defaultValue={currentTodo.status}  onChange={e => {
                                this.setState({ 
                                    currentTodo : {
                                        ...currentTodo,
                                        status : e.target.value 
                                    }
                                })
                            }}>
                                <option value="">-Elija una opcion-</option>
                                <option value="pending">Pendiente</option>
                                <option value="in_process">En Proceso</option>
                                <option value="completed">Completada</option>
                                <option value="deleted">Cancelada</option>
                            </Input>
                        </FormGroup>
                    </Form>
                </Modal>

                <Modal
                    show={showScheduleModal}
                    title={'Cita'}
                    onContinue={() => {
                        if(currentSchedule && currentSchedule.title 
                            && currentSchedule.description 
                            && currentSchedule.client_id
                            && currentSchedule.schedule_at
                            && currentSchedule.schedule_at_time){
                            currentSchedule.user_id = 1
                            currentSchedule.created_at = moment().format('YYYY-M-D')
                            create(currentSchedule);
                            this.setState({showScheduleModal : false})
                        }else{
                            toast.warn('Debe llenar todos los campos!', {
                                position: "top-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        }
                    }}
                    onCancel={() => {
                        this.setState({showScheduleModal : false})
                    }}
                >
                    <Form>
                        <FormGroup>
                            <Label>Título</Label>
                            <Input required defaultValue={currentSchedule.title} onChange={e => {
                                this.setState({ 
                                    currentSchedule : {
                                        ...currentSchedule,
                                        title : e.target.value 
                                    }
                                })
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Descripción</Label>
                            <Input required type="textarea" defaultValue={currentSchedule.description}  onChange={e => {
                                this.setState({ 
                                    currentSchedule : {
                                        ...currentSchedule,
                                        description : e.target.value 
                                    }
                                })
                            }} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Cliente</Label>
                            <Input required type="select" defaultValue={currentSchedule.client_id}  onChange={e => {
                                this.setState({ 
                                    currentSchedule : {
                                        ...currentSchedule,
                                        client_id : e.target.value 
                                    }
                                })
                            }}>
                                <option value="">-Elija una opcion-</option>
                                {clients && clients.length > 0 && clients.map((item,index) => (
                                    <option key={index} value={item.client_id}>{item.client_name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Duración de la cita</Label>
                            <div>
                            <CustomInput checked={this.state.currentSchedule.allDay} id="exampleCustomSwitch" name="customSwitch" label="La cita es todo el día" type="switch" onChange={e => {
                                this.setState({ 
                                    currentSchedule : {
                                        ...currentSchedule,
                                        allDay : !this.state.currentSchedule.allDay
                                    }
                                })
                            }}/>
                            </div>
                        </FormGroup>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                <Label for="exampleCity">Fecha inicial de la cita</Label>
                                <Input required type="date" name="city" id="exampleCity" defaultValue={currentSchedule.schedule_at}  onChange={e => {
                                    this.setState({ 
                                        currentSchedule : {
                                            ...currentSchedule,
                                            schedule_at : e.target.value 
                                        }
                                    })
                                }} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                <Label for="exampleState">Inicia a las</Label>
                                <Input required type="time" name="state" id="exampleState" defaultValue={currentSchedule.schedule_at_time}  onChange={e => {
                                    this.setState({ 
                                        currentSchedule : {
                                            ...currentSchedule,
                                            schedule_at_time : e.target.value 
                                        }
                                    })
                                }} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                <Label for="exampleCity">Fecha final de la cita</Label>
                                <Input required type="date" name="city" id="exampleCity" defaultValue={currentSchedule.end_at}  onChange={e => {
                                    this.setState({ 
                                        currentSchedule : {
                                            ...currentSchedule,
                                            end_at : e.target.value 
                                        }
                                    })
                                }} />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                <Label for="exampleState">Termina a las</Label>
                                <Input required type="time" name="state" id="exampleState" defaultValue={currentSchedule.end_at_time}  onChange={e => {
                                    this.setState({ 
                                        currentSchedule : {
                                            ...currentSchedule,
                                            end_at_time : e.target.value 
                                        }
                                    })
                                }} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <ToastContainer />

            </Col>
        )
    }
}

export default FormWidget;