import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,giftcards} from '../actions';
import Giftcards from '../pages/Giftcards';

export default connect(
    (state) => ({
        account: state.account,
        giftcards : state.giftcards
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...giftcards
    }, dispatch)
)(Giftcards);
