import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,profiles,modules} from '../actions';
import Profiles from '../pages/Profiles';

export default connect(
    (state) => ({
        account: state.account,
        profiles : state.profiles,
        modules : state.modules
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...profiles,
        ...modules
    }, dispatch)
)(Profiles);
