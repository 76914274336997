import {RoomsApi,BookingApi} from '../api'
import {rooms} from '../actions'

export const FETCH_BOOKING_REQUEST = "FETCH_BOOKING_REQUEST"
export const FETCH_BOOKING_SUCCESS = "FETCH_BOOKING_SUCCESS"
export const FETCH_BOOKING_ERROR = "FETCH_BOOKING_ERROR"
export const FETCH_WEEKLY_BOOKINGS_SUCCESS = "FETCH_WEEKLY_BOOKINGS_SUCCESS"
export const FETCH_MONTHLY_BOOKINGS_SUCCESS = "FETCH_MONTHLY_BOOKINGS_SUCCESS"
export const FETCH_ROOM_REQUEST = "FETCH_ROOM_REQUEST"
export const FETCH_ROOM_SUCCESS = "FETCH_ROOM_SUCCESS"
export const FETCH_ROOM_ERROR = "FETCH_ROOM_ERROR"

export function createOrUpdateRoomTurns(turns,request_by){
    return function(dispatch){
        dispatch(fetchBookingsRequest())
        return BookingApi.post({
            turns,
            request_by,
            room_id : turns[0].room_id
        },'turns')
        .then(response => {
            if(response.response){
                dispatch(fetchBookings())
                dispatch(fetchRoomsAfterUpdate(1))
            }else{
                dispatch(fetchBookingsError("CANT_CREATE_TURN"))
            }
        })
        .catch(error => dispatch(fetchBookingsError(error.toString())))
    }
}

function fetchRoomsRequest(){
    return {
        type : FETCH_ROOM_REQUEST
    }
}


export function fetchRoomsAfterUpdate(page){
    return function(dispatch){
        dispatch(fetchRoomsRequest())
        return RoomsApi.get(`getAll/${page}`)
        .then(response => {
            if(response.response){
                dispatch(fetchRoomsSuccess(
                    response.result,
                    response.pages,
                    response.count
                ))
            }else{
                dispatch(fetchRoomsError("CANT_GET_ROOMS"))
            }
        })
    }
}

function fetchRoomsSuccess(rooms,pages,count){
    return {
        type : FETCH_ROOM_SUCCESS,
        rooms,
        pages,
        count
    }
}

function fetchRoomsError(error){
    return {
        type : FETCH_ROOM_ERROR,
        error
    }
}

export function deleteBooking(booking,request_by){
    return function(dispatch){
        dispatch(fetchBookingsRequest())
        return BookingApi.post({
            booking_id : booking.booking_id,
            request_by
        },'delete')
        .then(response => {
            if(response.response){
                dispatch(fetchBookings(booking.booked_at))
            }else{
                dispatch(fetchBookingsError("CANT_REMOVE"))
            }
        })
        .catch(error => dispatch(fetchBookingsError(error.toString())))
    }
}

export function createOrUpdateBooking(booking,request_by){
    return function(dispatch){
        dispatch(fetchBookingsRequest())
        return BookingApi.post({
            ...booking,
            request_by
        },'save')
        .then(response => {
            if(response.response){
                dispatch(fetchBookings(booking.booked_at))
            }else{
                dispatch(fetchBookingsError("CANT_SAVE"))
            }
        })
        .catch(error => dispatch(fetchBookingsError(error.toString())))
    }
}

export function fetchMonthlyBookings(year){
    return function(dispatch){
        dispatch(fetchBookingsRequest())
        return BookingApi.get(`monthly/${year}`)
        .then(response => {
            if(response.response){
                dispatch(fetchMonthlyBookingsSuccess(response.result))
            }else{
                dispatch(fetchBookingsError("CANT_GET_WEEKLY"))
            }
        })
        .catch(error => dispatch(fetchBookingsError(error.toString())))
    }
}

function fetchMonthlyBookingsSuccess(monthly){
    return {
        type : FETCH_MONTHLY_BOOKINGS_SUCCESS,
        monthly
    }
}

export function fetchWeeklyBookings(from,to){
    return function(dispatch){
        dispatch(fetchBookingsRequest())
        return BookingApi.get(`weekly/${from}/${to}`)
        .then(response => {
            if(response.response){
                dispatch(fetchWeeklyBookingsSuccess(response.result))
            }else{
                dispatch(fetchBookingsError("CANT_GET_WEEKLY"))
            }
        })
        .catch(error => dispatch(fetchBookingsError(error.toString())))
    }
}

function fetchWeeklyBookingsSuccess(weekly){
    return {
        type : FETCH_WEEKLY_BOOKINGS_SUCCESS,
        weekly
    }
}

export function fetchBookings(date){
    return function(dispatch){
        dispatch(fetchBookingsRequest())
        return RoomsApi.get(`booking/${date}`)
        .then(response => {
            if(response.response){
                dispatch(fetchBookingsSuccess(response.result))
            }else{
                dispatch(fetchBookingsError("No se encontraron reservas"))
            }
        })
        .catch(error => dispatch(fetchBookingsError(error)))
    }
}

function fetchBookingsRequest(){
    return {
        type : FETCH_BOOKING_REQUEST
    }
}

function fetchBookingsSuccess(bookings){
    return {
        type : FETCH_BOOKING_SUCCESS,
        bookings
    }
}

function fetchBookingsError(error){
    return {
        type : FETCH_BOOKING_ERROR,
        error
    }
}