import React,{Component} from 'react'
import { Row, Col, Container, FormGroup, Label, Input,FormText,Button, Table,ButtonGroup, InputGroupAddon,InputGroup } from 'reactstrap';
import {FormWidget,Form,ActivityIndicator,PaginationView} from '../components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//https://github.com/rdcalle/react-export-excel#readme
//value={(col) => col.is_married ? "Married" : "Single"}
const DownloadExcel = (props) => {
    return(
        <ExcelFile element={<Button>Exportar a Excel</Button>} filename={"clientes"}>
            <ExcelSheet data={props.data} name="Clientes">
                <ExcelColumn label="Nombre" value="client_name"/>
                <ExcelColumn label="Celular" value="client_phone"/>
                <ExcelColumn label="Email" value="client_email"/>
                <ExcelColumn label="Reserva" value="client_booking" />
                <ExcelColumn label="Empresa" value="client_company"/>
                <ExcelColumn label="Cortesía" value="client_courtesy"/>
                <ExcelColumn label="Notas" value="client_notes"/>
            </ExcelSheet>
        </ExcelFile>
    )
}

class Clients extends Component{
    constructor(){
        super();
        this.state = {
            form : false,
            client : {},
            currentPage : 1,
            data : [],
            sort: {
                column: null,
                direction: "desc",
            },
            search : null
        }
    }

    componentDidMount(){
        const {fetchClients} = this.props
        fetchClients(1);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.clients.collection !== this.props.clients.collection){
            this.setState({ data : nextProps.clients.collection })
        }
    }

    toogleForm = (client) => { 
        this.setState({form : !this.state.form, client}) 
    }

    handleInputChange = ({field}) => evt => {        
        this.setState({ client: {
            ...this.state.client,
            [field] : evt.target.value
        }});
    }

    searchClient = () => {
        const {search} = this.state
        const {searchClients} = this.props
        if(search && search != ""){
            if(search.length > 3){
                searchClients(search,1)
            }else{
                toast.warn('Por favor escriba más de 3 letras', {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
        }else{
            toast.warn('Por favor introduzca un valor para realizar la búsqueda', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    onSubmit = (e) => {
        const {createOrUpdateClient} = this.props
        const {client} = this.state
        e.preventDefault();

        if(client && client.client_name != "" && 
        client.client_email != "" && client.client_phone != ""){
            createOrUpdateClient(client);
        }else{
            toast.warn('Asegurece de llenar los campos requeridos (*)!', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    onSort = (column) => (e) => {
        if(column){
            const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
            const sortedData = this.state.data.sort((a, b) => {
            /*if (column === 'client_name') {
                
            } else {
                return a.contractValue - b.contractValue;
            }*/
                const nameA = a[column] ? a[column].toUpperCase() : ""; // ignore upper and lowercase
                const nameB = b[column] ? b[column].toUpperCase() : ""; // ignore upper and lowercase
                
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            
                // names must be equal
                return 0;
            });
        
            if (direction === 'desc') {
            sortedData.reverse();
            }
        
            this.setState({
            data: sortedData,
            sort: {
                column,
                direction,
            }
            });
        }
    }

    setArrow = (column) => {
        let className = 'fa fa-';
        
        if (this.state.sort.column === column) {
          className += this.state.sort.direction === 'asc' ? 'chevron-down' : 'chevron-up';
        }
                
        return className;
    }

    render(){
        const {form,client,currentPage,data,search} = this.state;
        const {clients:{collection,error,loading,pages,count},deleteClient,fetchClients,searchClients} = this.props
        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget 
                        title="Clientes"
                        headerColor="#f5b13f"
                        icon=""
                        onClick={(e) => {
                            e.preventDefault();
                            /*this.toogleForm();*/
                        }}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                <div>
                                <Row>
                                    <Col sm="6">
                                        <p style={{color:"white"}}>Viendo {collection.length} de {count}</p>
                                        <InputGroup>
                                            <InputGroupAddon addonType="append">
                                                <Button onClick={this.searchClient}><i className="fa fa-search"></i></Button>
                                            </InputGroupAddon>
                                            <Input placeholder="Buscar cliente" onChange={e => this.setState({search : e.target.value})} />
                                        </InputGroup>
                                    </Col>
                                    <Col sm="6" style={{textAlign:"right"}}>
                                        <DownloadExcel data={data} />
                                    </Col>
                                </Row>
                                <Table borderless>
                                    <thead>
                                    <tr>
                                        <th onClick={this.onSort('client_name')}>Nombre <i className={this.setArrow('client_name')}></i></th>
                                        <th onClick={this.onSort('client_phone')}>Celular <i className={this.setArrow('client_phone')}></i></th>
                                        <th onClick={this.onSort('client_email')}>Email <i className={this.setArrow('client_email')}></i></th>
                                        <th onClick={this.onSort('client_booking')}>Reserva <i className={this.setArrow('client_booking')}></i></th>
                                        <th onClick={this.onSort('client_razon_social')}>Empresa <i className={this.setArrow('client_razon_social')}></i></th>
                                        <th onClick={this.onSort('client_courtesy')}>Cortesía <i className={this.setArrow('client_courtesy')}></i></th>
                                        <th>Notas</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data && data.length > 0 && data.map((client,index) => (
                                        <tr key={client.client_id}>
                                            <td>{client.client_name}</td>
                                            <td>{client.client_phone}</td>
                                            <td>{client.client_email}</td>
                                            <td>{client.client_booking}</td>
                                            <td>{client.client_razon_social}</td>
                                            <td>{client.client_courtesy}</td>
                                            <td>{client.client_notes}</td>
                                            {/*<td>
                                                <ButtonGroup>
                                                    <Button onClick={(e) => {
                                                        e.preventDefault();
                                                        this.toogleForm(client);
                                                    }}>
                                                        <i className="fa fa-pencil-alt"></i>
                                                    </Button>
                                                    <Button color="danger" onClick={(e) => {
                                                        e.preventDefault();
                                                        let conf = window.confirm("Deseas eliminar este cliente?")
                                                        if(conf){
                                                            deleteClient(client.client_id)
                                                        }
                                                    }}>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                </ButtonGroup>
                                                </td>*/}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table></div>)}
                            </div>
                        }
                        maxHeight={430}
                    />
                    <PaginationView 
                        pages={pages}
                        count={count}
                        active={currentPage}
                        onPageChange={page => {
                            this.setState({currentPage : page, sort : { column : null,direction:"desc"}})
                            if(search){
                                searchClients(search,page)
                            }else{
                                fetchClients(page)
                            }
                        }}
                    />
                </Container>

                <Form 
                    show={form} 
                    onLeave={() => this.toogleForm()} >
                    <div className="form-content">
                        <div className="form-header">
                            <Row>
                                <Col><span>Crear Cliente</span></Col>
                                <Col className="text-align-right">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.toogleForm();
                                    }}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                        <Container className="form-children-content">
                            <FormGroup>
                                <Label>Nombre *</Label>
                                <Input defaultValue={client && client.client_name ? client.client_name : ""} type="text" name="client_name" placeholder="Escriba el nombre del cliente" onChange={this.handleInputChange({field:"client_name"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Correo *</Label>
                                <Input defaultValue={client && client.client_email ? client.client_email : ""} type="email" name="client_email" placeholder=" Escriba el email del cliente" onChange={this.handleInputChange({field:"client_email"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Empresa</Label>
                                <Input defaultValue={client && client.client_company ? client.client_company : ""} type="text" name="client_company" placeholder="Escriba la empresa de prosedencia del cliente" onChange={this.handleInputChange({field:"client_company"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Teléfono *</Label>
                                <Input defaultValue={client && client.client_phone ? client.client_phone : ""} type="text" name="client_name" placeholder="Escriba el teléfono del cliente" onChange={this.handleInputChange({field:"client_phone"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Razón Social</Label>
                                <Input defaultValue={client && client.client_razon_social ? client.client_razon_social : ""} type="text" name="client_rs" placeholder="Escriba la razón social del cliente" onChange={this.handleInputChange({field:"client_razon_social"})} />
                                <FormText color="muted">
                                    En caso de no contar con esta información, dejar en blanco
                                </FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label>RFC</Label>
                                <Input defaultValue={client && client.client_rfc ? client.client_rfc : ""} type="text" name="client_rfc" onChange={this.handleInputChange({field:"client_rfc"})} />
                                <FormText color="muted">
                                    En caso de no contar con esta información, dejar en blanco
                                </FormText>
                            </FormGroup>
                            <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                {!loading && ('Guardar Cliente')}
                                {loading && ('Espere por favor ...')}
                            </Button>
                        </Container>
                    </div>
                </Form>

                <ToastContainer />
            </div>
        )
    }
}

export default Clients