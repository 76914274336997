export default class Api {

    constructor(endPoint, defaults) {
        //this.token = '0b!RWTVv>1A11R-';
        this.baseUrl =  `https://euforiarooms.com/master/api/public` //`http://localhost:81/euforia/master/api/public`
        this.endPoint = endPoint;
		this.defaults = defaults;
    }

    url(params) {
        let defaults = { ...params };
        let queryParams = Object.keys(defaults)
            .map(key => `${encodeURIComponent(defaults[key])}`)
            .join('/');

        //console.log('api',`${this.baseUrl}\/${this.endPoint}\/${queryParams}`);

        return `${this.baseUrl}\/${this.endPoint}\/${queryParams}`;
    }

    get(objectId) {
        let url = `${this.baseUrl}\/${this.endPoint}\/${objectId}`;
        return fetch(url)
            .then(response => response.json());
    }

    post (params,type) {
        let url = this.url();
        let data = new FormData();

        Object.keys(params).forEach(key => {
            if((Array.isArray(params[key]) || typeof(params[key]) == "object") && key.indexOf('image') == -1){
                data.append(key, JSON.stringify(params[key]));
            }else{
                data.append(key, params[key]);
            }
        });

        //console.log('api body',JSON.stringify(params))

        return fetch(url + type, {
            method: 'POST',
            body: data,//JSON.stringify(params)
            /*headers: {
                'content-type': 'multipart/form-data'
            }*/
        })
        .then(response => response.json());
    }

    query(params) {
        return fetch(this.url(params))
            .then(response => response.json())
            .then(json => {
                if (json.message) throw json.message;
                return (Array.isArray(json) ? json : [json]);
            });
    }
}
