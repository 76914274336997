import {UsersApi} from '../api'

export const FETCH_USERS_LOADING = "FETCH_USERS_LOADING";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";

export function deleteUser(user_id,request_by){
    return function(dispatch){
        dispatch(fetchUsersLoading())
        return UsersApi.post({
            user_id,
            request_by
        },'delete')
        .then(response => {
            if(response.response){
                dispatch(fetchUsers())
            }else{
                dispatch(fetchUsersError("CANT_RESPONSE"))
            }
        })
        .catch(error => dispatch(fetchUsersError("NO_RESULT")))
    }
}

export function insertOrUpdateUser(user,request_by){
    return function(dispatch){
        dispatch(fetchUsersLoading())
        return UsersApi.post({
            ...user,
            request_by
        },'save')
        .then(response => {
            if(response.response){
                dispatch(fetchUsers())
            }else{
                dispatch(fetchUsersError("CANT_RESPONSE"))
            }
        })
        .catch(error => dispatch(fetchUsersError("NO_RESULT")))
    }
}

export function fetchUsers(){
    return function(dispatch){
        dispatch(fetchUsersLoading())
        return UsersApi.get('getAll')
        .then(response => {
            if(response.response){
                dispatch(fetchUsersSuccess(response.result))
            }else{
                dispatch(fetchUsersError("NO_RESULT"))
            }
        })
        .catch(error => dispatch(fetchUsersError("NO_RESULT")))
    }
}

function fetchUsersLoading(){
    return {
        type : FETCH_USERS_LOADING
    }
}

function fetchUsersError(error){
    return {
        type : FETCH_USERS_ERROR,
        error
    }
}

function fetchUsersSuccess(users){
    return {
        type : FETCH_USERS_SUCCESS,
        users
    }
}