import React from 'react';
import { Row, Col,Card } from 'reactstrap';
import {Link} from 'react-router-dom';
import './widget.css';
import CardBody from 'reactstrap/lib/CardBody';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
})

const Widget = (props) => {
    return(
        <Col style={{marginTop:60}}>
            <Card>
                <CardBody>
                    <Row>
                        <div className="widget-icon" 
                            style={{backgroundColor:props.color}}
                        >
                            <i className={`fa fa-${props.icon}`}></i>
                        </div>
                        <p className="widget-title">{props.title}</p>
                    </Row>

                    <Row>
                        <Col></Col>
                        <Col>
                            <p className="widget-data">{props.format == "money" ? formatter.format(props.data) : props.data}</p>
                        </Col>
                    </Row>

                    <hr style={{marginBottom:0}} />

                    <Link to={props.path} className="widget-view-more">
                        Ver más
                    </Link>
                    
                </CardBody>
            </Card>
        </Col>
    )
}

export default Widget;