import {FaqsApi} from '../api'

export const FETCH_FAQS_REQUEST = "FETCH_FAQS_REQuEST"
export const FETCH_FAQS_SUCCESS = "FETCH_FAQS_SUCCESS"
export const FETCH_FAQS_ERROR = "FETCH_FAQS_ERROR"

export function deleteFaq(faq_id){
    return function(dispatch){
        dispatch(faqsRequest())
        return FaqsApi.post({
            faq_id
        },`delete`)
        .then(response => {
            if(response.response){
                dispatch(fetchFaqs(1))
            }else{
                dispatch(faqsRequestError("CANT_REMOVE_ROOM"))
            }
        })
    }
}

export function createOrUpdateFaq(faq,request_by){
    return function(dispatch){
        dispatch(faqsRequest())
        return FaqsApi.post({
            ...faq,
            request_by
        },'save')
        .then(response => {
            if(response.response){
                dispatch(fetchFaqs(1))
            }else{
                dispatch(faqsRequestError("CANT_CRUD_ROOM"))
            }
        })
    }
}

export function fetchFaqs(page){
    return function(dispatch){
        dispatch(faqsRequest())
        return FaqsApi.get(`all/${page}`)
        .then(response => {
            if(response.response){
                dispatch(faqsRequestSuccess(response.result))
            }else{
                dispatch(faqsRequestError("CANT_GET_FAQS"))
            }
        })
        .catch(error => dispatch(faqsRequestError(error)))
    }
}

function faqsRequest(){
    return {
        type : FETCH_FAQS_REQUEST
    }
}

function faqsRequestError(error){
    return {
        type : FETCH_FAQS_ERROR,
        error
    }
}

function faqsRequestSuccess(faqs){
    return {
        type : FETCH_FAQS_SUCCESS,
        faqs
    }
}