import {ProductApi} from '../api'

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST"
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS"
export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR"

export function createOrUpdateProduct(client){
    return function(dispatch){
        dispatch(fetchProductsRequest())
        return ProductApi.post(client,'save')
        .then(response => {
           if(response.response){
               dispatch(fetchProducts())
           }else{
                dispatch(fetchProductsError("Ocurrió un error al actualizar"))
           }
        })
        .catch(error => {
            console.log(error)
        })
    }
}

export function fetchProducts(){
    return function(dispatch){
        dispatch(fetchProductsRequest())
        return ProductApi.get('getAll')
        .then(response => {
            if(response.result){
                dispatch(fetchProductsSuccess(response.result))
            }else{
                dispatch(fetchProductsError("No se encontraron resultados"))
            }
        })
        .catch(error => {
            dispatch(fetchProductsError(error))
        })
    }
}

function fetchProductsSuccess(products){
    return {
        type : FETCH_PRODUCTS_SUCCESS,
        products
    }
}

function fetchProductsError(error){
    return {
        type : FETCH_PRODUCTS_ERROR,
        error
    }
}

function fetchProductsRequest(){
    return {
        type : FETCH_PRODUCTS_REQUEST
    }
}