import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,rooms,bookings,clients,giftcards} from '../actions';
import Dashboard from '../pages/Dashboard';

export default connect(
    (state) => ({
        account: state.account,
        rooms : state.rooms,
        bookings : state.bookings,
        clients : state.clients,
        giftcards : state.giftcards
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...rooms,
        ...bookings,
        ...clients,
        ...giftcards
    }, dispatch)
)(Dashboard);
