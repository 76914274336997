import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {faqs, account} from '../actions';
import Faqs from '../pages/Faqs';

export default connect(
    (state) => ({
        faqs : state.faqs,
        account : state.account
    }),
    (dispatch) => bindActionCreators({
        ...faqs,
        ...account
    }, dispatch)
)(Faqs);
