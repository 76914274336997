import {ProyectApi} from '../api';

export const FETCH_PROYECTS_REQUEST = "FETCH_PROYECTS_REQUEST"
export const FETCH_PROYECTS_ERROR = "FETCH_PROYECTS_ERROR"
export const FETCH_PROYECTS_SUCCESS = "FETCH_PROYECTS_SUCCESS"
export const FETCH_PROYECT_SERVICES = "FETCH_PROYECT_SERVICES"
export const SAVE_SUCCESS_PROYECT = "SAVE_SUCCESS_PROYECT"


export function createOrUpdateProyect(proyect){
    return function(dispatch){
        dispatch(fetchProyectRequest())
        return ProyectApi.post(proyect,'save')
        .then(response => {
            if(response.response){
                dispatch(fetchProyects());
                dispatch(toogleSuccess());
            }else{
                dispatch(fetchProyectError("No se contraron resultados"))
            }
        })
        .catch(error => {
            dispatch(fetchProyectError(error))
        })
    }
}

function toogleSuccess(){
    return {
        type : SAVE_SUCCESS_PROYECT
    }
}

export function fetchProyects(){
    return function(dispatch){
        dispatch(fetchProyectRequest())
        return ProyectApi.get(`getAll`)
        .then(response => {
            if(response.result){
                dispatch(fetchProyectSuccess(response.result))
            }else{
                dispatch(fetchProyectError("No se contraron resultados"))
            }
        })
        .catch(error => {
            dispatch(fetchProyectError(error))
        })
    }
}

export function fetchProyect(proyect_id){
    return function(dispatch){
        dispatch(fetchProyectRequest())
        return ProyectApi.get(`get/${proyect_id}`)
        .then(response => {
            if(response.result){
                dispatch(fetchProyectSuccess(response.result))
            }else{
                dispatch(fetchProyectError("No se encuentra el proyecto"))
            }
        })
        .catch(error => {
            dispatch(fetchProyectError(error))
        })
    }
}

function fetchProyectSuccess(proyect){
    return {
        type : FETCH_PROYECTS_SUCCESS,
        proyect
    }
}

function fetchProyectError(error){
    return {
        type : FETCH_PROYECTS_ERROR,
        error
    }
}

export function fetchProyectQuotation(proyect_id){
    return function(dispatch){
        //dispatch(fetchProyectRequest())
        dispatch(fetchProyectRequest())
        return ProyectApi.get(`getQuotationId/${proyect_id}`)
        .then(response => {
            let quotationId = response.result.quotation_id
            if(quotationId && quotationId > 0){
                return ProyectApi.get(`getServices/${quotationId}`)
                .then(response => {
                    dispatch(fetchProyectServices(response.result))
                })
                .catch(error => {
                    dispatch(fetchProyectError(error))
                })
            }
        })
        .catch(error => {

        })
    }
}

function fetchProyectServices(services){
    return {
        type : FETCH_PROYECT_SERVICES,
        services
    }
}

function fetchProyectRequest(){
    return {
        type : FETCH_PROYECTS_REQUEST
    }
}