import {BackupApi} from '../api'

export const FETCH_BACKUP_REQUEST = "FETCH_BACKUP_REQUEST"
export const FETCH_BACKUP_ERROR = "FETCH_BACKUP_ERROR"
export const FETCH_BACKUP_SUCCESS = "FETCH_BACKUP_SUCCESS"

export function fetchBackup(page,date,_module,user){
    return function(dispatch){
        dispatch(fetchBackupRequest())
        return BackupApi.get(`getAll/${page}/${date}/${_module}/${user}`)
        .then(response => {
            if(response.response){
                dispatch(fetchBackupSuccess(
                    response.result,
                    response.total_pages,
                    response.total_rows
                ));
            }else{
                dispatch(fetchBackupError("CANT_GET_ALL_BACKUP"))
            }
        })
        .catch(error => dispatch(fetchBackupError(error)))
    }
}
function fetchBackupSuccess(collection,pages,rows){ 
    return {
        type : FETCH_BACKUP_SUCCESS,
        collection, pages, rows
    }
}
function fetchBackupRequest(){ return { type : FETCH_BACKUP_REQUEST } }
function fetchBackupError(error){ return { type : FETCH_BACKUP_ERROR, error } }