import React,{Component} from 'react'
import { Row, Col, Container, FormGroup, Label, Input,Table,Button } from 'reactstrap';
import {FormWidget,Form,FileInput,ActivityIndicator} from '../components';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';

class Settings extends Component{
    constructor(){
        super();
        this.state = {
            form : false,
            pdf_mail : "",
            logo : "",
            contact_phone: "",
            address : "",
            success : false
        }
    }

    componentDidMount(){
        const {fetchSettings} = this.props
        fetchSettings();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.settings !== this.props.settings && nextProps.settings){
            this.setState({
                pdf_mail : nextProps.settings.pdf_mail,
                logo : nextProps.settings.logo,
                contact_phone : nextProps.settings.contact_phone,
                address : nextProps.settings.address
            })
        }

        if(nextProps.settings.success !== this.props.settings.success && nextProps.settings.success){
            this.setState({ success : true })
        }
    }

    toogleForm = (product) => { this.setState({form : !this.state.form, product}) }

    handleInputChange = ({field}) => evt => {        
        this.setState({
            [field] : evt.target.value
        });
    }

    onChangeFile = (file)  => {
        let base64File = '';
        //console.log(file,'archivo')
        this.getBase64(file, (result) => {
            base64File = result;
            //console.log(base64File,'here')
            this.setState({logo : base64File})
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    onSubmit = (e) => {
        const {updateSettings} = this.props
        const {pdf_mail,contact_phone,logo,address} = this.state
        e.preventDefault();
        updateSettings({
            pdf_mail,contact_phone,logo,address
        });
    }

    render(){
        const {form,pdf_mail,logo,contact_phone,address,success} = this.state;
        const {loading,error} = this.props.settings

        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget 
                        title="Ajustes"
                        headerColor="#f5b13f"
                        icon=""
                        onClick={(e) => {
                            e.preventDefault();
                            //this.toogleForm();
                        }}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && (
                                    <Table borderless>
                                        <thead>
                                        <tr>
                                            <th>Logo</th>
                                            <th>Email</th>
                                            <th>Teléfono</th>
                                            <th>Acciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><img src={logo} style={{width:100,backgroundColor:"#000"}} /></td>
                                                <td>{contact_phone}</td>
                                                <td>{pdf_mail}</td>
                                                <td>
                                                    <Button onClick={(e) => {
                                                        e.preventDefault();
                                                        this.toogleForm();
                                                    }}>
                                                        <i className="fa fa-pencil-alt"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        }
                        maxHeight={430}
                    />
                </Container>

                <Form 
                    show={form} 
                    onLeave={() => this.toogleForm()} >
                    <div className="form-content">
                        <div className="form-header">
                            <Row>
                                <Col><span>Ajustes</span></Col>
                                <Col className="text-align-right">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.toogleForm();
                                    }}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                        <Container className="form-children-content">
                            <FormGroup>
                                <Label>Logo</Label>
                                <FileInput 
                                    input={{
                                        onChange : (file) => { this.onChangeFile(file) },
                                        value : logo,
                                        onClear : () => {this.setState({logo:""})}
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Email de Contacto</Label>
                                <Input defaultValue={pdf_mail && pdf_mail != "" ? pdf_mail : ""} type="email" onChange={this.handleInputChange({field:"pdf_mail"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Teléfono de Contacto</Label>
                                <Input defaultValue={contact_phone && contact_phone != "" ? contact_phone : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"contact_phone"})} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Direción</Label>
                                <Input defaultValue={address && address != "" ? address : ""} type="text"  placeholder="" onChange={this.handleInputChange({field:"address"})} />
                            </FormGroup>
                            <Button disabled={loading ? true : false} onClick={this.onSubmit}>
                                        {!loading && ('Guardar Ajustes')}
                                        {loading && ('Espere por favor ...')}
                            </Button>
                        </Container>
                    </div>
                </Form>

                <SweetAlert 
                    show={success}
                    title="Aviso"
                    type={"success"}
                    text={"Guardado correctamente"}
                    onConfirm={() => this.setState({ success: false })}
                />
            </div>
        )
    }
}

export default Settings