import {TodosApi} from '../api'

export const FETCH_TODO_REQUEST = "FETCH_TODOS_REQUEST"
export const FETCH_TODO_SUCCESS = "FETCH_TODOS_SUCCESS"
export const FETCH_TODO_ERROR = "FETCH_TODOS_ERROR"

export function newTodo(todo){
    return function(dispatch){
        dispatch(fetchTodoRequest())
        return TodosApi.post(todo,'save')
        .then(response => {
            dispatch(fetchTodos())
        })
        .catch(error => dispatch(fetchTodosError(error)))
    }
}

export function deleteTodo(todo_id){
    return function(dispatch){
        dispatch(fetchTodoRequest())
        return TodosApi.post({},`delete/${todo_id}`)
        .then(response => {
            dispatch(fetchTodos())
        })
        .catch(error => dispatch(fetchTodosError(error)))
    }
}

export function fetchTodos(){
    return function(dispatch){
        dispatch(fetchTodoRequest())
        return TodosApi.get('getAll')
        .then(response => {
            if(response.response){
                dispatch(fetchTodosSuccess(response.result))
            }else{
                dispatch(fetchTodosError("NO_RESULTS"))
            }
        })
        .catch(error => dispatch(fetchTodosError(error)))
    }
}

function fetchTodoRequest(){
    return {
        type : FETCH_TODO_REQUEST
    }
}

function fetchTodosSuccess(todos){
    return {
        type : FETCH_TODO_SUCCESS,
        todos
    }
}

function fetchTodosError(error){
    return {
        type : FETCH_TODO_ERROR,
        error
    }
}