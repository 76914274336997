import React, {Component} from 'react'
import ReactExport from "react-export-excel";
import {Container,Table,Row,Col,FormGroup,Input,Button,ButtonGroup,Label,InputGroup,InputGroupAddon} from 'reactstrap'
import {FormWidget,Form, ActivityIndicator, PaginationView} from '../components'
import moment from 'moment'
moment.locale('es')

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

const tipoPagoUSe = {
    1 : "Efectivo",
    2 : "Cheque nominativo",
    3 : "Transferencia electrónica de fondos",
    4 : "Tarjeta de Crédito",
    28 : "Tarjeta de Débito",
    99 : "Por definir"
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadExcel = (props) => {
    return(
        <ExcelFile element={<Button>Exportar a Excel</Button>} filename={"ventas"}>
            <ExcelSheet data={props.data} name="Ventas">
                <ExcelColumn label="Fecha" value={(col) => moment(col.booked_at).format('DD[-]MMM[-]YY')} />
                <ExcelColumn label="Cuarto" value="room_name" />
                <ExcelColumn label="Código Reservación" value="booking_code" />
                <ExcelColumn label="Personas" value="players" />
                <ExcelColumn label="Importe Total" value={(col) => formatter.format(col.booking_amount) } />
                <ExcelColumn label="Efectivo" value={(col) => formatter.format(col.booking_cash_amount)} />
                <ExcelColumn label="Terminal" value={(col) => formatter.format(col.booking_terminal_amount)} />
                <ExcelColumn label="Tipo de pago" value={(col) => tipoPagoUSe[col.booking_payment_type]} />
                <ExcelColumn label="Cortesía" value="booking_courtesy_id" />
                <ExcelColumn label="Cliente" value="booking_name" /> 
                <ExcelColumn label="Celular" value="booking_phone" />
                <ExcelColumn label="Email" value="booking_email" />
                <ExcelColumn label="Empresa" value="company_name" />
                <ExcelColumn label="Factura" value="invoice_number" />
            </ExcelSheet>
        </ExcelFile>
    )
}

class Sales extends Component{

    state = {
        currentPage : 0
    }

    componentDidMount(){
        const {fetchSales} = this.props
        fetchSales(0)
    }

    render(){
        const {sales:{error,loading,collection,count,pages},fetchSales} = this.props
        const {currentPage} = this.state
        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget 
                        title="Ventas"
                        headerColor="#f5b13f"
                        icon=""
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        maxHeight={430}
                        render={<div>
                            {error && !loading && (<span>{error.toString()}</span>)}
                            {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                            {!loading && !error && collection && collection.length > 0 && (
                                <div>
                                    <Row>
                                        <Col sm="6">
                                            <p style={{color:"white"}}>Viendo {collection.length} de {count}</p>
                                        </Col>
                                        <Col sm="6" style={{textAlign:"right"}}>
                                            <DownloadExcel data={collection} />
                                        </Col>
                                    </Row>
                                    <Table className="sales-table" borderless>
                                        <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Cuarto</th>
                                            <th>Código Reservación</th>
                                            <th>Personas</th>
                                            <th>Importe Total</th>
                                            <th>Efectivo</th>
                                            <th>Terminal</th>
                                            <th>Tipo de pago</th>
                                            <th>Cortesía</th>
                                            <th>Cliente</th>
                                            <th>Celular</th>
                                            <th>Email</th>
                                            <th>Empresa</th>
                                            <th>Factura</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {collection && collection.length > 0 && collection.map((sale,index) => (
                                                <tr key={sale.booking_id}>
                                                    <td style={{width:100}}>{sale && sale.booked_at != "" ? moment(sale.booked_at).format('DD[-]MMM[-]YY') : ""}</td>
                                                    <td>{sale && sale.room_name != "" ? sale.room_name : "N/A"}</td>
                                                    <td>{sale && sale.booking_code != "" ? sale.booking_code : "N/A"}</td>
                                                    <td>{sale && sale.players != "" ? sale.players : "N/A"}</td>
                                                    <td>{sale && sale.booking_amount != "" ? formatter.format(sale.booking_amount) : "N/A"}</td>
                                                    <td>{sale && sale.booking_cash_amount != "" ? formatter.format(sale.booking_cash_amount) : "N/A"}</td>
                                                    <td>{sale && sale.booking_terminal_amount != "" ? formatter.format(sale.booking_terminal_amount) : "N/A"}</td>
                                                    <td>{sale && sale.booking_payment_type != "" ? tipoPagoUSe[sale.booking_payment_type] : "N/A"}</td>
                                                    <td>{sale && sale.booking_courtesy_id != "0" ? sale.booking_courtesy_id : "N/A"}</td>
                                                    <td>{sale && sale.booking_name != "" ? sale.booking_name : "N/A"}</td>
                                                    <td>{sale && sale.booking_phone != "" ? sale.booking_phone : "N/A"}</td>
                                                    <td>{sale && sale.booking_email != "" ? sale.booking_email : "N/A"}</td>
                                                    <td>{sale && sale.company_name != "" ? sale.company_name : "N/A"}</td>
                                                    <td>{sale && sale.invoice_number != "" ? sale.invoice_number : "N/A"}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </div>}
                    />

                    <PaginationView 
                        pages={pages}
                        count={count}
                        active={currentPage}
                        onPageChange={page => {
                            this.setState({currentPage : page})
                            fetchSales(page)
                        }}
                        style={{flexWrap: "wrap"}}
                    />
                </Container>
            </div>
        )
    }
}

export default Sales;