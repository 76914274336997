import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Row,Col } from 'reactstrap';

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      closeAll: false
    };
  }

  componentDidMount(){
      const {show} = this.props
      this.setState({
          modal : show
      })
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.show !== this.props.show && nextProps.show){
        this.setState({
            modal : nextProps.show
        })
      }
  }

  render() {
    const {children,title,className,onContinue,onCancel,show,loading,error,style,anotherOne,hasRemove,onRemove} = this.props;
    return (
      <div>
        <Modal isOpen={show} className={className} style={style}>
          <ModalHeader>
            {title}<br></br>
            {anotherOne}
          </ModalHeader>
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter>
            {hasRemove && onRemove && (
              <Button disabled={loading} color={"danger"} onClick={() => onRemove()}>Eliminar</Button>
            )}
            <Button disabled={loading} color="primary" onClick={() => onContinue()}>Continuar</Button>{' '}
            <Button disabled={loading} color="secondary" onClick={() => onCancel()}>Cerrar</Button>
            {error && (
              <div className="alert alert-danger" style={{marginTop:15}}>
                <span>{error.toString()}</span>
              </div>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalExample;