import {SalesApi} from '../api'

export const FETCH_SALES_REQUEST = "FETCH_SALES_REQUEST"
export const FETCH_SALES_ERROR = "FETCH_SALES_ERROR"
export const FETCH_SALES_SUCCESS = "FETCH_SALES_SUCCESS"

export function fetchSales(page){
    return function(dispatch){
        dispatch(fetchSalesRequest())

        return SalesApi.get(`getAll/${page}`)
        .then(response => {
            if(response.response && response.result){
                dispatch(fetchSalesSuccess(
                    response.result,
                    response.total_pages,
                    response.total_rows
                ))
            }else{
                dispatch(fetchSalesError("CANT_GET_SALES"))
            }
        })
        .catch(error => dispatch(fetchSalesError(error)))
    }
}

function fetchSalesRequest(){
    return {
        type : FETCH_SALES_REQUEST
    }
}

function fetchSalesError(error){
    return {
        type : FETCH_SALES_ERROR,
        error
    }
}

function fetchSalesSuccess(sales,pages,count){
    return {
        type : FETCH_SALES_SUCCESS,
        sales, pages, count
    }
}