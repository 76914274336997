import React,{Component} from 'react';
import { Row, Container,Table,Button,Form,FormGroup,Label,Input,FormFeedback,Col,CustomInput,InputGroup,InputGroupAddon,ButtonGroup } from 'reactstrap';
import {ActivityIndicator,Modal} from '../components'
import CurrencyInput from 'react-currency-input';
import Select from 'react-select';
import './dashboard.css';
import moment, { weekdaysShort } from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

moment.locale('es')

const tipoPagoUSe = {
    1 : "Efectivo",
    2 : "Cheque nominativo",
    3 : "Transferencia electrónica de fondos",
    4 : "Tarjeta de Crédito",
    28 : "Tarjeta de Débito",
    99 : "Por definir"
}

const cfdiUse = {
    1 : "Adiquisición de Mercancias",
    2 : "Devoluciones, descuentos o bonificaciones",
    3 : "Gastos en general",
    4 : "Construcciones",
    5 : "Mobiliaria y equipo de oficina por inversiones",
    6 : "Equipo de transporte",
    7 : "Equipo de computo y accesorios",
    8 : "Dados, troqueles, moldes, matrices y herramental",
    9 : "Comunicaciones telefónicas",
    10 : "Comunicaciones satelitales",
    11 : "Otra maquinario o equipo",
    12 : "Honorarios médicos, dentales y gastos hospitalarios",
    13 : "Gastos médicos por incapacidad o discapacidad",
    14 : "Gastos funerales",
    15 : "Donativos",
    16 : "Intereses reales",
    17 : "Aportaciones voluntarias al SAR",
    18 : "Primas por seguros de gastos médicos",
    19 : "Gastos de transportación escolar obligatoria",
    20 : "Depósitos en cuentas para el ahorro",
    21 : "Pagos por servicios educativos",
    22 : "Por definir"
}

const Hours = (props) => {
    const status = (hour,props) => {
        let data = props.data.find((item,index) => {
            return item.hour == hour;
        });
        return data && data.status;
    }
    const item = (hour,props) => {
        let data = props.data.find((item,index) => {
            return item.hour == hour;
        });
        return data;
    }
    return(
        <Row className="hours-wrap">
            <Button color={"link"} onClick={(e) => props.onClick(e,"00:00",item("00:00:00",props))} className={`hour hour-${status("00:00:00",props)}`}>00:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"01:00",item("01:00:00",props))} className={`hour hour-${status("01:00:00",props)}`}>01:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"02:00",item("02:00:00",props))} className={`hour hour-${status("02:00:00",props)}`}>02:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"03:00",item("03:00:00",props))} className={`hour hour-${status("03:00:00",props)}`}>03:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"04:00",item("04:00:00",props))} className={`hour hour-${status("04:00:00",props)}`}>04:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"05:00",item("05:00:00",props))} className={`hour hour-${status("05:00:00",props)}`}>05:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"06:00",item("05:00:00",props))} className={`hour hour-${status("06:00:00",props)}`}>06:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"07:00",item("07:00:00",props))} className={`hour hour-${status("07:00:00",props)}`}>07:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"08:00",item("08:00:00",props))} className={`hour hour-${status("08:00:00",props)}`}>08:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"09:00",item("09:00:00",props))} className={`hour hour-${status("09:00:00",props)}`}>09:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"10:00",item("10:00:00",props))} className={`hour hour-${status("10:00:00",props)}`}>10:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"11:00",item("11:00:00",props))} className={`hour hour-${status("11:00:00",props)}`}>11:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"12:00",item("12:00:00",props))} className={`hour hour-${status("12:00:00",props)}`}>12:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"13:00",item("13:00:00",props))} className={`hour hour-${status("13:00:00",props)}`}>13:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"14:00",item("14:00:00",props))} className={`hour hour-${status("14:00:00",props)}`}>14:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"15:00",item("15:00:00",props))} className={`hour hour-${status("15:00:00",props)}`}>15:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"16:00",item("16:00:00",props))} className={`hour hour-${status("16:00:00",props)}`}>16:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"17:00",item("17:00:00",props))} className={`hour hour-${status("17:00:00",props)}`}>17:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"18:00",item("18:00:00",props))} className={`hour hour-${status("18:00:00",props)}`}>18:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"19:00",item("19:00:00",props))} className={`hour hour-${status("19:00:00",props)}`}>19:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"20:00",item("20:00:00",props))} className={`hour hour-${status("20:00:00",props)}`}>20:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"21:00",item("21:00:00",props))} className={`hour hour-${status("21:00:00",props)}`}>21:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"22:00",item("22:00:00",props))} className={`hour hour-${status("22:00:00",props)}`}>22:00</Button>
            <Button color={"link"} onClick={(e) => props.onClick(e,"23:00",item("23:00:00",props))} className={`hour hour-${status("23:00:00",props)}`}>23:00</Button>
        </Row>
    )
}

const Hour = (props) => {
    return(
        <Button color={"link"} onClick={(e) => props.onClick()} className={`hour hour-${props.status}`}>{props.hour}</Button>
    )
}

const Day = (props) => {
    return(
        <div className="dayCol">
            <a href="#" onClick={e => {
                e.preventDefault();
                props.onSelectDate(props.date);
            }}>{moment(props.date).format('dddd, DD MMM')}</a>
        </div>
    )
}

const Month = (props) => {
    return(
        <div className="dayCol">
            <a href="#" onClick={e => {
                e.preventDefault();
                props.onSelectDate(props.date);
            }}>{props.date}</a>
        </div>
    )
}

class Dashboard extends Component{

    state = {
        showModal : false,
        showRoomModal : false,
        roomTurnsArr : [],
        selectedHour : "",
        selectedDate : new Date(),
        formatedDate : moment(new Date()).format('DDMMYY'),
        viewType : "hours",
        currentDate : moment(new Date()).format('Y-MM-DD'),
        currentYear : moment().startOf('year').format('Y-MM-DD'),
        giftCardType : 'temp',
        booking : {
            booking_id: 0,
            created_at: "",
            booked_at: moment(new Date()).format('Y-MM-DD'),
            room_id: 0,
            hour: "00:00:00",
            players: 0,
            booking_name: "",
            booking_email: "",
            booking_phone: "",
            booking_amount: "",
            booking_code: "",
            booking_payment_type: 0,
            booking_courtesy_id: 0,
            booking_notes: "",
            booking_cfdi_use: 0,
            booking_invoice_sent: "",
            booking_terminal_amount: "",
            booking_cash_amount: 0,
            status: "available",
            booking_invoice_needed: 0,
            booking_terminal_checked: 0,
            booking_cash_checked: 0,
            booking_payed: 0,
            invoice_number : "",
            client: {
                client_id: 0,
                client_razon_social: "",
                client_phone: "",
                client_email: "",
                client_rfc: "",
                client_address: ""
            }
        },
        selectedRoom : "",
        selectedRoomId : 0,
        showNestedModal : false,
        isGiftcardAvailable : false
    }
    
    componentDidMount(){
        const {fetchBookingClients,fetchBookings,fetchRooms} = this.props;
        fetchBookingClients();
        fetchBookings(moment().format('Y-MM-DD'));
        fetchRooms(1,moment().format('Y-MM-DD'));
        this.getWeeklyBookings();
        this.getMonthlyBookings();
    }

    filterByDate(date,type){
        const {fetchBookings,fetchRooms} = this.props;
        const {booking,viewType} = this.state
        let formated = "";
        if(type == "left"){
            let newDate = moment(date).subtract(1, 'days');
            formated = moment(newDate).format('DDMMYY');
            this.setState({
                selectedDate : new Date(newDate),
                formatedDate : formated,
                booking : {
                    ...booking,
                    booked_at : moment(date).format('Y-MM-DD')
                }
            })
            fetchBookings(moment(newDate).format('Y-MM-DD'));
            fetchRooms(1,moment(newDate).format('Y-MM-DD'));
        }else if(type == "right"){
            let newDate = moment(date).add(1, 'days')
            formated = moment(newDate).format('DDMMYY');
            this.setState({
                selectedDate : new Date(newDate),
                formatedDate : formated,
                booking : {
                    ...booking,
                    booked_at : moment(date).format('Y-MM-DD')
                }
            });
            fetchBookings(moment(newDate).format('Y-MM-DD'));
            fetchRooms(1,moment(newDate).format('Y-MM-DD'));
        }else{
            formated = moment(date).format('DDMMYY')
            this.setState({
                selectedDate : date,
                formatedDate : formated,
                booking : {
                    ...booking,
                    booked_at : moment(date).format('Y-MM-DD')
                }
            })
            fetchBookings(moment(date).format('Y-MM-DD'));
            fetchRooms(1,moment(date).format('Y-MM-DD'));
        }

        if(viewType == "weeks"){
            this.renderWeeks(date);
        }
    }

    handleInputChange = ({field}) => evt => {   
        if(field == "players" && evt.target.value > 0){
            this.setState({ booking: {
                ...this.state.booking,
                status : "booked",
                [field] : evt.target.value
            }});
        }else{
            this.setState({ booking: {
                ...this.state.booking,
                [field] : evt.target.value
            }});
        }
    }

    resetBooking(hour,room_id,formatedDate){
        const {booking,selectedDate} = this.state
        this.setState({
            booking : {
                ...booking,
                hour : hour,
                room_id : room_id,
                booking_code : null,//`${room_id}-${hour.replace(':','')}-${formatedDate}`,
                booking_id: 0,
                created_at: "",
                booked_at: moment(selectedDate).format('Y-MM-DD'),
                players: 0,
                booking_name: "",
                booking_email: "",
                booking_phone: "",
                booking_amount: "",
                booking_payment_type: 0,
                booking_courtesy_id: 0,
                booking_notes: "",
                booking_cfdi_use: 0,
                booking_invoice_sent: 0,
                booking_terminal_amount: 0,
                booking_cash_amount: 0,
                status: "available",
                booking_invoice_needed: 0,
                booking_terminal_checked: 0,
                booking_cash_checked: 0,
                booking_payed: 0,
                invoice_number : "",
                client: {
                    client_id: 0,
                    client_razon_social: "",
                    client_phone: "",
                    client_email: "",
                    client_rfc: "",
                    client_address: ""
                }
            }
        })
    }

    blockBookingHour(e){
        const {booking} = this.state
        this.setState({
            booking : {
                ...booking,
                status : booking.status == "blocked" ? "available" : "blocked"
            }
        })
    }

    changeDate(date){
        const {fetchBookings,fetchRooms} = this.props;
        const {booking} = this.state;

        let formated = moment(date).format('DDMMYY');
        this.setState({
            selectedDate : moment(date).format('Y-MM-DD'),
            formatedDate : formated,
            booking : {
                ...booking,
                booked_at : moment(date).format('Y-MM-DD')
            },
            viewType : 'hours'
        })
        fetchBookings(moment(date).format('Y-MM-DD'));
        fetchRooms(1,moment(date).format('Y-MM-DD'));
    }

    changeMonth(date){
        const {fetchBookings} = this.props;
        const {booking} = this.state;

        let formated = moment(date).format('DDMMYY');
        this.setState({
            selectedDate : moment(date).format('Y-MM-DD'),
            formatedDate : formated,
            currentDate : moment(date).startOf('month').format('Y-MM-DD'),
            currentYear : moment(date).startOf('year').format('Y-MM-DD'),
            booking : {
                ...booking,
                booked_at : moment(date).format('Y-MM-DD')
            },
            viewType : 'weeks'
        })
        fetchBookings(moment(date).format('Y-MM-DD'));
        setTimeout(() => this.getWeeklyBookings(),50)
        
    }

    getWeeklyBookings(type = "top"){
        const {currentDate} = this.state; //26 de enero
        const {fetchWeeklyBookings} = this.props;
        const daysBefore = moment(currentDate).format('Y-MM-DD');
        const daysAfter = moment(currentDate).add(10, 'days').format('Y-MM-DD');

        fetchWeeklyBookings(daysBefore,daysAfter);
    }

    renderWeeks(type = "top"){       
        const {currentDate} = this.state;
        let dates = []

        for(let i = 0; i <= 10; i++){
            dates.push(
                type == "top" 
                ? <Day onSelectDate={() => this.changeDate(moment(currentDate).add(i,'days').format('Y-MM-DD'))} date={moment(currentDate).add(i,'days').format('Y-MM-DD')} /> 
                : <Day onSelectDate={() => this.changeDate(moment(currentDate).subtract(i,'days').format('Y-MM-DD'))} date={moment(currentDate).subtract(i,'days').format('Y-MM-DD')} />
            )
        }

        return dates
    }

    getMonthlyBookings(type = "top"){
        const {currentYear} = this.state; //26 de enero
        const {fetchMonthlyBookings} = this.props;
        
        fetchMonthlyBookings(moment(currentYear).format('Y'));
    }

    renderMonths(type = "top"){
        const {currentYear} = this.state;
        let dates = []

        for(let i = 0; i <= 11; i++){
            dates.push(
                type == "top" 
                ? <Month onSelectDate={() => this.changeMonth(moment(currentYear).add(i,'months').format('Y-MM-DD'))} date={moment(currentYear).add(i,'months').format('MMM, Y')} /> 
                : <Month onSelectDate={() => this.changeMonth(moment(currentYear).subtract(i,'months').format('Y-MM-DD'))} date={moment(currentYear).subtract(i,'months').format('MMM, Y')} />
            )
        }

        return dates
    }

    getTotalBookings(type = "weekly", data = [],date = ""){
        let total = 0
        if(type == "weekly"){
            let date_ = data.booked_at;
            data.rooms.map((item,index) => {
                if(date_ == date){
                    total = total + parseInt(item.total)
                }
            })
        }

        if(type == "monthly"){
            let date_ = data.booked_at;
            data.rooms.map((item,index) => {
                if(date_ == date){
                    total = total + parseInt(item.total)
                }
            })
        }

        return parseInt(total);
    }
    
    addNewTurn(){
        const {roomTurnsArr, selectedRoomId} = this.state
        this.setState({
            roomTurnsArr : [
                ...roomTurnsArr,
                {
                    turn_id : new Date().getTime(),
                    from : null,
                    to : null,
                    hours : [],
                    room_id : selectedRoomId
                }
            ]
        })
    }

    handleDynamicInputChange = (field,index) => evt => { 
        const {roomTurnsArr} = this.state   
        
        let edited = [ ...roomTurnsArr ];
        edited[index] = {...edited[index], [field]: evt.target.value};

        if(edited){

            this.setState({
                roomTurnsArr : edited
            });

        }
    }

    handleDynamicSelectInputChange(field,index,option){
        const {roomTurnsArr} = this.state   
        
        let edited = [ ...roomTurnsArr ];
        edited[index] = {...edited[index], [field]: option};

        if(edited){

            this.setState({
                roomTurnsArr : edited
            });

        }
    }

    createRoomTurns(){
        const {createOrUpdateRoomTurns,account:{data}} = this.props
        const {roomTurnsArr} = this.state

        //console.log(roomTurnsArr,'turnos del cuarto')
        if(roomTurnsArr.length > 0){
            let canCreate = false
            roomTurnsArr.map((item,index) => {
                if(item.from && item.from != "" &&
                item.to && item.to != "" &&
                item.hours && item.hours.length > 0){
                    canCreate = true
                }
            })
            if(canCreate){
                createOrUpdateRoomTurns(roomTurnsArr,data.user_name)
            }else{
                toast.warn('Verifique, que todos los turnos tengan asignadas las fechas y las horas', {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
            
        }else{
            toast.warn('Agregue al menos una regla', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
       
    }

    viewRoomHours(room_id){
        const {fetchRoomTurns} = this.props;
        fetchRoomTurns(room_id);
        this.setState({showRoomModal:true,selectedRoomId:room_id})
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.rooms.booking !== this.props.rooms.booking && nextProps.rooms.booking && nextProps.rooms.booking.booking_id){
            this.setState({
                booking : nextProps.rooms.booking
            })
        }

        if(nextProps.rooms.turns !== this.props.rooms.turns && nextProps.rooms.turns && nextProps.rooms.turns.length > 0){
            //console.log(nextProps.rooms.booking,'data')
            this.setState({
                roomTurnsArr : nextProps.rooms.turns
            })
        }

        if(nextProps.giftcards.isValid !== this.props.giftcards.isValid){
            //console.log(nextProps.rooms.booking,'data')
            this.setState({
                isGiftcardAvailable : nextProps.giftcards.isValid
            });
            if(nextProps.giftcards.isValid){
                this.getGiftcardDiscount(this.state.booking.booking_courtesy_id,nextProps.giftcards.isValid);
            }
        }

        
    }

    showBooking(blocks,hour,room_id){
        const {rooms:{collection},getHourBooking} = this.props;
        const {formatedDate,booking,selectedDate,currentDate} = this.state
        this.setState({
            showModal:true,
            booking : {
                ...booking,
                booking_code:`${room_id}-${hour.substring(0,hour.length-3).replace(":","")}-${formatedDate}`,
                room_id,
                hour,
                booked_at : moment(selectedDate).format('Y-MM-DD')
            },
            selectedRoom : collection.find((item) => item.room_id == room_id).room_name,
            selectedHour : hour.substring(0,hour.length-3)
        });

        getHourBooking({
            booked_at : moment(selectedDate).format('Y-MM-DD'),
            room_id,
            hour
        })
    }

    checkGiftcard(value){
        const {booking} = this.state
        const {getGiftcard} = this.props
        if(value && value != ""){
            getGiftcard(value);
            this.setState({
                booking : {
                    ...booking,
                    booking_courtesy_id : value
                }
            })
        }
    }

    getGiftcardDiscount(giftcard_code,isGiftcardAvailable){
        const {booking} = this.state
        //get giftcardType        
        let courType = isGiftcardAvailable.giftcard_type, //temp
        canGo = false

        if(booking && booking.players && booking.players > 0 && booking.booking_amount && booking.booking_amount != "" && booking.booking_amount > 0){
            if(giftcard_code && giftcard_code != ""){
                if(isGiftcardAvailable.giftcard_available == 1){
                    if(isGiftcardAvailable.giftcard_remaining_uses == 0
                        && isGiftcardAvailable.giftcard_uses == 0
                        && isGiftcardAvailable.giftcard_available_date == "0000-00-00"
                    ){
                        courType = "unlimited"
                        canGo = true
                    }

                    if(courType == "temp"){
                        //validar fecha y usos
                        if(isGiftcardAvailable.giftcard_remaining_uses > 0
                            && moment(isGiftcardAvailable.giftcard_available_date) > moment()
                        ){
                            canGo = true
                        }else{
                            canGo = false
                        }
                    }

                    if(
                        !giftcard_code.includes('FREE') && 
                        !giftcard_code.includes('EUFO') &&
                        !giftcard_code.includes('VENT') &&
                        !giftcard_code.includes('GIFT')
                    ){
                    
                        //jul - 9 / 2019
                        if(courType == 'free'){
                            let conf = window.confirm("La cortesía se aplicará como Todo Gratis, desea continuar?")
                            if(conf){
                                this.setState({
                                    booking : {
                                        ...booking,
                                        booking_amount : 0
                                    }
                                })
                                canGo = true
                            }
                        }

                        if(courType == '1-person'){
                            let conf = window.confirm("La cortesía se aplicará descontando 1 persona, desea continuar?")
                            if(conf){
                                this.setState({
                                    booking : {
                                        ...booking,
                                        booking_amount : (parseFloat(booking.booking_amount) - 200)
                                    }
                                })
                                canGo = true
                            }
                        }

                        if(courType == '2-person'){
                            let conf = window.confirm("La cortesía se aplicará descontando 2 personas, desea continuar?")
                            if(conf){
                                this.setState({
                                    booking : {
                                        ...booking,
                                        booking_amount : (parseFloat(booking.booking_amount) - 400)
                                    }
                                })
                                canGo = true
                            }
                        }

                        if(courType == "half-price"){
                            let conf = window.confirm("La cortesía se aplicará a la mitad del precio, desea continuar?")
                            if(conf){
                                this.setState({
                                    booking : {
                                        ...booking,
                                        booking_amount : (parseFloat(booking.booking_amount) / 2)
                                    }
                                })
                                canGo = true
                            }
                        }
                    }else{
                        canGo = true
                    }


                    if(canGo){
                        //console.log('with code')
                        
                            //tarjetas de regalo temporales
                            if(
                                giftcard_code.indexOf('VENT') > -1 ||
                                giftcard_code.indexOf('EUFO') > -1
                            ){
                                if(booking.players >= 4){
                                    let conf = window.confirm("La cortesía se aplicará para Jugadores de 4 ó más, desea continuar?")
                                    if(conf){
                        //              console.log('entro a la validación de solo VENT,EUFO y las normales',booking.players)
                                        if(booking.players == 4){
                                            this.setState({
                                                booking : {
                                                    ...booking,
                                                    booking_amount : (parseFloat(booking.booking_amount) - 260)
                                                }
                                            })
                            //                console.log('new amount',(parseFloat(booking.booking_amount) - 260))
                                        }
            
                                        if(booking.players == 5){
                                            this.setState({
                                                booking : {
                                                    ...booking,
                                                    booking_amount : (parseFloat(booking.booking_amount) - 240)
                                                }
                                            })
                                        }
            
                                        if(booking.players == 6){
                                            this.setState({
                                                booking : {
                                                    ...booking,
                                                    booking_amount : (parseFloat(booking.booking_amount) - 220)
                                                }
                                            })
                                        }

                                        if(booking.players >= 7){
                                            this.setState({
                                                booking : {
                                                    ...booking,
                                                    booking_amount : (parseFloat(booking.booking_amount) - 200)
                                                }
                                            })
                                        }
                                    }
                                }
                            }else if(
                                giftcard_code.indexOf('FREE') > -1 ||
                                giftcard_code.indexOf('GIFT') > -1 ){
                                    if(booking.players >= 4){
                                        let conf = window.confirm("La cortesía se aplicará, desea continuar?")
                                        if(conf){
                                            this.setState({
                                                booking : {
                                                    ...booking,
                                                    booking_amount : 0
                                                }
                                            })
                                        }
                                    }
                            }else{
                        //      console.log('no es valido')
                            }
                        
                        
                    }else{
                        //console.log('no code')
                        toast.warn('La cortesía ha caducado', {
                            position: "top-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                    }
                }
            }else{
                toast.warn('El código de la tarjeta es obligatorio', {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }

        }else{
            //console.log('all is no ok')
            toast.warn('Por favor seleccione el numero de jugadores / escriba el importe', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    updateDashboard(){
        //actualizar la información del dashboard con la fecha seleccionada y en el modo seleccionado
        const {fetchBookingClients,fetchBookings,fetchRooms} = this.props;
        const {selectedDate} = this.state
        fetchBookingClients();
        fetchBookings(moment(selectedDate).format('Y-MM-DD'));
        fetchRooms(1,moment(selectedDate).format('Y-MM-DD'));
        this.getWeeklyBookings();
        this.getMonthlyBookings();
    }

    render(){
        const {booking,showModal, showNestedModal, selectedDate, selectedHour, selectedRoom,formatedDate,viewType,currentDate,currentYear,showRoomModal,roomTurnsArr,isGiftcardAvailable} = this.state
        const {bookings:{loading,error,weeklyCollection,monthlyCollection},clients,account:{data}} = this.props
        const {rooms} = this.props

        let clientsSelect = [{
            label : '+ Agregar nuevo cliente',
            value : 'add',
            data : {}
        }]
        clients.collection.map((client,index) => {
            clientsSelect.push({
                value : client.client_id,
                label : client.client_razon_social,
                data : client
            });
        });

        //console.log(booking,'render')

        //console.log(booking.booking_amount,'here')

        /**<Hours 
                                                    data={room.schedules}
                                                    onClick={(e,hour,booking) => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            showModal : true, 
                                                            selectedHour : hour, 
                                                            selectedRoom : room.room_name,
                                                            selectedRoomId:room.room_id
                                                        })

                                                        if(booking){
                                                            this.setState({
                                                                booking : {
                                                                    ...booking,
                                                                    booked_at : moment(new Date()).format('Y-MM-DD')
                                                                }
                                                            })
                                                        }else{
                                                            this.resetBooking(hour,room.room_id,formatedDate)
                                                        }
                                                    }}
                                                /> */

        
        return(
            <div className="dashboard-content">
                <Container style={{backgroundColor:"#23262e"}}>
                    {loading && (<div><ActivityIndicator /></div>)}

                    <Row style={{alignItems:"center"}}>
                        <Col>
                            <Button onClick={() => this.updateDashboard()}>Actualizar información</Button>
                        </Col>
                        <Col>
                            <Row>
                                <Button style={{backgroundColor:"transparent",border:0}} onClick={(e) => this.filterByDate(selectedDate,'left')}><i className="fa fa-arrow-left"></i></Button>
                                <div>
                                    <Label style={{marginTop:20,color:"#ffffff"}} for="datepicker">{moment(selectedDate).format("LL")}</Label>
                                    <Input name="datepicker" id="datepicker" type="date" onChange={e => this.filterByDate(e.target.value,'center')} style={{
                                        opacity: 0,height: 10,padding: 0,margin: 0
                                    }} />
                                </div>
                                <Button style={{backgroundColor:"transparent",border:0}} onClick={(e) => this.filterByDate(selectedDate,'right')}><i className="fa fa-arrow-right"></i></Button>
                            </Row>
                        </Col>
                        <Col>
                            <ButtonGroup>
                                <Button style={{backgroundColor:viewType == "hours" ? "#4e555b" : "transparent",border:0}} onClick={() => this.setState({viewType : "hours"})}><i className="fa fa-calendar"></i></Button>
                                <Button style={{backgroundColor:viewType == "weeks" ? "#4e555b" : "transparent",border:0}} onClick={() => this.setState({viewType : "weeks"})}><i className="fa fa-calendar-week"></i></Button>
                                <Button style={{backgroundColor:viewType == "months" ? "#4e555b" : "transparent",border:0}} onClick={() => this.setState({viewType : "months"})}><i className="fa fa-globe-asia"></i></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    {viewType == "hours" && (
                        <div>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>Cuarto</th>
                                        <th>Horarios</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {rooms.collection && rooms.collection.length > 0 && rooms.collection.map((room,index) => (
                                    <tr key={`${index}-${room.room_id}`}>
                                        <td>{room.room_name} <br /><Button onClick={() => this.viewRoomHours(room.room_id)}>Horarios</Button></td>
                                        <td>
                                            <Row>
                                                
                                            {room.blocks && room.blocks.length > 0 && room.blocks.map((block,index) => {
                                                return block.hours && block.hours.length > 0 && block.hours.map((hour,i) => (
                                                    <Hour 
                                                        key={i}
                                                        hour={hour.label.substring(0,hour.label.length-3)}
                                                        onClick={() => this.showBooking(room.blocks,hour.label,room.room_id)}
                                                        status={hour.status ? hour.status : "available"}
                                                    />
                                                ))
                                            })}
                                            </Row>
                                        </td>
                                    </tr>
                                ))}
                                {/*collection && collection.length > 0 && collection.map((room,index) => (
                                    <tr key={`${index}-${room.room_id}`}>
                                        <td>{room.room_name} <Button onClick={() => this.viewRoomHours(room.room_id)}>Nuevo turno</Button></td>
                                        <td>
                                            <Hours 
                                                data={room.schedules}
                                                onClick={(e,hour,booking) => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        showModal : true, 
                                                        selectedHour : hour, 
                                                        selectedRoom : room.room_name,
                                                        selectedRoomId:room.room_id
                                                    })

                                                    if(booking){
                                                        this.setState({
                                                            booking : {
                                                                ...booking,
                                                                booked_at : moment(new Date()).format('Y-MM-DD')
                                                            }
                                                        })
                                                    }else{
                                                        this.resetBooking(hour,room.room_id,formatedDate)
                                                    }
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))*/}
                                </tbody>
                            </Table>

                        </div>
                    )}

                    {viewType == "weeks" && (
                        <Row>
                            <Col sm="2" style={{paddingRight:0}}>
                                <div className="dayCol"><a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        currentDate : moment(currentDate).subtract(10,'days').format('Y-MM-DD')
                                    });
                                    this.renderWeeks("bottom")
                                    setTimeout(() => this.getWeeklyBookings("bottom"),200)
                                }}>Semana anterior</a></div>
                                {this.renderWeeks("top")}
                                <div className="dayCol"><a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        currentDate : moment(currentDate).add(10,'days').format('Y-MM-DD')
                                    });
                                    this.renderWeeks("top")
                                    setTimeout(() => this.getWeeklyBookings("top"),200)
                                }}>Semana siguiente</a></div>
                            </Col>
                            <Col sm="8" className="table-wrap" style={{padding:0}}>
                                <Table borderless className="minimumWidth">
                                    <tr>
                                        {rooms && rooms.collection && rooms.collection.map((room,index) => (
                                            <th>{room.room_name}</th>
                                        ))}
                                    </tr>
                                    <tbody>
                                        {weeklyCollection && weeklyCollection.map((week,index) => (
                                            <tr key={index}>
                                            {week.rooms && week.rooms.length > 0 && week.rooms.map((r,index) => (
                                                <td key={index} className={r.total > 0 ? "green" : "red"}>{r.total}</td>
                                            ))}
                                            {week.rooms && week.rooms.length == 0 && (<td key={index} className="red">0</td>)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col sm="2" style={{paddingLeft:0}}>
                                <div className="dayCol"><span>Total</span></div>
                                {weeklyCollection && weeklyCollection.map((week,index) => (
                                    <div className="dayCol" key={index}>
                                        <span className={this.getTotalBookings("weekly",week,week.booked_at) > 0 ? "green" : "red"}>{this.getTotalBookings("weekly",week,week.booked_at)}</span>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    )}

                    {viewType == "months" && (
                        <Row>
                            <Col sm="2" style={{paddingRight:0}}>
                                <div className="dayCol"><a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        currentYear : moment(currentYear).subtract(1,'years').format('Y-MM-DD')
                                    });
                                    this.renderMonths("top")
                                    setTimeout(() => this.getMonthlyBookings("bottom"),200)
                                }}>Año anterior</a></div>
                                {this.renderMonths("top")}
                                <div className="dayCol"><a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        currentYear : moment(currentYear).add(1,'years').format('Y-MM-DD')
                                    });
                                    this.renderMonths("bottom")
                                    setTimeout(() => this.getMonthlyBookings("top"),200)
                                }}>Año siguiente</a></div>
                            </Col>
                            <Col sm="8" className="table-wrap" style={{padding:0}}>
                                <Table borderless className="minimumWidth">
                                    <tr>
                                        {rooms && rooms.collection && rooms.collection.map((room,index) => (
                                            <th>{room.room_name}</th>
                                        ))}
                                    </tr>
                                    <tbody>
                                        {monthlyCollection && monthlyCollection.map((month,index) => (
                                            <tr key={index}>
                                            {month.rooms && month.rooms.length > 0 && month.rooms.map((r,index) => (
                                                <td key={index} className={r.total == 0 ? "red" : "green"}>{r.total}</td>
                                            ))}
                                            {month.rooms && month.rooms.length == 0 && (<td key={index} className="red">0</td>)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col sm="2" style={{paddingLeft:0}}>
                                <div className="dayCol"><span>Total</span></div>
                                {monthlyCollection && monthlyCollection.map((week,index) => (
                                    <div className="dayCol" key={index}>
                                        <span className={this.getTotalBookings("monthly",week,week.booked_at) > 0 ? "green" : "red"}>{this.getTotalBookings("weekly",week,week.booked_at)}</span>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    )}
                </Container>

                <Modal
                    style={{minWidth:786}}
                    show={showRoomModal}
                    title={'Horarios del Cuarto'}
                    loading={loading}
                    onContinue={() => {
                        this.createRoomTurns()
                    }}
                    onCancel={() => {
                        this.setState({showRoomModal : false, payment : true, roomTurnsArr: []})
                    }}
                >
                    <div>
                        <Button onClick={()=>this.addNewTurn()}>Crear Horario</Button>

                        {roomTurnsArr && roomTurnsArr.length > 0 && roomTurnsArr.map((item,index) => (
                            <div key={item.turn_id} style={{marginTop:15}}>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Desde</Label>
                                            <Input type="date" defaultValue={item && item.from ? item.from : ""} placeholder="desde" onChange={this.handleDynamicInputChange("from",index)} />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Hasta</Label>
                                            <Input type="date" defaultValue={item && item.to ? item.to : ""} placeholder="hasta" onChange={this.handleDynamicInputChange("to",index)} />
                                        </FormGroup>
                                    </Col>
                                    <br />
                                </Row>
                                <Row>
                                    <Col>
                                        <Select required options={[
                                        { value : "09:00:00", label : "09:00" },
                                        { value : "09:15:00", label : "09:15" },
                                        { value : "09:30:00", label : "09:30" },
                                        { value : "10:00:00", label : "10:00" },
                                        { value : "10:15:00", label : "10:15" },
                                        { value : "10:30:00", label : "10:30" },
                                        { value : "10:45:00", label : "10:45" },
                                        { value : "11:00:00", label : "11:00" },
                                        { value : "11:15:00", label : "11:15" },
                                        { value : "11:30:00", label : "11:30" },
                                        { value : "11:45:00", label : "11:45" },
                                        { value : "12:00:00", label : "12:00" },
                                        { value : "12:15:00", label : "12:15" },
                                        { value : "12:30:00", label : "12:30" },
                                        { value : "13:00:00", label : "13:00" },
                                        { value : "13:15:00", label : "13:15" },
                                        { value : "13:30:00", label : "13:30" },
                                        { value : "13:45:00", label : "13:45" },
                                        { value : "14:00:00", label : "14:00" },
                                        { value : "14:15:00", label : "14:15" },
                                        { value : "14:30:00", label : "14:30" },
                                        { value : "14:45:00", label : "14:45" },
                                        { value : "15:00:00", label : "15:00" },
                                        { value : "15:15:00", label : "15:15" },
                                        { value : "15:30:00", label : "15:30" },
                                        { value : "15:45:00", label : "15:45" },
                                        { value : "16:00:00", label : "16:00" },
                                        { value : "16:15:00", label : "16:15" },
                                        { value : "16:30:00", label : "16:30" },
                                        { value : "16:45:00", label : "16:45" },
                                        { value : "17:00:00", label : "17:00" },
                                        { value : "17:15:00", label : "17:15" },
                                        { value : "17:30:00", label : "17:30" },
                                        { value : "17:45:00", label : "17:45" },
                                        { value : "18:00:00", label : "18:00" },
                                        { value : "18:15:00", label : "18:15" },
                                        { value : "18:30:00", label : "18:30" },
                                        { value : "18:45:00", label : "18:45" },
                                        { value : "19:00:00", label : "19:00" },
                                        { value : "19:15:00", label : "19:15" },
                                        { value : "19:30:00", label : "19:30" },
                                        { value : "19:45:00", label : "19:45" },
                                        { value : "20:00:00", label : "20:00" },
                                        { value : "20:15:00", label : "20:15" },
                                        { value : "20:30:00", label : "20:30" },
                                        { value : "20:45:00", label : "20:45" },
                                        { value : "21:00:00", label : "21:00" },
                                        { value : "21:15:00", label : "21:15" },
                                        { value : "21:30:00", label : "21:30" },
                                        { value : "21:45:00", label : "21:45" },
                                        { value : "22:00:00", label : "22:00" }
                                    ]}
                                    isMulti={true}
                                    placeholder={"Forma de pago"}
                                    isSearchable={true} 
                                    name="turn"
                                    isDisabled={false}
                                    value={item && item.hours && item.hours.length > 0 && item.hours.map((hour,index) => {
                                        if(hour){
                                            return {
                                                value : hour.value,
                                                label : hour.label.length > 5 ? hour.label.substring(0,hour.label.length-3) : hour.label 
                                            }
                                        }
                                    })}
                                    onChange={(option,t) => this.handleDynamicSelectInputChange("hours",index,option)}/>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => {
                                           this.setState({
                                                roomTurnsArr : roomTurnsArr.filter((l,index) => l.turn_id != item.turn_id)
                                            });
                                        }}><i className="fa fa-trash"></i></Button>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                </Modal>

                <Modal
                    style={{minWidth:768}}
                    show={showModal}
                    title={`Reservación | ${booking.booking_code}`}
                    loading={loading}
                    hasRemove={booking && booking.booking_id > 0}
                    error={error}
                    onRemove={() => {
                        const {deleteBooking} = this.props
                        let conf = window.confirm("¿Deseas eliminar esta reservación?")
                        if(conf){
                            deleteBooking(booking,data.user_name);
                            this.setState({showModal : false, payment : true})
                        }
                    }}
                    onContinue={() => {
                        const {createOrUpdateBooking} = this.props


                        if(booking){
                            if(booking.status != "blocked"){
                                if(booking.booked_at && booking.booked_at != ""
                                && booking.room_id && booking.room_d != ""
                                && booking.hour && booking.hour != ""
                                && booking.players && booking.players != ""
                                && booking.booking_name && booking.booking_name != ""
                                && booking.booking_email && booking.booking_email != ""
                                && booking.booking_phone && booking.booking_phone != ""){
                                    
                                    if(booking && booking.booking_cash_amount && booking.booking_cash_amount != ""){
                                        let newBookCashAmount = booking.booking_cash_amount.replace('$','').replace(',','')
                                        booking.booking_cash_amount = parseFloat(newBookCashAmount)
                                    }

                                    if(booking && booking.booking_terminal_amount && booking.booking_terminal_amount != ""){
                                        let newBookTerminalAmount = booking.booking_terminal_amount.replace('$','').replace(',','')
                                        booking.booking_terminal_amount = parseFloat(newBookTerminalAmount)
                                    }

                                    createOrUpdateBooking(booking,data.user_name);
                                    this.setState({showModal : false, payment: true})
                                }else{
                                    //aviso
                                    toast.warn('Verifique que todos los campos estén llenados', {
                                        position: "top-left",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true
                                    });
                                }
                            }else{
                                toast.warn('Se bloqueó un turno, no es necesario llenar información', {
                                    position: "top-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true
                                });
                                createOrUpdateBooking(booking,data.user_name);
                                this.setState({showModal : false, payment: true})
                            }
                        }else{
                            //aviso
                            toast.warn('Verifique que todos los campos estén llenados', {
                                position: "top-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                        }

                        
                        
                    }}
                    onCancel={() => {
                        this.setState({showModal : false, payment : true})
                        this.resetBooking(null,null,null)
                    }}
                    anotherOne={
                        <div>
                            <CustomInput checked={booking && booking.status == "blocked" ? true : false} name="bloqueado" id="bloqueado" type="switch" label="Hora bloqueada" 
                                onChange={e => this.blockBookingHour(e)}
                            />
                        </div>
                    }
                >
                    <Container>
                        <Form>
                            <Row form>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>Fecha : {moment(selectedDate).format("LL")}</Label>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{textAlign:"center",display:"block"}}>Cuarto : {selectedRoom}</Label>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{textAlign:"right",display:"block"}}>Hora : {selectedHour} Hrs.</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Jugadores</Label>
                                        <Input type="select" value={booking && booking.players ? booking.players : 0}
                                        onChange={this.handleInputChange({field:"players"})}>
                                            <option value="0">-Elija una opción-</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input type="text" value={booking && booking.booking_name != "" ? booking.booking_name : ""} 
                                        onChange={this.handleInputChange({field:"booking_name"})}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input type="email" value={booking && booking.booking_email != "" ? booking.booking_email : ""} 
                                        onChange={this.handleInputChange({field:"booking_email"})}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Teléfono</Label>
                                        <Input type="text" value={booking && booking.booking_phone != "" ? booking.booking_phone : ""} 
                                        onChange={this.handleInputChange({field:"booking_phone"})}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Importe</Label>
                                        <InputGroup>
                                            <Input type="text" value={booking && booking.booking_amount != "" ? booking.booking_amount : ""} 
                                            onChange={this.handleInputChange({field:"booking_amount"})}/>
                                            <InputGroupAddon addonType="append">MXN</InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>&nbsp;</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <div>
                                            <CustomInput checked={booking && booking.booking_payed == 0 ? true : false} name="no_pagado" id="no_pagado" type="switch" label="NO PAGADO"
                                                onChange={e => {
                                                    this.setState({
                                                        booking : {
                                                            ...booking,
                                                            booking_payed : booking.booking_payed == 0 ? 1 : 0,
                                                            booking_invoice_needed : 0,
                                                            booking_cash_checked : 0,
                                                            booking_terminal_checked : 0
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Efectivo</Label>
                                        <Row>
                                            <Col md="2">
                                                <div>
                                                    <CustomInput checked={booking && booking.booking_cash_checked == 0 ? false : true} disabled={booking && booking.booking_payed == 0 ? true : false} name="efectivo" id="efectivo" type="switch"
                                                        onChange={e => {
                                                            this.setState({
                                                                booking : {
                                                                    ...booking,
                                                                    booking_cash_checked : booking.booking_cash_checked == 0 ? 1 : 0
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="10">
                                                {/*<Input type="text" value={booking && booking.booking_cash_amount != "" ? formatter.format(booking.booking_cash_amount) : ""} disabled={booking && booking.booking_cash_checked == 0 ? true : false} 
                                                onChange={this.handleInputChange({field:"booking_cash_amount"})}/>*/}
                                                <CurrencyInput 
                                                    prefix={"$"} 
                                                    decimalSeparator="." 
                                                    thousandSeparator=","
                                                    precision="2"
                                                    className="form-control"
                                                    disabled={booking && booking.booking_cash_checked == 0 ? true : false} 
                                                    value={booking && booking.booking_cash_amount != "" ? booking.booking_cash_amount : ""}
                                                    onChangeEvent={this.handleInputChange({field:"booking_cash_amount"})} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Terminal</Label>
                                        <Row>
                                            <Col md="2">
                                                <div>
                                                    <CustomInput checked={booking && booking.booking_terminal_checked == 0 ? false : true} disabled={booking && booking.booking_payed == 0 ? true : false} name="terminal" id="terminal" type="switch" 
                                                        onChange={e => {
                                                            this.setState({
                                                                booking : {
                                                                    ...booking,
                                                                    booking_terminal_checked : booking.booking_terminal_checked == 0 ? 1 : 0
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="10">
                                                {/*<Input type="text" value={booking && booking.booking_terminal_amount != "" ? formatter.format(booking.booking_terminal_amount) : ""} disabled={booking && booking.booking_terminal_checked == 0 ? true : false} 
                                                onChange={this.handleInputChange({field:"booking_terminal_amount"})}/>*/}
                                                <CurrencyInput 
                                                    prefix={"$"} 
                                                    decimalSeparator="." 
                                                    thousandSeparator=","
                                                    precision="2"
                                                    className="form-control"
                                                    disabled={booking && booking.booking_terminal_checked == 0 ? true : false} 
                                                    value={booking && booking.booking_terminal_amount != "" ? booking.booking_terminal_amount : ""}
                                                    onChangeEvent={this.handleInputChange({field:"booking_terminal_amount"})} />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Factura</Label><br></br>
                                        <Row>
                                            <Col md="2">
                                                <div>
                                                    <CustomInput checked={booking && booking.booking_invoice_needed == 0 ?  false : true} disabled={booking && booking.booking_payed == 0 ? true : false} name="factura" id="factura" type="switch"
                                                        onChange={e => {
                                                            this.setState({
                                                                booking : {
                                                                    ...booking,
                                                                    booking_invoice_needed : booking.booking_invoice_needed == 0 ? 1 : 0
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="10">
                                                <Button onClick={e => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        showNestedModal : true
                                                    })
                                                }} color="secondary" disabled={booking && booking.booking_invoice_needed == 0 ? true : false}>Datos fiscales</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Giftcard / Cortesía</Label>
                                        <InputGroup>
                                            <Input defaultValue={booking && booking.booking_courtesy_id != "" ? booking.booking_courtesy_id : ""} type="text" onChange={(evt) => {
                                                this.checkGiftcard(evt.target.value);
                                            }} {...(isGiftcardAvailable ? {valid: true} : {invalid:true})} />
                                            <FormFeedback {...(isGiftcardAvailable ? {valid: true} : {invalid:true})}>{isGiftcardAvailable ? "Cortesía disponible" : "Cortesía no disponible"}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Container>


                    <Modal
                        show={showNestedModal}
                        onContinue={() => {
                            this.setState({showNestedModal : false})
                        }}
                        hasRemove={booking.client.client_id}
                        onRemove={() => {
                            this.setState({
                                booking :{
                                    ...booking,
                                    client : {}
                                }
                            })
                        }}
                        onCancel={() => {
                            this.setState({showNestedModal : false})
                        }}
                        title={"Datos fiscales"}
                        anotherOne={
                            <div>
                                <CustomInput 
                                    checked={booking && booking.booking_invoice_sent == 0 ? false : true} 
                                    name="factura_enviada" 
                                    id="factura_enviada" 
                                    type="switch" 
                                    label="Factura enviada" 
                                    onChange={e => {
                                        this.setState({
                                            booking : {
                                                ...booking,
                                                booking_invoice_sent : booking.booking_invoice_sent == 0 ? 1 : 0
                                            }
                                        })
                                    }}
                                />
                            </div>
                        }
                    >

                        <FormGroup>
                            <Select 
                            required 
                            options={clientsSelect}
                            placeholder={"Buscar cliente"}
                            isSearchable={true} 
                            name="capable_invoice_cliente"
                            value={booking && booking.client ? {
                                value : booking.client.client_id,
                                label : booking.client.client_razon_social,
                                data :  booking.client
                            } : {}}
                            onChange={(option,t) => {
                                if(option.value == 'add'){
                                    this.setState({
                                        booking : {
                                            ...booking,
                                            client : {}
                                        }
                                    })
                                }else{
                                    this.setState({
                                        booking : {
                                            ...booking,
                                            client : {
                                                ...option.data
                                            }
                                        }
                                    })
                                }
                            }}/>
                        </FormGroup>
                        
                        <FormGroup>
                            <Row>
                                <Col md="3"><Label>R.F.C</Label></Col>
                                <Col md="9">
                                    <Input type="text" value={booking && booking.client && booking.client.client_rfc ? booking.client.client_rfc : ""}
                                    onChange={e => {
                                        this.setState({
                                            booking :{
                                                ...booking,
                                                client : {
                                                    ...booking.client,
                                                    client_rfc : e.target.value
                                                }
                                            }
                                        })
                                    }} />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col md="3"><Label>Razón Social</Label></Col>
                                <Col md="9"><Input type="text" value={booking && booking.client && booking.client.client_razon_social ? booking.client.client_razon_social : ""}
                                    onChange={e => {
                                        this.setState({
                                            booking :{
                                                ...booking,
                                                client : {
                                                    ...booking.client,
                                                    client_razon_social : e.target.value
                                                }
                                            }
                                        })
                                    }} /></Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col md="3"><Label>Dirección fiscal</Label></Col>
                                <Col md="9"><Input type="text" value={booking && booking.client && booking.client.client_address ? booking.client.client_address : ""}
                                    onChange={e => {
                                        this.setState({
                                            booking :{
                                                ...booking,
                                                client : {
                                                    ...booking.client,
                                                    client_address : e.target.value
                                                }
                                            }
                                        })
                                    }} /></Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label>Email contacto</Label>
                                    <Input type="email" value={booking && booking.client && booking.client.client_email ? booking.client.client_email : ""}
                                    onChange={e => {
                                        this.setState({
                                            booking :{
                                                ...booking,
                                                client : {
                                                    ...booking.client,
                                                    client_email : e.target.value
                                                }
                                            }
                                        })
                                    }} />
                                </Col>
                                <Col>
                                    <Label>Teléfono</Label>
                                    <Input type="text" value={booking && booking.client && booking.client.client_phone ? booking.client.client_phone : ""}
                                    onChange={e => {
                                        this.setState({
                                            booking :{
                                                ...booking,
                                                client : {
                                                    ...booking.client,
                                                    client_phone : e.target.value
                                                }
                                            }
                                        })
                                    }} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col>
                                <Label>Forma de pago</Label>
                                <Select required options={[
                                    { value : 1, label : "Efectivo" },
                                    { value : 2, label : "Cheque nominativo" },
                                    { value : 3, label : "Transferencia electrónica de fondos" },
                                    { value : 4, label : "Tarjeta de Crédito" },
                                    { value : 28, label : "Tarjeta de Débito" },
                                    { value : 99, label : "Por definir" },
                                ]}
                                placeholder={"Forma de pago"}
                                isSearchable={true} 
                                value={booking && booking.booking_payment_type ? {
                                    value : booking.booking_payment_type,
                                    label : tipoPagoUSe[booking.booking_payment_type]
                                } : 0}
                                name="payment_type"
                                isDisabled={false}
                                onChange={(option,t) => {
                                    this.setState({
                                        booking : {
                                            ...booking,
                                            booking_payment_type : option.value
                                        }
                                    })
                                }}/>    
                                </Col>
                                <Col>
                                    <Label>Uso de CFDI</Label>
                                    <Select 
                                    required 
                                    options={Object.keys(cfdiUse).map((item,index) => {
                                        return {
                                        value : item,
                                        label : cfdiUse[index+1]
                                        }
                                    })}
                                    placeholder={"Uso de CFDI"}
                                    isSearchable={true} 
                                    value={booking && booking.booking_cfdi_use && booking.booking_cfdi_use > 0 ? {
                                        value : booking.booking_cfdi_use,
                                        label : cfdiUse[booking.booking_cfdi_use]
                                    } : 0}
                                    name="cfdi_use"
                                    isDisabled={false}
                                    onChange={(option,t) => {
                                        this.setState({
                                            booking : {
                                                ...booking,
                                                booking_cfdi_use : option.value
                                            }
                                        })
                                    }}/> 
                                </Col>
                            </Row>
                            {/*https://contadorcontado.com/2016/07/14/catalogo-formas-pago-cfdi/*/}
                             
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label>Factura #</Label>
                                    <Input type="text" defaultValue={booking && booking.invoice_number != "" ? booking.invoice_number : ""}
                                    onChange={e => {
                                        this.setState({
                                            booking :{
                                                ...booking,
                                                invoice_number : e.target.value
                                            }
                                        })
                                    }} />
                                </Col>
                                <Col>
                                    <Label>Notas</Label>
                                    <Input type="text" defaultValue={booking && booking.booking_notes != "" ? booking.booking_notes : ""}
                                    onChange={e => {
                                        this.setState({
                                            booking :{
                                                ...booking,
                                                booking_notes : e.target.value
                                            }
                                        })
                                    }} />
                                </Col>
                            </Row>
                        </FormGroup>                    
                    </Modal>

                </Modal>

                <ToastContainer />
            </div>
        )
    }
}

export default Dashboard;