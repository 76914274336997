import { SectionsApi } from '../api'

export const FETCH_SECTION_SUCCESS = "FETCH_SECTION_SUCCESS"
export const FETCH_SECTION_ERROR = "FETCH_SECTION_ERROR"
export const FETCH_REQUEST_SECTION = "FETCH_REQUEST_SECTION"

export function saveSection(section_id,values){
    return function(dispatch){
        dispatch(fetchSectionsRequest())

        return SectionsApi.post({...values},`save/${section_id}`)
        .then(response => {
            if(response.response && response.result){
                dispatch(fetchSections(1))
            }else{
                dispatch(fetchSectionsError("CANT_SAVE_SECTION"))
            }
        })
        .catch(error => dispatch(fetchSectionsError(error)))
    }
}

export function fetchSections(page){
    return function(dispatch){
        dispatch(fetchSectionsRequest())

        return SectionsApi.get(`getAll/${page}`)
        .then(response => {
            if(response.response && response.result){
                dispatch(fetchSectionsSuccess(
                    response.result,
                    response.total_pages,
                    response.total_rows
                ))
            }else{
                dispatch(fetchSectionsError("CANT_GET_SECTIONS"))
            }
        })
        .catch(error => dispatch(fetchSectionsError(error)))
    }
}

function fetchSectionsSuccess(collection){
    return {
        type : FETCH_SECTION_SUCCESS,
        collection
    }
}

function fetchSectionsRequest(){
    return {
        type : FETCH_REQUEST_SECTION
    }
}

function fetchSectionsError(error){
    return {
        type : FETCH_SECTION_ERROR,
        error
    }
}