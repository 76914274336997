import React,{Component} from 'react';
import {FormWidget,ActivityIndicator} from '../components';
import {Container} from 'reactstrap';
import BigCalendar from 'react-big-calendar-like-google';
import 'react-big-calendar-like-google/lib/css/react-big-calendar.css';
import moment from 'moment';
moment.locale('es')

const localizer = BigCalendar.momentLocalizer(moment);

class Agenda extends Component{

    componentDidMount(){
        const {fetchSchedules} = this.props;
        fetchSchedules();
    }

    render(){
        const {agenda:{collection,error,loading}} = this.props;

        let eventList = collection && collection.map((event,index) => {
            //let start = 
            return {
                title: event.title + ' | ' + event.description,
                start: new Date(`${event.schedule_at} ${event.schedule_at_time}`),//moment(`${event.schedule_at} ${event.schedule_at_time}`).format("YYYY-MM-DD HH:mm:ss"),
                end: new Date(`${event.end_at} ${event.end_at_time}`),//event.schedule_at,
                allDay: parseInt(event.allDay),
                desc : event.description
                //resource: event.description,
            }
        })

        return(
            <div className="dashboard-content">
                <Container>
                    <FormWidget 
                        title="Agenda"
                        headerColor="#215dff"
                        icon=""
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        render={
                            <div>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                    <BigCalendar
                                        style={{backgroundColor:"white",padding:20}}
                                        defaultView={"agenda"}
                                        localizer={localizer}
                                        events={eventList}
                                        startAccessor="start"
                                        endAccessor="end"
                                    />
                                )}
                            </div>
                        }
                    />
                </Container>
            </div>
        )
    }
}

export default Agenda;