import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import { HashRouter as Router, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from "./store";
import {
    Login,
    DashboardLayout
} from './pages'
import {Main} from './components'

const LoginComponent = () => {
    return(
        <div className="login-wrap">
            <div className="login-overlay"></div>
            <Login />
        </div>
    )
}

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>     
                    <div id="router" style={{display:"flex",flex:1}}>        
                        <Route exact path="/" component={LoginComponent} />
                        <Route path="/dashboard" component={DashboardLayout} />
                    </div>
                </Router>
            </Provider>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();

