import {ProfilesApi} from '../api'

export const FETCH_MODULE_REQUEST = "FETCH_MODULE_REQUEST"
export const FETCH_MODULE_SUCCESS = "FETCH_MODULE_SUCCESS"
export const FETCH_MODULE_ERROR = "FETCH_MODULE_ERROR"

export function fetchModules(){
    return function(dispatch){
        dispatch(fetchModulesRequest())
        return ProfilesApi.get('modules')
        .then(response => {
            if(response.response){
                dispatch(fetchModulesSuccess(response.result))
            }else{
                dispatch(fetchModulesError("NO_RESULTS"))
            }
        }).catch(error => dispatch(fetchModulesError(error)))
    }
}

function fetchModulesRequest(){
    return {
        type : FETCH_MODULE_REQUEST
    }
}

function fetchModulesSuccess(modules){
    return {
        type : FETCH_MODULE_SUCCESS,
        modules
    }
}

function fetchModulesError(error){
    return {
        type : FETCH_MODULE_ERROR,
        error
    }
}