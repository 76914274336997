import {products} from '../actions'

export default function(state = {}, action) {

    switch(action.type){
        case products.FETCH_PRODUCTS_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case products.FETCH_PRODUCTS_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case products.FETCH_PRODUCTS_SUCCESS : {
            return {
                ...state,
                loading : false,
                collection : action.products
            }
        }

        default:
            return state;
    }
}