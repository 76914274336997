import {sections} from '../actions'

export default function(state = {}, action) {

    switch(action.type){

        case sections.FETCH_SECTION_SUCCESS:
            return {
                ...state,
                loading : false,
                error : null,
                collection : action.collection
            }

        case sections.FETCH_REQUEST_SECTION:
            return {
                ...state,
                loading : true,
                error : null
            }

        default:
            return state;
    }
}