import React from 'react'

const Tabs = ({ active, items, onChangeTab, returnableValue }) => {
    return(
        <div>
            {items && items.length > 0 && items.map((tab,index) => (
                <a href="#" className={`tab ${active == index ? 'active' : ''}`} key={index} onClick={(e) => {
                    e.preventDefault()
                    onChangeTab(tab[returnableValue]);
                }}>{tab.name}</a>
            ))}
        </div>
    )
}

export default Tabs