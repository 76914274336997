import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {sections} from '../actions';
import Sections from '../pages/Sections';

export default connect(
    (state) => ({
        sections: state.sections
    }),
    (dispatch) => bindActionCreators({
        ...sections
    }, dispatch)
)(Sections);
