import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
        const account = require('../store').default.getState().account
        if (!account.data.user_id) return (<Redirect to={{pathname: "/", state: { from: props.location }}}/>)
        /*if (session.modules.length > 0) {
            const path = props.location.pathname.split('/')[1]
            const index = session.modules.findIndex(module => module.get('name') === path)
            if (index === -1) {
            // const module = session.modules[0].get('name')
            return (<Redirect to={{pathname: `/resume`, state: { from: props.location }}}/>)
            } else {
            return (<Component {...props} />)
            }
        }*/
        if(account && account.data && account.data.user_id){
            return (<Component {...props} />)
        }
        return null
      //return (<Component {...props} />)
    }}
  />
)

export default PrivateRoute