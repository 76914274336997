import React,{Component} from 'react'
import {
    Container, Col, Form,
    FormGroup, Label, Input,
    Button,InputGroup, InputGroupAddon,Row
  } from 'reactstrap';
import { Transition, animated } from 'react-spring'

class Login extends Component{

    constructor(){
        super();

        this.state = {
            show : true,
            user : {},
            passwordField : "password"
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.account.data !== this.props.account.data && nextProps.account.data){
            window.location.href = '/#/dashboard/home'
            //window.history.pushState('', '', './#/dashboard/home');
            //this.context.router.push("dashboard")
        }
    }

    submitForm(e){
        const {loginUser} = this.props
        const {user} = this.state
        e.preventDefault();

        loginUser(user)
    }

    handleInputChange = ({field}) => evt => {        
        this.setState({ user: {
            ...this.state.user,
            [field] : evt.target.value
        }});
    }

    render(){
        const {show,passwordField} = this.state;
        const {account:{data,loading,error}} = this.props;
        return(
            <div className="login-main">
            <div className="reveals-main">
            <Transition
                native
                items={show}
                from={{ position: 'absolute', top : '0%' }}
                enter={[{ top : '50%' }]}
                leave={{ height: 0 }}>
                {show =>
                    show && (props => <animated.div style={props}>
                        <Container className="login-form-container">
                            <Form className="login-form" onSubmit={ (e) => this.submitForm(e) }>

                                <div className="body">
                                    <Row>
                                        <Col className="login-form-background"></Col>
                                        <Col style={{padding:60}}>
                                            <img src="images/brand.png" style={{
                                                marginLeft:12,
                                                marginBottom:15
                                            }} />
                                            <Col>
                                                <FormGroup>
                                                <Label>Correo electrónico</Label>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    id="exampleEmail"
                                                    placeholder="miemail@email.com"
                                                    onChange={this.handleInputChange({field:"user_email"})}
                                                />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                <Label for="examplePassword">Contraseña</Label>
                                                <InputGroup>
                                                    <Input type={passwordField} onChange={this.handleInputChange({field:"user_password"})} />
                                                    <InputGroupAddon addonType="append"><Button color="secondary" onClick={(e) => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            passwordField : this.state.passwordField == "text" ? "password" : "text"
                                                        })
                                                    }}><i className="fa fa-eye"></i></Button></InputGroupAddon>
                                                </InputGroup>
                                                </FormGroup>
                                                <Button 
                                                    className="submit"
                                                    color="secondary"
                                                    style={{width:"100%"}}
                                                    disabled={loading ? true : false}
                                                >
                                                    {!loading && ('Iniciar Sesión')}
                                                    {loading && ('Espere por favor ...')}
                                                </Button>
                                                {!loading && error && (
                                                    <div className="alert alert-danger" style={{marginTop:15}}>
                                                        <span>{error.toString()}</span>
                                                    </div>
                                                )}
                                            </Col>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </Container>   
                    </animated.div>)
                }
            </Transition>
            </div>
            </div>
        )
    }
}

export default Login