import {sales} from '../actions'
export default function(state = {}, action) {

    switch(action.type){
        case sales.FETCH_SALES_REQUEST:
            return {
                ...state,
                loading : true,
                error : null
            }

        case sales.FETCH_SALES_ERROR:
            return {
                ...state,
                loading : false,
                error: action.error
            }

        case sales.FETCH_SALES_SUCCESS : 
            return {
                ...state,
                loading : false,
                error : null,
                collection : action.sales,
                count : action.count,
                pages : action.pages
            }

        case "ACCOUNT_LOGOUT":{
            return {
                loading : false,
                collection : [],
                error : null,
                count : 0,
                pages : 0
            }
        }

        default:
            return state;
    }
}