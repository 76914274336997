import React,{Component} from 'react'
import ReactExport from "react-export-excel";
import { Row, Col, Container, Button, Table,ButtonGroup,InputGroup,InputGroupAddon,Input } from 'reactstrap';
import {FormWidget,ActivityIndicator,PaginationView,Modal} from '../components';

import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
moment.locale('es')

const actions = {
    'create' : "Creó",
    'update' : "Actualizó",
    'delete' : "Eliminó"
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//https://github.com/rdcalle/react-export-excel#readme
//value={(col) => col.is_married ? "Married" : "Single"}
const DownloadExcel = (props) => {
    return(
        <ExcelFile element={<Button>Exportar a Excel</Button>} filename={"backup-file"}>
            <ExcelSheet data={props.data} name="Respaldo">
                <ExcelColumn label="Módulo" value="module_name"/>
                <ExcelColumn label="Fecha" value={(col) => moment(col.created_at).format('DD[-]MMM[-]YY')} />
                <ExcelColumn label="Hora" value="created_at_time"/>
                <ExcelColumn label="Hecho por" value="created_by" />
                <ExcelColumn label="Solicitud" value={(col) => actions[col.request_type] }/>
            </ExcelSheet>
        </ExcelFile>
    )
}

class Backup extends Component{

    state = {
        module_id : 0,
        modal : false,
        data : null,
        currentPage : 1,
        search : false,
        currentModule : 0,
        currentDate : moment().format('YYYY-MM-DD'),
        currentUser : 0
    }

    componentDidMount(){
        const {fetchBackup,fetchRooms, fetchModules, fetchUsers} = this.props
        fetchBackup(0,moment().format('YYYY-MM-DD'),'0','0')
        fetchRooms(0,undefined)
        fetchModules()
        fetchUsers()
    }

    toggleModal(data,module_id){
        this.setState({modal:true,module_id,data:JSON.parse(data)})
    }

    searchBackup(){
        const {currentDate,currentModule,currentUser} = this.state
        const {fetchBackup} = this.props
        let newDate = "";
        if(currentDate == ""){
            newDate = moment().format('YYYY-MM-DD')
        }else{
            newDate = currentDate
        }

        fetchBackup(0,newDate,currentModule,currentUser)

        this.setState({ search : true })
    }

    render(){
        const {
            rooms,
            backup:{loading,error,collection,pages,count},
            fetchBackup,
            fetchRooms,
            users,
            modules
        } = this.props
        const {currentPage, currentDate, currentModule, currentUser, modal, data, module_id, search} = this.state

        //console.log(modal,data,'here')

        return(
            <div className="dashboard-content">
                <Container>
                <FormWidget 
                        title="Acciones de los usuarios"
                        headerColor="#f5b13f"
                        icon=""
                        onClick={(e) => {
                            e.preventDefault();
                            /*this.toogleForm();*/
                        }}
                        render={
                            <div>
                                <Row>
                                    <Col sm="10">
                                        <p style={{color:"white"}}>Viendo {collection.length} de {count}</p>
                                    </Col>
                                    <Col sm="2">
                                        <DownloadExcel data={collection} />
                                    </Col>
                                </Row>
                                <Row>
                                        <Col sm="4">
                                            <InputGroup>
                                                <InputGroupAddon addonType="append">
                                                    <Button onClick={this.searchClient}><i className="fa fa-calendar"></i></Button>
                                                </InputGroupAddon>
                                                <Input type="date" placeholder="Elija una fecha" onChange={e => this.setState({currentDate : e.target.value})} />
                                            </InputGroup>
                                        </Col>
                                        <Col sm="3">
                                            <Input type="select" onChange={e => {
                                                this.setState({
                                                    currentUser : e.target.value
                                                })
                                            }}>
                                                <option value="0">- Elija un usuario -</option>
                                                {users && users.collection && users.collection.map((user,index) => (
                                                    <option key={index} value={user.user_name}>{user.user_name}</option>
                                                ))}
                                            </Input>
                                        </Col>
                                        <Col sm="3">
                                            <Input type="select" onChange={(e) => {
                                                this.setState({
                                                    currentModule : e.target.value
                                                })
                                            }}>
                                                <option value="0">- Elija un módulo -</option>
                                                {modules && modules.collection && modules.collection.map((_module,index) => (
                                                    <option key={index} value={_module.module_id}>{_module.module_name}</option>
                                                ))}
                                            </Input>
                                        </Col>
                                        <Col sm="2">
                                            <Button onClick={() => this.searchBackup()}>Buscar</Button>
                                        </Col>
                                    </Row>
                                {error && !loading && (<span>{error.toString()}</span>)}
                                {loading && collection && collection.length == 0 && (<div className="activity-wrap"><ActivityIndicator /></div>)}
                                {!loading && !error && collection && collection.length > 0 && (
                                <div>
                                <Table borderless>
                                    <thead>
                                    <tr>
                                        <th>Usuario</th>
                                        <th>Acción</th>
                                        <th>Módulo</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Detalles</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {collection && collection.length > 0 && collection.map((backup,index) => (
                                        <tr key={backup.backup_id}>
                                            <td>{backup.created_by}</td>
                                            <td>{actions[backup.request_type]}</td>
                                            <td>{backup.module_name}</td>
                                            <td>{moment(backup.created_at).format('LL')}</td>
                                            <td>{backup.created_at_time}</td>
                                            {<td>
                                                <ButtonGroup>
                                                    <Button onClick={(e) => {
                                                        e.preventDefault();
                                                        this.toggleModal(backup.module_data,backup.module_id);
                                                    }}>
                                                        <i className="fa fa-eye"></i>
                                                    </Button>
                                                </ButtonGroup>
                                            </td>}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table></div>)}
                            </div>
                        }
                        maxHeight={430}
                    />
                    <PaginationView 
                        pages={pages}
                        count={count}
                        active={currentPage}
                        onPageChange={page => {
                            this.setState({currentPage : page})
                            if(search){
                                fetchBackup(page,moment().format('YYYY-MM-DD'),0,0)
                            }else{
                                fetchBackup(page,moment(currentDate).format('YYYY-MM-DD'),currentModule,currentUser)
                            }
                            
                            fetchRooms(0,undefined)
                        }}
                        style={{flexWrap: "wrap"}}
                    />
                </Container>

                <Modal
                    style={{minWidth:786}}
                    show={modal}
                    title={'Detalles de la acción'}
                    loading={loading}
                    onContinue={() => {
                        this.setState({modal:false,data:null})
                    }}
                    onCancel={() => {
                        this.setState({modal:false,data:null})
                    }}
                >
                    <div>
                        {module_id == 1 && !!data && rooms.collection && rooms.collection.length > 0 && (
                            <div>
                                <p><strong>Fecha de reserva </strong>: {moment(data.booked_at).format("LL")}</p>
                                {data.room_id && (
                                    <p><strong>Cuarto</strong> : {rooms.collection.find(item => item.room_id == data.room_id ).room_name}</p>
                                )}
                                <p><strong>Status</strong> : {data.booking_status}</p>
                                <p><strong>Hora</strong> : {data.hour}</p>
                                <p><strong>Jugadores</strong> : {data.players}</p>
                                <p><strong>Cliente</strong> : {data.booking_name}</p>
                                <p><strong>Email</strong> : {data.booking_email}</p>
                                <p><strong>Precio</strong> : {data.booking_amount}</p>
                                <p><strong>Efectivo</strong> : {data.booking_cash_amount}</p>
                                <p><strong>Terminal</strong> : {data.booking_terminal_amount}</p>
                                <p><strong>Cortesía</strong> : {data.booking_courtesy_id}</p>
                                <p><strong>Notas</strong> : {data.booking_notes}</p>
                            </div>
                        )}

                        {module_id == 2 && !!data && (
                            <div>
                                <p><strong>Nombre </strong>: {data.user_name}</p>
                                <p><strong>Email</strong> : {data.user_email}</p>
                                <p><strong>Status</strong> : {data.user_active ? "Activo" : "Desactivado"}</p>
                            </div>
                        )}

                        {module_id == 4 && !!data && (
                            <div>
                                <p><strong>Nombre </strong>: {data.profile_name}</p>
                            </div>
                        )}

                        {module_id == 5 && !!data && (
                            <div>
                                <p><strong>Razón social </strong>: {data.client_razon_social}</p>
                                <p><strong>Teléfono </strong>: {data.client_phone}</p>
                                <p><strong>Email </strong>: {data.client_email}</p>
                                <p><strong>RFC </strong>: {data.client_rfc}</p>
                                <p><strong>Dirección </strong>: {data.client_address}</p>
                            </div>
                        )}

                        {module_id == 6 && !!data && (
                            <div>
                                <p><strong>Nombre </strong>: {data.room_name}</p>
                                <p><strong>Descripción </strong>: {data.room_description}</p>
                                <p><strong>Subtítulo </strong>: {data.room_subtitle}</p>
                                <p><strong>Dificultad </strong>: {data.room_dificult}</p>
                                <p><strong>Estrellas </strong>: {data.room_stars}</p>
                                <p><strong>Título inferior </strong>: {data.room_bottom_title}</p>
                                <p><strong>Jugadores </strong>: {data.room_players}</p>
                                <p><strong>Status </strong>: {data.room_status}</p>
                                <p><strong>Imagen </strong>: <img width="200" src={data.room_image} /></p>
                            </div>
                        )}

                        {module_id == 8 && !!data && (
                            <div>
                                <p><strong>Código </strong>: {data.giftcard_code}</p>
                                <p><strong>Tipo </strong>: {data.giftcard_type}</p>
                                <p><strong>Status </strong>: {data.giftcard_available ? "Disponible" : "Desactivada" }</p>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Backup