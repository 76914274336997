import {ProfilesApi} from '../api'

export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST"
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS"
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR"

export function createOrUpdateProfile(profile,request_by){
    return function(dispatch){
        dispatch(fetchProfilesRequest())
        return ProfilesApi.post({
            ...profile,
            request_by
        },'save')
        .then(response => {
            if(response.response){
                dispatch(fetchProfiles(1))
            }else{
                dispatch(fetchProfilesError("CANT_CRUD_PROFILE"))
            }
        })
    }
}

export function deleteProfile(profile_id,request_by){
    return function(dispatch){
        dispatch(fetchProfilesRequest())
        return ProfilesApi.post({
            profile_id,
            request_by
        },`delete`)
        .then(response => {
            if(response.response){
                dispatch(fetchProfiles(1))
            }else{
                dispatch(fetchProfilesError("CANT_REMOVE_PROFILE"))
            }
        })
    }
}

export function fetchProfiles(page){
    return function(dispatch){
        dispatch(fetchProfilesRequest())
        return ProfilesApi.get(`getAll/${page}`)
        .then(response => {
            if(response.response){
                dispatch(fetchProfilesSuccess(
                    response.result,
                    response.pages,
                    response.count
                ))
            }else{
                dispatch(fetchProfilesError("CANT_GET_PROFILES"))
            }
        })
    }
}

function fetchProfilesRequest(){
    return {
        type : FETCH_PROFILE_REQUEST
    }
}

function fetchProfilesSuccess(profiles,pages,count){
    return {
        type : FETCH_PROFILE_SUCCESS,
        profiles,
        pages,
        count
    }
}

function fetchProfilesError(error){
    return {
        type : FETCH_PROFILE_ERROR,
        error
    }
}