import { faqs } from '../actions'

export default (state = {},action) => {
    switch(action.type){
        case faqs.FETCH_FAQS_ERROR:
            return {
                ...state,
                loading : false,
                error : action.error
            }

        case faqs.FETCH_FAQS_REQUEST:
            return {
                ...state,
                loading : true
            }

        case faqs.FETCH_FAQS_SUCCESS:
            return {
                ...state,
                loading : false,
                error : null,
                collection : action.faqs
            }

        default:
            return state
    }
}