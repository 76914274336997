import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {account,backup,rooms,modules,users} from '../actions';
import Backup from '../pages/Backup';

export default connect(
    (state) => ({
        account: state.account,
        backup : state.backup,
        rooms : state.rooms,
        modules : state.modules,
        users : state.users
    }),
    (dispatch) => bindActionCreators({
        ...account,
        ...backup,
        ...rooms,
        ...modules,
        ...users
    }, dispatch)
)(Backup);
